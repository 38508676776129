import React from 'react';
import { b64EncodeUnicode } from '../utils';
import useUpdateEffect from './useUpdateEffect';

export default function useUpdateQueryState({ history, state, queryName = 'q' }) {
  const onSearchStateChange = React.useCallback(() => {
    const encodedQuery = b64EncodeUnicode(JSON.stringify(state));
    history.replace({
      pathName: history.location.pathName,
      search: `?${queryName}=${encodedQuery}`,
    });
  }, [history, queryName, state]);

  // Don't need to set query on mount
  useUpdateEffect(() => {
    onSearchStateChange(state);
  }, [onSearchStateChange, state]);
}
