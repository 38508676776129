import React from 'react';

import { useUser, useSearchUser } from '@vidispine/vdt-react/';
import { UserDetails, useSnackbarAlert } from '@vidispine/vdt-materialui/';

import EditUserDialog, { DIALOG_ID as EDIT_USER_DIALOG_ID } from './EditUserDialog';
import ChangeUserPasswordDialog, {
  DIALOG_ID as CHANGE_USER_PASSWORD_DIALOG_ID,
} from './ChangeUserPasswordDialog';
import DialogContext from '../DialogContext';
import DeleteUserDialog, { DIALOG_ID as DELETE_USER_DIALOG } from './DeleteUserDialog';

function User({ userType: initialUserType = {}, groupListType, onChange, onDeleteUserSuccess }) {
  const { onOpen: onOpenDialog, onClose: onCloseDialog } = React.useContext(DialogContext);
  const [Alert, showAlert] = useSnackbarAlert();

  const { userListDocument: { user: users = [] } = {} } = useSearchUser({
    queryParams: {},
    rowsPerPage: 1000,
    page: 0,
  });

  const {
    userType,
    updateUser,
    updateUserGroups,
    removeUserFromGroup,
    disableUser,
    enableUser,
    deleteUser,
    changePassword,
  } = useUser(initialUserType);

  const { userName } = userType || {};

  const handleChangePassword = ({ password }) => {
    changePassword(password)
      .then(() => {
        showAlert({ severity: 'success', message: 'User password updated' });
        onCloseDialog();
      })
      .catch(() => {
        showAlert({ severity: 'error', message: 'Failed to update user password' });
      });
  };

  const handleEditUser = (args) => {
    updateUser(args)
      .then(() => {
        showAlert({ severity: 'success', message: 'User updated' });
        onCloseDialog();
        if (onChange) onChange();
      })
      .catch(() => {
        showAlert({ severity: 'error', message: 'Failed to update user' });
      });
  };

  const handleAddToGroups = (args) =>
    updateUserGroups(args)
      .then(() => {
        showAlert({ severity: 'success', message: 'User updated' });
      })
      .catch(() => {
        showAlert({ severity: 'error', message: 'Could not update user' });
      });

  const handleRemoveUserFromGroup = (groupName) => {
    removeUserFromGroup(groupName)
      .then(() => {
        showAlert({ severity: 'success', message: 'User updated' });
      })
      .catch(() => {
        showAlert({ severity: 'error', message: 'Could not update user' });
      });
  };

  const handleToggleDisabled = () => {
    if (userType.disabled) {
      enableUser()
        .then(() => {
          showAlert({ severity: 'success', message: 'User enabled' });
          if (onChange) onChange();
        })
        .catch(() => {
          showAlert({ severity: 'error', message: 'Could not update user' });
        });
    } else {
      disableUser()
        .then(() => {
          showAlert({ severity: 'success', message: 'User disabled' });
          if (onChange) onChange();
        })
        .catch(() => {
          showAlert({ severity: 'error', message: 'Could not update user' });
        });
    }
  };

  const handleDeleteUser = (values) => {
    deleteUser({
      queryParams:
        values.transferUser !== 'empty_choosing' ? { transferAccess: values.transferUser } : {},
    })
      .then(() => {
        setTimeout(() => {
          if (onDeleteUserSuccess) {
            onDeleteUserSuccess({ severity: 'success', message: 'User deletion scheduled' });
          }
        }, 500);
      })
      .catch(() => {
        showAlert({ severity: 'error', message: 'Could not delete user' });
      });
  };

  return (
    <>
      <UserDetails
        userType={userType}
        groupListType={groupListType}
        onAddToGroups={handleAddToGroups}
        onRemoveFromGroup={handleRemoveUserFromGroup}
        onToggleDisabled={handleToggleDisabled}
        onEdit={onOpenDialog(EDIT_USER_DIALOG_ID)}
        onDelete={onOpenDialog(DELETE_USER_DIALOG)}
        onChangePassword={onOpenDialog(CHANGE_USER_PASSWORD_DIALOG_ID)}
      />
      <EditUserDialog
        onSubmit={handleEditUser}
        user={userType}
        DialogProps={{
          fullWidth: true,
        }}
      />
      <ChangeUserPasswordDialog
        onSubmit={handleChangePassword}
        user={userType}
        DialogProps={{
          fullWidth: true,
        }}
      />
      <DeleteUserDialog
        titleText={`Are you sure you want to delete the user "${userName || ''}"?`}
        submitText="Delete"
        cancelText="Cancel"
        onSubmit={handleDeleteUser}
        users={users}
        user={userType}
      />
      <Alert />
    </>
  );
}

export default User;
