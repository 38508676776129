import React from 'react';
import { user as UserApi, group as GroupApi } from '@vidispine/vdt-api';
import useApi from './useApi';

export default function useUser(initialState) {
  const { data: userType, request } = useApi(UserApi.getUser, { data: initialState });
  const { userName } = userType || {};
  const getUser = React.useCallback(() => request({ userName }), [request, userName]);
  React.useEffect(() => {
    if (userName) getUser();
  }, [getUser, userName]);

  const updateUser = React.useCallback(
    ({ userDocument }) =>
      UserApi.updateUser({
        userName,
        userDocument,
      }).then(() => request({ userName: userDocument.userName })),
    [request, userName],
  );

  const updateUserGroups = React.useCallback(
    ({ groups = [] }) =>
      UserApi.updateUserGroup({
        userName,
        groupListDocument: {
          group: groups.map((g) => ({ groupName: g })),
        },
      }).then(getUser),
    [getUser, userName],
  );

  const removeUserFromGroup = React.useCallback(
    (groupName) =>
      GroupApi.removeGroupUser({
        groupName,
        userName,
      }).then(getUser),
    [getUser, userName],
  );

  const changePassword = React.useCallback(
    (password) =>
      UserApi.updateUser({
        userName,
        userDocument: {
          password,
        },
        queryParams: {
          passwordType: 'raw',
        },
      }),
    [userName],
  );
  const deleteUser = ({ queryParams = {} } = {}) =>
    UserApi.disableUser({ userName, queryParams: { hard: true, ...queryParams } });
  const disableUser = (args) => UserApi.disableUser({ userName, ...args }).then(getUser);
  const enableUser = (args) => UserApi.enableUser({ userName, ...args }).then(getUser);

  return {
    userType,
    getUser,
    updateUser,
    updateUserGroups,
    removeUserFromGroup,
    disableUser,
    enableUser,
    deleteUser,
    changePassword,
  };
}
