import React from 'react';
import { useGroup } from '@vidispine/vdt-react/';
import { GroupDetails, useSnackbarAlert, useConfirmationDialog } from '@vidispine/vdt-materialui/';
import { DialogContentText } from '@material-ui/core';
import EditGroupDialog, { DIALOG_ID as EDIT_GROUP_DIALOG_ID } from './EditGroupDialog';
import DialogContext from '../DialogContext';

function Group({
  groupType: initialGroupType = {},
  groupListType,
  userListType,
  onChange,
  onDeleteGroupSuccess,
}) {
  const { onOpen: onOpenDialog, onClose: onCloseDialog } = React.useContext(DialogContext);
  const [Alert, showAlert] = useSnackbarAlert();
  const [ConfirmationDialog, confirm] = useConfirmationDialog({ throwOnReject: false });

  const {
    groupType,
    updateGroup,
    addChildGroups,
    addParentGroups,
    addUsersToGroup,
    removeGroup,
    removeUserFromGroup,
    removeChildGroup,
    removeFromParentGroup,
  } = useGroup(initialGroupType);

  const { groupName } = groupType || {};

  const handleEditGroup = (args) => {
    updateGroup(args)
      .then(() => {
        showAlert({ severity: 'success', message: 'Group updated' });
        onCloseDialog();
        if (onChange) onChange();
      })
      .catch(() => {
        showAlert({ severity: 'error', message: 'Failed to update group' });
      });
  };

  const handleAddChildGroups = (args) =>
    addChildGroups(args)
      .then(() => {
        showAlert({ severity: 'success', message: 'Group updated' });
      })
      .catch((error) => {
        const { response } = error;
        const message =
          response &&
          response.data &&
          response.data.invalidInput &&
          response.data.invalidInput.explanation
            ? response.data.invalidInput.explanation
            : 'Could not update group';
        showAlert({ severity: 'error', message });
      });

  const handleAddParentGroups = (args) =>
    addParentGroups(args)
      .then(() => {
        showAlert({ severity: 'success', message: 'Group updated' });
      })
      .catch((error) => {
        const { response } = error;
        const message =
          response &&
          response.data &&
          response.data.invalidInput &&
          response.data.invalidInput.explanation
            ? response.data.invalidInput.explanation
            : 'Could not update group';
        showAlert({ severity: 'error', message });
      });

  const handleAddUsersToGroup = (args) =>
    addUsersToGroup(args)
      .then(() => {
        showAlert({ severity: 'success', message: 'Group updated' });
      })
      .catch(() => {
        showAlert({ severity: 'error', message: 'Could not update group' });
      });

  const handleDeleteGroup = () => {
    confirm().then((confirmed) => {
      if (confirmed) {
        removeGroup()
          .then(() => {
            setTimeout(() => {
              if (onDeleteGroupSuccess) onDeleteGroupSuccess();
            }, 500);
          })
          .catch(() => {
            showAlert({ severity: 'error', message: 'Could not delete group' });
          });
      }
    });
  };

  return (
    <>
      <GroupDetails
        groupType={groupType}
        groupListType={groupListType}
        userListType={userListType}
        onAddToGroups={() => {}}
        onRemoveFromGroup={() => {}}
        onEdit={onOpenDialog(EDIT_GROUP_DIALOG_ID)}
        onAddChildGroups={handleAddChildGroups}
        onAddParentGroups={handleAddParentGroups}
        onAddUsersToGroup={handleAddUsersToGroup}
        onRemoveUser={removeUserFromGroup}
        onRemoveChildGroup={removeChildGroup}
        onRemoveFromParent={removeFromParentGroup}
        onDelete={handleDeleteGroup}
      />
      <EditGroupDialog
        onSubmit={handleEditGroup}
        groupType={groupType}
        DialogProps={{
          fullWidth: true,
        }}
      />
      {ConfirmationDialog && (
        <ConfirmationDialog titleText="Delete group" confirmText="Delete" rejectText="Cancel">
          <DialogContentText>
            Are you sure you want to delete the group {groupName}?
          </DialogContentText>
        </ConfirmationDialog>
      )}
      <Alert />
    </>
  );
}

export default Group;
