/* eslint-disable no-unused-vars */
import React from 'react';
import { useSearchGroup, useListGroup, useListUser } from '@vidispine/vdt-react/';
import {
  GroupTable,
  SearchInputFieldSelect,
  Pagination,
  useSnackbarAlert,
} from '@vidispine/vdt-materialui/';
import { group as GroupApi } from '@vidispine/vdt-api';
import { Drawer, Button, FormControlLabel, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

import CreateGroupDialog, { DIALOG_ID as CREATE_GROUP_DIALOG_ID } from './CreateGroupDialog';
import DialogContext from '../DialogContext';
import Group from './Group';

const SEARCH_FIELDS = [
  { label: 'groupname', value: 'groupname' },
  { label: 'description', value: 'description' },
];

const styles = (theme) => ({
  groupDrawer: {
    width: '450px',
    maxWidth: '450px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  searchActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

function GroupList({ classes }) {
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = React.useState();
  const { onOpen: onOpenDialog, onClose: onCloseDialog } = React.useContext(DialogContext);
  const [Alert, showAlert] = useSnackbarAlert();

  const {
    queryParams,
    onHideRoles,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    onChangeQuery,
    onSearchGroup,
    groupListDocument: { group: groups = [], hits } = {},
  } = useSearchGroup({ queryParams: { role: false } });
  const { role } = queryParams;
  const hideRoles = role === false;

  const { groupListType = {} } = useListGroup();
  const { userListType = {} } = useListUser();

  const onDeleteGroupAlert = () => {
    showAlert({ severity: 'success', message: 'Group deletion scheduled' });
  };

  const handleSearch = ({ field, query }) => {
    onChangeQuery(field, query);
  };

  const handleSwitchRole = () => onHideRoles({ hideRoles: !hideRoles });

  const handleCreateGroup = ({ groupDocument }) => {
    const { groupName } = groupDocument;
    GroupApi.updateGroup({
      groupName,
      groupDocument,
    })
      .then(() => {
        showAlert({ severity: 'success', message: 'Group successfully created' });
        onCloseDialog();
        onSearchGroup();
        setSelectedGroup(groupDocument);
      })
      .catch(() => {
        showAlert({ severity: 'error', message: 'Failed to create group' });
      });
  };

  return (
    <div>
      <div className={classes.searchActions}>
        <SearchInputFieldSelect
          onSubmit={handleSearch}
          fields={SEARCH_FIELDS}
          value="groupname"
          disableReset
          disableRequired
          flexGrow={1}
          py={0.5}
          mr={1}
        />
        <FormControlLabel
          control={<Switch checked={hideRoles} onChange={handleSwitchRole} />}
          label="Hide Roles"
        />
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={onOpenDialog(CREATE_GROUP_DIALOG_ID)}
        >
          {t('new')}
        </Button>
      </div>
      <CreateGroupDialog
        onSubmit={handleCreateGroup}
        groupListType={groupListType}
        userListType={userListType}
        DialogProps={{
          fullWidth: true,
        }}
      />
      <GroupTable
        groups={groups}
        GroupRowProps={{
          onClick: setSelectedGroup,
          hover: true,
        }}
      />
      <Pagination
        count={hits}
        page={page}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
      />
      <Drawer
        classes={{ paper: classes.groupDrawer }}
        anchor="right"
        open={Boolean(selectedGroup)}
        onClose={() => {
          setSelectedGroup();
          onSearchGroup();
        }}
      >
        {selectedGroup && (
          <Group
            groupType={selectedGroup}
            groupListType={groupListType}
            userListType={userListType}
            onDeleteGroupSuccess={() => {
              setSelectedGroup();
              onDeleteGroupAlert();
              onSearchGroup();
            }}
          />
        )}
      </Drawer>
      <Alert />
    </div>
  );
}

export default withStyles(styles)(GroupList);
