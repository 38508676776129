import React from 'react';
import { VidispineApi } from '@vidispine/vdt-react';
import { BrowserRouter as Router } from 'react-router-dom';
import Keycloak from 'keycloak-js';
import { user as userApi, utils as api } from '@vidispine/vdt-api';

const KEYCLOAK_AUTH_URL =
  process.env.REACT_APP_KEYCLOAK_AUTH_URL === ''
    ? undefined
    : process.env.REACT_APP_KEYCLOAK_AUTH_URL;

const KEYCLOAK_REALM =
  process.env.REACT_APP_KEYCLOAK_REALM === '' ? undefined : process.env.REACT_APP_KEYCLOAK_REALM;

const KEYCLOAK_CLIENT_ID =
  process.env.REACT_APP_KEYCLOAK_CLIENT_ID === ''
    ? undefined
    : process.env.REACT_APP_KEYCLOAK_CLIENT_ID;

// eslint-disable-next-line react/prefer-stateless-function
export default class Authentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = { token: null, userName: null };
    this.kc = new Keycloak({
      realm: KEYCLOAK_REALM,
      url: `${KEYCLOAK_AUTH_URL}/auth`,
      clientId: KEYCLOAK_CLIENT_ID,
    });
    this.kc.onTokenExpired = () => {
      this.kc.updateToken(-1).then((refreshed) => {
        if (!refreshed) {
          this.kc.clearToken();
          return;
        }
        this.setState({ token: this.kc.token });
      });
    };
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.kc
      .init({
        onLoad: 'login-required',
      })
      .then(() => {
        this.setState({ token: this.kc.token });
        const { serverUrl: baseURL } = this.props;
        api.defaultClient.defaults.baseURL = baseURL;
        userApi
          .whoAmI({ headers: { Authorization: `Bearer ${this.kc.token}` } })
          .then((result) => this.setState({ userName: result.data }));
      });
  }

  logout() {
    this.kc.logout();
  }

  render() {
    const { serverUrl: defaultServerUrl } = this.props;
    const { token, userName } = this.state;
    const { AppComponent, AppProps = {}, AppTitleComponent } = this.props;
    const basename = '/';
    return token && userName ? (
      <Router basename={basename}>
        <VidispineApi serverUrl={defaultServerUrl} username={userName} bearerToken={token}>
          <AppComponent
            onLogout={this.logout}
            AppTitleComponent={AppTitleComponent}
            onLogin={this.onLogin}
            tokenLifetime={Number.MAX_SAFE_INTEGER}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...AppProps}
          />
        </VidispineApi>
      </Router>
    ) : (
      <></>
    );
  }
}
