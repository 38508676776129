import React from 'react';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import { CollectionRow, CollectionTable, withDrag } from '@vidispine/vdt-materialui';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreatedComponent = ({ value }) => (value ? moment(value).format('ll') : '');
const IconComponent = () => (
  <Avatar variant="square">
    <FolderIcon />
  </Avatar>
);

const CustomCollectionRowDraggable = withDrag(CollectionRow);

export default function CustomCollectionTable({ collectionListType }) {
  const { t } = useTranslation();
  const history = useHistory();
  const handleRowClick = (collectionType) => {
    const { id } = collectionType;
    history.push(`/collection/${id}`);
  };
  return (
    <CollectionTable
      collectionListType={collectionListType}
      RowComponent={CustomCollectionRowDraggable}
      wrapDragInDiv={false}
      onRowClick={handleRowClick}
      options={[
        {
          label: '',
          valueComponent: IconComponent,
        },
        {
          label: t('title'),
          value: 'title',
        },
        {
          label: t('created'),
          value: 'created',
          valueComponent: CreatedComponent,
          hide: 'xs',
        },
        {
          label: t('owner'),
          value: '__user',
          hide: 'xs',
        },
      ]}
    />
  );
}
