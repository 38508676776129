import React from 'react';
import MUIDialog from '@material-ui/core/Dialog';

const DialogContext = React.createContext({
  open: false,
  onOpen: () => undefined,
  onClose: () => undefined,
});

export const DialogProvider = ({ children }) => {
  const [dialogId, setDialogId] = React.useState();
  const onOpen = (newDialogId) => () => setDialogId(newDialogId);
  const onClose = () => setDialogId();
  return (
    <DialogContext.Provider
      value={{
        dialogId,
        onOpen,
        onClose,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const withDialog = (dialogId, Component) => (props) => {
  const context = React.useContext(DialogContext);
  if (context === undefined) {
    throw new Error('useDialogContext must be used within a DialogProvider');
  }
  const { onClose, dialogId: currentDialogId } = context;
  const open = dialogId === currentDialogId;
  const { DialogProps = {}, ...componentProps } = props;
  if (!open) return null;
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...DialogProps}
    >
      <Component
        onClose={onClose}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...componentProps}
      />
    </MUIDialog>
  );
};

export const Dialog = ({ dialogId, children }) => {
  const context = React.useContext(DialogContext);
  if (context === undefined) {
    throw new Error('useDialogContext must be used within a DialogProvider');
  }
  const { onClose, dialogId: currentDialogId } = context;
  const open = dialogId === currentDialogId;
  if (!open) return null;
  const cloneChild = (child) => React.cloneElement(child, { onClose });
  return (
    <MUIDialog open={open} onClose={onClose} fullWidth>
      {open && React.Children.map(children, cloneChild)}
    </MUIDialog>
  );
};

export function useDialogContext() {
  const context = React.useContext(DialogContext);
  if (context === undefined) {
    throw new Error('useDialogContext must be used within a DialogProvider');
  }
  return context;
}

export default DialogContext;
