import React from 'react';
import { useSearchUser, useListGroup } from '@vidispine/vdt-react/';
import {
  UserTable,
  SearchInputFieldSelect,
  Pagination,
  useSnackbarAlert,
} from '@vidispine/vdt-materialui/';
import { user as UserApi } from '@vidispine/vdt-api';
import { Drawer, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import CreateUserDialog, { DIALOG_ID as CREATE_USER_DIALOG_ID } from './CreateUserDialog';

import DialogContext from '../DialogContext';
import User from './User';

const SEARCH_FIELDS = [
  { label: 'username', value: 'username' },
  { label: 'realname', value: 'realname' },
  { label: 'disabled', value: 'disabled' },
];

const styles = (theme) => ({
  userDrawer: {
    width: '450px',
    maxWidth: '450px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  searchActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

function Users({ classes }) {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = React.useState();
  const { onOpen: onOpenDialog, onClose: onCloseDialog } = React.useContext(DialogContext);
  const [Alert, showAlert] = useSnackbarAlert();

  const {
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    onChangeQuery,
    onSearchUser,
    userListDocument: { user: users = [], hits } = {},
  } = useSearchUser();

  const { groupListType = {} } = useListGroup();

  const handleSearch = ({ field, query }) => {
    onChangeQuery(field, query);
  };

  const handleCreateUser = ({ userDocument }) => {
    UserApi.createUser({
      userDocument,
      queryParams: {
        passwordType: 'raw',
      },
    })
      .then(() => {
        showAlert({ severity: 'success', message: 'User successfully created' });
        onCloseDialog();
        onSearchUser();
        setSelectedUser(userDocument);
      })
      .catch(() => {
        showAlert({ severity: 'error', message: 'Failed to create user' });
      });
  };

  const handleShowAlert = (severity, message) => {
    showAlert({ severity, message });
  };

  return (
    <div>
      <div className={classes.searchActions}>
        <SearchInputFieldSelect
          onSubmit={handleSearch}
          fields={SEARCH_FIELDS}
          value="username"
          disableReset
          disableRequired
          flexGrow={1}
          py={0.5}
          mr={1}
        />
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={onOpenDialog(CREATE_USER_DIALOG_ID)}
        >
          {t('new')}
        </Button>
      </div>
      <CreateUserDialog
        onSubmit={handleCreateUser}
        groupListType={groupListType}
        DialogProps={{
          fullWidth: true,
        }}
      />
      <UserTable
        users={users}
        UserRowProps={{
          onClick: setSelectedUser,
          hover: true,
        }}
      />
      <Pagination
        count={hits}
        page={page}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
      />
      <Drawer
        classes={{ paper: classes.userDrawer }}
        anchor="right"
        open={Boolean(selectedUser)}
        onClose={() => {
          setSelectedUser();
          onSearchUser();
        }}
      >
        {selectedUser && (
          <User
            userType={selectedUser}
            groupListType={groupListType}
            onDeleteUserSuccess={(values) => {
              setSelectedUser();
              onSearchUser();
              handleShowAlert(values.severity, values.message);
            }}
          />
        )}
      </Drawer>
      <Alert />
    </div>
  );
}

export default withStyles(styles)(Users);
