import React from 'react';
import { DialogTitle, DialogActions, DialogContent, Button } from '@material-ui/core/';
import { useApi } from '@vidispine/vdt-react';
import { item as ItemApi, exportlocation as ExportLocationApi } from '@vidispine/vdt-api';
import { SnackbarContext } from '../SnackbarContext';

import { withDialog } from '../DialogContext';
import ExportForm from './ExportForm';

export const DIALOG_ID = 'ITEM_EXPORT_DIALOG';

export function ExportDialogComponent({ itemId, onClose, ExportFormComponent = ExportForm }) {
  const [selectedLocation, setSelectedLocation] = React.useState('');
  const [selectedShapeTags, setSelectedShapeTags] = React.useState([]);
  const { setNotification } = React.useContext(SnackbarContext);

  const { request: getItemRequest, data: { shape: itemShapeTags = [] } = {} } = useApi(
    ItemApi.getItem,
  );

  React.useEffect(() => {
    getItemRequest({
      itemId,
      queryParams: {
        p: 'shape.tag',
      },
    });
  }, [getItemRequest, itemId]);
  const shapeTagsValues = React.useMemo(() => itemShapeTags.map((shapeTag) => shapeTag.tag[0]), [
    itemShapeTags,
  ]);

  const {
    request: listExportLocationRequest,
    data: { exportLocation: exportLocations = [] } = {},
    isLoading,
  } = useApi(ExportLocationApi.listExportLocation);

  React.useEffect(() => {
    listExportLocationRequest({
      itemId,
      queryParams: {
        p: 'shape.tag',
      },
    });
  }, [listExportLocationRequest, itemId]);
  const locationValues = React.useMemo(
    () => exportLocations.map((location) => ({ label: location.name, value: location.name })),
    [exportLocations],
  );

  const setErrorNotification = (error) =>
    setNotification({
      open: true,
      message: error.message,
      severity: 'error',
    });

  const handleShapeTagChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedShapeTags([...selectedShapeTags, name]);
    } else {
      setSelectedShapeTags(selectedShapeTags.filter((value) => value !== name));
    }
  };
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleSubmit = () => {
    try {
      ItemApi.createExport({
        itemId,
        queryParams: {
          locationName: selectedLocation,
          tag: selectedShapeTags,
        },
      })
        .then((response) => {
          if (response.ok) {
            setNotification({
              open: true,
              message: 'Export Job Created',
              severity: 'success',
            });
          }
          onClose();
        })
        .catch((error) => setErrorNotification(error));
    } catch (error) {
      setErrorNotification(error);
    }
  };

  const diableStartButton = React.useMemo(() => !selectedShapeTags.length || !selectedLocation, [
    selectedShapeTags,
    selectedLocation,
  ]);

  return (
    <>
      <DialogTitle>Export</DialogTitle>
      <DialogContent dividers style={{ minHeight: 150 }}>
        {!isLoading && (
          <ExportFormComponent
            itemId={itemId}
            onShapeTagChange={handleShapeTagChange}
            onLocationChange={handleLocationChange}
            selectedLocation={selectedLocation}
            selectedShapeTags={selectedShapeTags}
            locations={locationValues}
            shapeTags={shapeTagsValues}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={diableStartButton}
        >
          Start
        </Button>
      </DialogActions>
    </>
  );
}

export default withDialog(DIALOG_ID, ExportDialogComponent);
