import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import { EntityTree, ENTITY_TREE_JOIN_CHARACTER } from '@vidispine/vdt-materialui';
import { Card, CardHeader, CardContent, IconButton, Collapse } from '@material-ui/core';

const styles = (theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  root: {
    '& .WorkspaceTree-card': {
      '& .MuiCardHeader-root': {
        paddingBottom: '0',
      },
      '& .MuiCardContent-root': {
        paddingTop: '0',
      },
      '& .MuiCardHeader-title': {
        fontSize: '1.2em',
      },
    },
  },
});

const searchState = {
  interval: 'generic',
  queryParams: { field: ['title', 'mimeType'], content: ['metadata'] },
  itemSearchDocument: {
    field: [
      { name: 'eidr_referentType', value: [{ value: 'workspace' }] },
      { name: '__parent_collection', value: [{ value: '' }] },
    ],
    sort: [
      { field: '_type', order: 'ascending' },
      { field: 'created', order: 'descending' },
    ],
  },
};
const collectionState = {
  queryParams: {
    field: ['content', 'title'],
    content: ['metadata'],
  },
  itemSearchDocument: {
    field: [{ name: 'eidr_referentType', value: [{ value: 'workspace' }] }],
  },
};

function WorkspaceTree({
  classes,
  initialSelected = null,
  initialExpanded = [],
  onMoved,
  onChange,
}) {
  const [selected, setSelected] = React.useState(initialSelected);
  const [expanded, setExpanded] = React.useState(initialExpanded);
  const [expandedHeader, setExpandedHeader] = React.useState(true);
  const handleChange = (newSelected) => {
    setSelected(newSelected);
    const [, collectionId] = newSelected.split(ENTITY_TREE_JOIN_CHARACTER);
    onChange(collectionId);
  };
  return (
    <Box className={classes.root} width="100%">
      <Card className="WorkspaceTree-card">
        <CardHeader
          title="Workspaces"
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedHeader,
              })}
              onClick={() => setExpandedHeader(!expandedHeader)}
              aria-expanded={expandedHeader}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={expandedHeader} timeout="auto" unmountOnExit>
          <CardContent>
            <EntityTree
              searchState={searchState}
              collectionLabelField="title"
              selectOnToggle={false}
              selected={selected}
              expanded={expanded}
              EntityTreeListProps={{
                onMoved,
                CollectionListProps: {
                  collectionState,
                  includeItems: false,
                  CollectionLabelProps: {
                    labelField: 'title',
                  },
                },
              }}
              onChange={handleChange}
              onExpanded={setExpanded}
            />
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
}

export default withStyles(styles, { name: 'VdtWorkspaceTree' })(WorkspaceTree);
