import React from 'react';
import { Link } from 'react-router-dom';
import { MediaCardAvatar } from '@vidispine/vdt-materialui';
import { parseMetadataType, roles as ROLES } from '@vidispine/vdt-js';
import { HasRole } from '@vidispine/vdt-react';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { useTranslation } from 'react-i18next';

import MenuVert from '../layout/MenuVert';
import DialogContext from '../DialogContext';
import { DIALOG_ID as DELETE_COLLECTION_DIALOG } from './CollectionDeleteDialog';

function CollectionMenu({
  onSave,
  toggleAutosave,
  isAutoSaveReorder,
  isAutoPlayEnabled,
  toggleAutoPlay,
}) {
  const { onOpen: onOpenDialog } = React.useContext(DialogContext);
  const { t } = useTranslation();

  return (
    <MenuVert>
      <MenuItem onClick={onSave}>
        <ListItemIcon>
          <SaveIcon />
        </ListItemIcon>
        <ListItemText primary="Save collection" />
      </MenuItem>
      <MenuItem onClick={onOpenDialog(DELETE_COLLECTION_DIALOG)}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText primary="Delete" primaryTypographyProps={{ color: 'secondary' }} />
      </MenuItem>
      <MenuItem onClick={toggleAutosave}>
        <FormControlLabel
          control={<Switch checked={isAutoSaveReorder} color="primary" />}
          label={t('Autosave')}
        />
      </MenuItem>
      <MenuItem onClick={toggleAutoPlay}>
        <FormControlLabel
          control={<Switch checked={isAutoPlayEnabled} color="primary" />}
          label={t('Autoplay')}
        />
      </MenuItem>
    </MenuVert>
  );
}

export default function CollectionHeader({
  collectionType,
  isLoading,
  onClose,
  linkTo,
  CollectionHeaderMenuProps = {},
}) {
  const { t } = useTranslation();
  const { metadata: metadataType, name } = collectionType;
  const metadata = React.useMemo(
    () => parseMetadataType(metadataType, { joinValue: ',', flat: true }),
    [metadataType],
  );
  const { title, created, user } = metadata;

  return (
    <Card square elevation={0} style={{ backgroundColor: 'inherit' }}>
      <CardHeader
        title={isLoading ? <Skeleton variant="rect" width="50%" height={28} /> : title || name}
        avatar={
          isLoading ? (
            <Skeleton variant="rect" width={40} height={40} />
          ) : (
            <MediaCardAvatar avatar="Collection" />
          )
        }
        titleTypographyProps={{ variant: 'h5' }}
        action={
          <>
            {linkTo && (
              <IconButton component={Link} to={linkTo} disableRipple>
                <LaunchIcon />
              </IconButton>
            )}
            <HasRole
              roleName={[ROLES.COLLECTION_WRITE]}
              // eslint-disable-next-line react/jsx-props-no-spreading
              allow={<CollectionMenu {...CollectionHeaderMenuProps} />}
            />{' '}
            {onClose && (
              <IconButton component={ButtonBase} onClick={onClose} disableRipple>
                <CloseIcon />
              </IconButton>
            )}
          </>
        }
      />
      <CardContent>
        {isLoading ? (
          <Skeleton variant="rect" width="10%" height={18} />
        ) : (
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rect" width="10%" height={18} />
              ) : (
                <Grid container alignItems="center">
                  <Typography variant="body2">{`${t('createdBy')}`}&nbsp;</Typography>
                  <Typography variant="subtitle2">{user}</Typography>
                  <Typography variant="body2">&nbsp;on&nbsp;</Typography>
                  <Typography variant="subtitle2">
                    {created ? moment(created).format('ll') : ''}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
