import React from 'react';
import { EntityDeleteDialog } from '@vidispine/vdt-materialui';
import { useDialogContext } from '../DialogContext';

export const DIALOG_ID = 'DELETE_COLLECTION_DIALOG';

export default function CollectionDeleteDialog({ collectionId, ...props }) {
  const { dialogId, onClose } = useDialogContext();
  return (
    <EntityDeleteDialog
      open={dialogId === DIALOG_ID}
      onClose={onClose}
      entity="collection"
      entityId={collectionId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
