/* eslint max-classes-per-file: "off" */
import { CONSTANT_TIMEBASES, FRAME_SEPARATORS } from './const/time';

const splitSmpte = (smpteText) => {
  const hasDropFrameSeparator = smpteText.match(/[^0-9:\-_]/);

  let hhmmssff;
  let frameOptions = {};
  if (hasDropFrameSeparator) {
    const [frameSeparator] = hasDropFrameSeparator;
    const [hhmmss, splitFrames] = smpteText.split(frameSeparator);
    hhmmssff = [...hhmmss.split(':'), splitFrames];
    frameOptions = FRAME_SEPARATORS[frameSeparator] || {};
  } else {
    hhmmssff = smpteText.split(':');
  }

  hhmmssff = hhmmssff.map(Number);

  if (hhmmssff.length > 4 || hhmmssff.some((n) => Number.isNaN(n))) {
    throw new Error('Invalid SMPTE timecode');
  }

  return [hhmmssff, frameOptions];
};

const getDropFrames = (roundedFrameRate) => (roundedFrameRate === 60 ? 4 : 2);

const getRoundedFrameRate = (timeBase) => Math.round(timeBase.denominator / timeBase.numerator);

const countSamples = (hh, mm, ss, ff, { dropFrame, roundedFrameRate }) => {
  if (!dropFrame) {
    return hh * 3600 * roundedFrameRate + mm * 60 * roundedFrameRate + ss * roundedFrameRate + ff;
  }

  if (![30, 60].includes(roundedFrameRate)) {
    throw new Error('Cannot use dropframe with non NTSC timebase');
  }

  const dropFrames = getDropFrames(roundedFrameRate);

  const shouldDropMinute = mm % 10 !== 0;
  const shouldDropSecond = shouldDropMinute && ss === 0;

  const hourFrames = hh * (3600 * roundedFrameRate - 54 * dropFrames);
  const minuteFrames = mm * 60 * roundedFrameRate - (mm - Math.ceil(mm / 10)) * dropFrames;
  const secondFrames = ss * roundedFrameRate - (shouldDropMinute && ss > 1 ? dropFrames : 0);

  if (shouldDropSecond && ff < dropFrames) {
    throw new Error('Invalid ff');
  }

  const frameFrames = shouldDropSecond ? ff - dropFrames : ff;

  return hourFrames + minuteFrames + secondFrames + frameFrames;
};

function countDroppedFrames(frames, roundedFrameRate) {
  const dropFrames = getDropFrames(roundedFrameRate);
  const oneMinuteUndroppedFrames = 60 * roundedFrameRate;
  const oneMinuteDroppedFrames = 60 * roundedFrameRate - dropFrames;
  const tenMinuteFrames = 10 * (oneMinuteUndroppedFrames - dropFrames) + dropFrames;

  const tenMinuteChunks = Math.floor(frames / tenMinuteFrames);
  const minuteRemainder = Math.max(0, (frames % tenMinuteFrames) - oneMinuteUndroppedFrames);
  const oneMinuteChunks = Math.floor(minuteRemainder / oneMinuteDroppedFrames);
  const frameRemainder = minuteRemainder % oneMinuteDroppedFrames;
  const frameChunks = frameRemainder > 0 ? dropFrames : 0;

  return tenMinuteChunks * 9 * dropFrames + oneMinuteChunks * dropFrames + frameChunks;
}

export class TimeBase {
  constructor({ numerator = 1, denominator = 1 } = {}) {
    this.numerator = Number(numerator);
    this.denominator = Number(denominator);
  }

  toJSON() {
    return {
      denominator: this.denominator,
      numerator: this.numerator,
    };
  }

  toConstant() {
    let constant;
    Object.entries(CONSTANT_TIMEBASES).find((thisTimeBase) => {
      const [thisTimeBaseText, thisTimeBaseType] = thisTimeBase;
      const { numerator, denominator } = thisTimeBaseType;
      if (numerator === this.numerator && denominator === this.denominator) {
        constant = thisTimeBaseText;
        return true;
      }
      return false;
    });
    return constant;
  }

  toText(useConstant = false) {
    if (useConstant) {
      const timeBaseText = this.toConstant();
      if (timeBaseText) return timeBaseText;
    }
    if (this.numerator > 1) {
      const timeBaseText = [this.denominator, this.numerator].join(':');
      return timeBaseText;
    }
    const timeBaseText = String(this.denominator);
    return timeBaseText;
  }

  toRate(useConstant = false, round = true) {
    if (useConstant) {
      const rate = this.toConstant();
      if (rate) return rate;
    }
    const rate = this.denominator / this.numerator;
    if (Number.isInteger(rate)) {
      return rate;
    }
    return round ? rate.toFixed(2) : rate;
  }
}

const isDropFrameTimeBase = ({ numerator, denominator }) => {
  return numerator === 1001 && (denominator === 60000 || denominator === 30000);
};

export class TimeCode {
  constructor({ samples = 0, timeBase } = {}, { dropFrame, field = 2 } = {}) {
    if (typeof samples === 'number') {
      this.samples = samples;
    } else if (typeof samples === 'string') {
      if (samples === '-INF') {
        this.samples = -Infinity;
      } else if (samples === '+INF') {
        this.samples = Infinity;
      } else {
        this.samples = Number(samples);
      }
    } else {
      throw new Error(`samples is not number/string/-Inf/+Inf is: ${samples}`);
    }
    this.timeBase = new TimeBase(timeBase);
    this.dropFrame = dropFrame === undefined ? isDropFrameTimeBase(this.timeBase) : dropFrame;
    this.field = field;
  }

  add(val) {
    const {
      timeBase: { numerator, denominator },
    } = val;
    let conformedTimeCode = val;
    if (numerator !== this.timeBase.numerator || denominator !== this.timeBase.denominator) {
      conformedTimeCode = val.conformTimeBase(this.timeBase);
    }
    const { samples } = conformedTimeCode;
    return new TimeCode({ samples: this.samples + samples, timeBase: this.timeBase });
  }

  subtract(val) {
    const {
      timeBase: { numerator, denominator },
    } = val;
    let conformedTimeCode = val;
    if (numerator !== this.timeBase.numerator || denominator !== this.timeBase.denominator) {
      conformedTimeCode = val.conformTimeBase(this.timeBase);
    }
    const { samples } = conformedTimeCode;
    return new TimeCode({ samples: this.samples - samples, timeBase: this.timeBase });
  }

  conformTimeBase(conformTo) {
    let timeBase = conformTo;
    if (conformTo instanceof TimeCode === false) {
      timeBase = new TimeBase(conformTo);
    }
    const samples = Math.round(
      this.samples / (this.timeBase.toRate(false, false) / timeBase.toRate(false, false)),
    );
    const timeCode = { samples, timeBase };
    return new TimeCode(timeCode);
  }

  toJSON() {
    return {
      samples: this.samples,
      timeBase: this.timeBase,
    };
  }

  toText() {
    let timeCodeText = String(this.samples);
    const timeBaseText = this.timeBase.toText();
    if (timeBaseText !== '1') {
      timeCodeText = [this.samples, timeBaseText].join('@');
    }
    return timeCodeText;
  }

  toSeconds() {
    const { numerator, denominator } = this.timeBase;
    return this.samples * (numerator / denominator);
  }

  toTime() {
    const roundedFrameRate = getRoundedFrameRate(this.timeBase);
    const totalSamples =
      this.samples + (this.dropFrame ? countDroppedFrames(this.samples + 1, roundedFrameRate) : 0);

    const hours = Math.floor(totalSamples / (3600 * roundedFrameRate));
    const minutes = Math.floor(totalSamples / (60 * roundedFrameRate)) % 60;
    const seconds = Math.floor(totalSamples / roundedFrameRate) % 60;
    const frames = totalSamples % roundedFrameRate;
    const partialSeconds = frames / roundedFrameRate;
    return {
      hours,
      minutes,
      seconds,
      frames,
      partialSeconds,
    };
  }

  toDuration({ format } = {}) {
    const { hours, minutes, seconds } = this.toTime();
    if (typeof format === 'string') {
      if (format.toLowerCase() === 'hhmmss') {
        return [
          hours.toFixed().padStart(2, '0'),
          minutes.toFixed().padStart(2, '0'),
          seconds.toFixed().padStart(2, '0'),
        ].join(':');
      }
    }
    if (hours) {
      return [
        hours.toFixed(),
        minutes.toFixed().padStart(2, '0'),
        seconds.toFixed().padStart(2, '0'),
      ].join(':');
    }
    if (minutes >= 10) {
      return [minutes.toFixed().padStart(2, '0'), seconds.toFixed().padStart(2, '0')].join(':');
    }
    return [minutes.toFixed(), seconds.toFixed().padStart(2, '0')].join(':');
  }

  toSmpte() {
    if (this.samples === -Infinity) return '00:00:00:00';
    const { hours, minutes, seconds, frames } = this.toTime();
    const hhmmss = [
      hours.toFixed().padStart(2, '0'),
      minutes.toFixed().padStart(2, '0'),
      seconds.toFixed().padStart(2, '0'),
    ].join(':');
    const [frameSeparator = ':'] = Object.entries(FRAME_SEPARATORS).find((thisSeparator) => {
      const [, { dropFrame, field }] = thisSeparator;
      return dropFrame === this.dropFrame && field === this.field;
    });
    return [hhmmss, frames.toFixed().padStart(2, '0')].join(frameSeparator);
  }
}

const formatTimeBaseType = (timeBase) => new TimeBase(timeBase);

const formatTimeBaseText = (timeBaseText) => {
  if (timeBaseText === undefined) {
    return formatTimeBaseType();
  }
  if (typeof timeBaseText === 'number') {
    return formatTimeBaseType({ denominator: timeBaseText });
  }
  if (timeBaseText.includes(':')) {
    const [denominator, numerator] = timeBaseText.split(':');
    return formatTimeBaseType({ denominator, numerator });
  }
  if (Object.keys(CONSTANT_TIMEBASES).includes(timeBaseText)) {
    return formatTimeBaseType(CONSTANT_TIMEBASES[timeBaseText]);
  }
  const denominator = Number(timeBaseText);
  if (Number.isNaN(denominator)) {
    throw new Error(
      `timeBaseText must be a number or ${Object.keys(CONSTANT_TIMEBASES).join(
        ',',
      )} - is ${timeBaseText}`,
    );
  }
  return formatTimeBaseType({ denominator });
};

const formatTimeBase = (timeBase) => {
  if (typeof timeBase === 'object') {
    return formatTimeBaseType(timeBase);
  }
  return formatTimeBaseText(timeBase);
};

const formatTimeCodeType = (timeCode, options) => new TimeCode(timeCode, options);

const formatTimeCodeText = (timeCodeText, options) => {
  if (timeCodeText === undefined) {
    const timeCode = { samples: 0 };
    return formatTimeCodeType(timeCode, options);
  }
  if (typeof timeCodeText === 'number') {
    const timeCode = { samples: timeCodeText };
    return formatTimeCodeType(timeCode, options);
  }
  if (timeCodeText.includes('@')) {
    const [samplesString, timeBaseText] = timeCodeText.split('@');
    const samples = Number(samplesString);
    const timeBase = formatTimeBaseText(timeBaseText);
    const timeCode = { samples, timeBase };
    return formatTimeCodeType(timeCode, options);
  }
  if (timeCodeText === '-INF') {
    const samples = -Infinity;
    const timeCode = { samples };
    return formatTimeCodeType(timeCode, options);
  }
  if (timeCodeText === '+INF') {
    const samples = Infinity;
    const timeCode = { samples };
    return formatTimeCodeType(timeCode, options);
  }
  const samples = Number(timeCodeText);
  if (Number.isNaN(samples)) {
    throw new Error(`timeBaseText must be a number or sample@timeBase - is ${timeCodeText}`);
  }
  const timeCode = { samples };
  return formatTimeCodeType(timeCode, options);
};

const formatSeconds = (seconds, timeBase = {}, options) => {
  if (Number.isNaN(Number(seconds))) {
    throw new Error(`seconds must be digits, is ${seconds}`);
  }
  const { denominator = 1, numerator = 1 } = timeBase;
  const samples = seconds * (denominator / numerator);
  const timeCode = { samples, timeBase };
  return new TimeCode(timeCode, options);
};

const formatSecondsPrecise = (seconds, timeBase = {}, options) => {
  if (Number.isNaN(Number(seconds))) {
    throw new Error(`seconds must be digits, is ${seconds}`);
  }
  const { numerator = 1 } = timeBase;
  let { denominator = 1 } = timeBase;
  if (!Number.isInteger(seconds)) {
    const decimalPlaces = String(seconds).split('.')[1].length;
    denominator *= 10 ** decimalPlaces;
  }
  const samples = (seconds * (denominator / numerator)).toFixed();
  const timeCode = { samples, timeBase: { denominator, numerator } };
  return new TimeCode(timeCode, options);
};

const formatSmpte = (smpteText, timeBaseText, options = {}) => {
  if (smpteText === undefined) {
    const timeBase = formatTimeBase(timeBaseText);
    const timeCode = { samples: 0, timeBase };
    return formatTimeCodeType(timeCode);
  }
  if (typeof smpteText !== 'string') {
    throw new Error(`smpteText must be a string, is ${smpteText}`);
  }

  const [[hh, mm, ss, ff = 0], frameOptions] = splitSmpte(smpteText);
  const { dropFrame = false } = frameOptions;

  const timeBase = formatTimeBase(timeBaseText);
  const roundedFrameRate = getRoundedFrameRate(timeBase);

  if (mm >= 60 || mm < 0 || ss >= 60 || ss < 0 || ff >= roundedFrameRate || ff < 0) {
    throw new Error('Invalid mm, ss or ff');
  }

  const samples = countSamples(hh, mm, ss, ff, { dropFrame, roundedFrameRate });
  const timeCode = { samples, timeBase };
  return formatTimeCodeType(timeCode, { ...frameOptions, ...options });
};

export {
  formatSeconds,
  formatSecondsPrecise,
  formatTimeBaseType,
  formatTimeBaseText,
  formatTimeCodeType,
  formatTimeCodeText,
  formatTimeBase,
  formatSmpte,
};
