const MuiTableRow = {
  root: {
    background: '#2f3846',
    '&.MuiTableRow-hover:hover': {
      background: '#303947',
    },
    '&:nth-child(even)': {
      background: '#333d4c',
      '&.MuiTableRow-hover:hover': {
        background: '#333e4c    ',
      },
    },
    '& .MuiTableCell-root': {
      border: 'none',
    },
  },
  selected: {},
  hover: {},
  head: {
    background: '#3d475e',
    '& th': {
      color: '#9facc1',
      textTransform: 'uppercase',
      fontSize: '12px',
      fontWeight: 700,
    },
  },
  footer: {},
};

export default MuiTableRow;
