import React from 'react';

export default function ComposeProviders({ providers = [], children, ...props }) {
  return providers.reduceRight((acc, Provider) => {
    if (typeof Provider === 'function') {
      return (
        <Provider
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {acc}
        </Provider>
      );
    }
    const { provider: ProviderComponent, ...providerProps } = Provider;
    return (
      <ProviderComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...providerProps}
      >
        {acc}
      </ProviderComponent>
    );
  }, children);
}
