import React from 'react';
import { useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import {
  useSettings,
  useSearch,
  useSearchItem,
  useQueryInitialState,
  useUpdateQueryState,
} from '@vidispine/vdt-react';
import {
  CheckboxGroupField,
  ExpandedFormControlLabel,
  SelectField,
  SliderField,
  TagField,
  TextField,
  AutocompleteField,
  Pagination,
} from '@vidispine/vdt-materialui';

import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import SearchItemForm from './SearchItemForm';
import SearchItemGrid from './SearchItemGridPrivileged';
import SearchItemTable from './SearchItemTablePrivileged';
import SearchInFilters from './SearchInFilters';
import { PREFIX_STRING, SUFFIX_STRING, CATEGORY_OPTIONS } from '../const';

const filterFields = [
  {
    value: '__user',
    label: 'Content',
    component: CheckboxGroupField,
    options: [],
  },
  {
    value: 'title',
    component: TextField,
  },
  {
    value: 'cct',
    label: 'Category',
    component: AutocompleteField,
    getOptionLabel: (opt) => (Array.isArray(opt) ? opt[0] : opt),
    getOptionSelected: (opt, val) => (Array.isArray(val) ? opt === val[0] : opt === val),
    options: CATEGORY_OPTIONS,
  },
  {
    value: 'mediaType',
    component: CheckboxGroupField,
    FormControlLabelComponent: ExpandedFormControlLabel,
    FacetProps: {
      count: true,
      exclude: ['mediaType'], // there are no items that have both media type video and any of the other media types
    },
    parseOptions: (facet = {}) => {
      const newOpt = Object.keys(facet).map((value) => ({
        value,
        label: `${value === 'none' ? 'other' : value} (${facet[value]})`,
      }));
      newOpt.unshift({ value: '*', label: 'Any Media Type ' });
      return newOpt;
    },
    selectAllValue: '*',
    FormLabelComponent: SearchInFilters,
  },
  {
    value: 'originalVideoCodec',
    component: SelectField,
    FacetProps: {
      count: true,
    },
  },
  {
    value: 'originalAudioCodec',
    component: SelectField,
    FacetProps: {
      count: true,
    },
  },
  {
    value: 'originalHeight',
    showMarks: false,
    component: SliderField,
    isRange: true,
    exclude: true,
    FacetProps: {
      count: true,
    },
  },
  {
    value: 'mimeType',
    label: 'Mime Types',
    component: TagField,
    parseOptions: (facet = {}) => Object.keys(facet).map((value) => ({ value, label: value })),
    freeSolo: true,
    FacetProps: {
      count: true,
    },
    AutocompleteProps: {
      multiple: true,
    },
  },
];

const defaultInitialState = {
  itemSearchDocument: {
    intervals: 'all',
    highlight: {
      matchingOnly: false,
      prefix: PREFIX_STRING,
      suffix: SUFFIX_STRING,
      field: ['title'],
    },
    facet: filterFields
      .filter(({ FacetProps = {} }) => FacetProps.count === true)
      .map(({ value, exclude, FacetProps: { ...props } = {} }) => {
        const excludeFilter = exclude
          ? { exclude: Array.isArray(exclude) ? exclude : [value] }
          : {};
        return {
          field: value,
          name: value,
          ...excludeFilter,
          ...props,
        };
      }),
    suggestion: {
      maximumSuggestions: 3,
      accuracy: 0.7,
    },
  },
  queryParams: {
    field: [
      'title',
      'originalFilename',
      'created',
      'user',
      'durationSeconds',
      'mimeType',
      'itemId',
    ],
    content: ['metadata', 'thumbnail'],
    'noauth-url': true,
  },
};

const GRID_VIEW = 'GRID_VIEW';
const ROW_VIEW = 'ROW_VIEW';
const DEFAULT_VIEW = ROW_VIEW;

const styles = {};

function SearchItemPrivileged({ userName }) {
  const history = useHistory();
  const { queryInitialState } = useQueryInitialState({
    history,
    defaultState: defaultInitialState,
  });
  const {
    state,
    setSearchText,
    setItemSearchDocument,
    onChangeRowsPerPage,
    onChangePage,
    onChangeSort,
    setSearchFilter,
  } = useSearch(queryInitialState);
  const { matrixParams, queryParams, itemSearchDocument, page, rowsPerPage } = state;
  useUpdateQueryState({ history, state });
  const { itemListType, isLoading, onRefresh } = useSearchItem({
    itemSearchDocument,
    queryParams,
    matrixParams,
    advancedParameter: 'XXXDELETEMEXXX',
  });

  const parsedFilterFields = React.useMemo(() => {
    return filterFields.map((field) => {
      if (field.value === '__user') {
        return { ...field, options: [{ value: userName, label: 'My Content' }] };
      }
      return field;
    });
  }, [userName]);

  const {
    settings: { layout = DEFAULT_VIEW },
    updateSetting,
  } = useSettings();

  const onChangeViewLayout = (e, newLayout) => updateSetting({ layout: newLayout });
  let ViewComponent;
  switch (layout) {
    case GRID_VIEW:
      ViewComponent = SearchItemGrid;
      break;
    case ROW_VIEW:
      ViewComponent = SearchItemTable;
      break;
    default:
      ViewComponent = SearchItemGrid;
      break;
  }

  const { hits: count = 0 } = itemListType;

  const minExpectedCount = isLoading ? page * rowsPerPage + 1 : 0;

  return (
    <SearchItemForm
      itemSearchDocument={itemSearchDocument}
      itemListType={itemListType}
      setSearchText={setSearchText}
      isLoading={isLoading}
      onChangeSort={onChangeSort}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      onChangeViewLayout={onChangeViewLayout}
      layout={layout}
      setItemSearchDocument={setItemSearchDocument}
      setSearchFilter={setSearchFilter}
      filterFields={parsedFilterFields}
      withBin
      onRefresh={onRefresh}
      queryParams={defaultInitialState.queryParams}
    >
      <ViewComponent itemListType={itemListType} />
      {page !== undefined && (
        <Box width="100%">
          <Grid item>
            <Hidden smDown>
              <Pagination
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                count={count || minExpectedCount}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </Hidden>
          </Grid>
        </Box>
      )}
    </SearchItemForm>
  );
}

export default withStyles(styles)(SearchItemPrivileged);
