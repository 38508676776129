/* eslint react/prop-types: "off" */
import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { search as SearchApi } from '@vidispine/vdt-api';

import useApi from '../hooks/useApi';

export default function SearchItemCollection({
  itemSearchDocument,
  queryParams,
  matrixParams,
  children,
}) {
  const { request, data: searchResultType = {}, isLoading, isError } = useApi(
    SearchApi.searchItemCollection,
  );
  const onRefresh = () => request({ itemSearchDocument, queryParams, matrixParams });
  useDeepCompareEffect(() => {
    onRefresh();
  }, [itemSearchDocument, queryParams, matrixParams]);
  const cloneProps = {
    itemSearchDocument,
    matrixParams,
    queryParams,
    searchResultType,
    onRefresh,
    isLoading,
    isError,
  };
  const cloneChild = (child) => React.cloneElement(child, cloneProps);
  return React.Children.map(children, cloneChild);
}
