import { group as GroupApi } from '@vidispine/vdt-api';
import React from 'react';
import useApi from './useApi';

const useListGroup = (args) => {
  const { request, data: groupListType = {}, isLoading } = useApi(GroupApi.listGroup);
  const onRefresh = () => request(args);
  React.useEffect(() => {
    onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args]);
  return {
    onRefresh,
    groupListType,
    isLoading,
  };
};

export default useListGroup;
