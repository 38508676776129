import React from 'react';
import { b64DecodeUnicode } from '../utils';

export default function useQueryInitialState({
  history,
  queryName = 'q',
  defaultState = undefined,
}) {
  const defaultStateRef = React.useRef(defaultState);
  const queryState = React.useMemo(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const encodedQuery = searchParams.get(queryName);
    if (!encodedQuery) {
      return defaultStateRef.current;
    }
    try {
      const decodedQuery = b64DecodeUnicode(encodedQuery);
      return JSON.parse(decodedQuery);
    } catch (error) {
      // TODO: Show error snackbar: "Could not parse search query"
      // eslint-disable-next-line no-console
      console.error(error);
      history.replace({ pathName: history.location.pathName, search: '' });
    }
    return undefined;
  }, [defaultStateRef, history, queryName]);

  return { queryInitialState: queryState };
}
