import React from 'react';
import { Upload } from '@vidispine/vdt-materialui';
import UploadContext from './UploadContext';

export default function ItemUpload() {
  const { useUploadFiles, contextFiles, setContextFiles } = React.useContext(UploadContext);

  return (
    <Upload
      tree
      useUploadFiles={useUploadFiles}
      contextFiles={contextFiles}
      setContextFiles={setContextFiles}
    />
  );
}
