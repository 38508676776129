const MuiDialogTitle = {
  root: {
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(60, 71, 93)',
    padding: '12px 24px',
    fontWeight: '700',
    textTransform: 'uppercase',
    fontSize: '12px',
    '& .MuiTypography-root': {
      lineHeight: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
  },
};

export default MuiDialogTitle;
