import React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import {
  SearchInput,
  Sort,
  LayoutButtonGroup,
  CollectionCreateDialog,
  SpellCheck,
  Pagination,
} from '@vidispine/vdt-materialui';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { collection as CollectionApi } from '@vidispine/vdt-api';
import { useTranslation } from 'react-i18next';

import { useSettings } from '@vidispine/vdt-react';
import CustomCollectionGrid from './CustomCollectionGrid';
import CustomCollectionTable from './CustomCollectionTable';
import CustomBinList from '../bin/CustomBinList';
import { SnackbarContext } from '../SnackbarContext';

const GRID_VIEW = 'GRID_VIEW';
const ROW_VIEW = 'ROW_VIEW';
const DEFAULT_VIEW = ROW_VIEW;

export default function CustomCollectionList({
  isLoading,
  collectionListType,
  itemSearchDocument,
  setSearchText,
  onChangeSort,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  paginationOn,
}) {
  const { t } = useTranslation();

  const {
    settings: { viewLayout = DEFAULT_VIEW },
    updateSetting,
  } = useSettings();

  const onChangeViewLayout = (e, newLayout) => updateSetting({ viewLayout: newLayout });

  let ViewComponent;
  switch (viewLayout) {
    case GRID_VIEW:
      ViewComponent = CustomCollectionGrid;
      break;
    case ROW_VIEW:
      ViewComponent = CustomCollectionTable;
      break;
    default:
      ViewComponent = CustomCollectionTable;
      break;
  }

  const { hits: count = 0 } = collectionListType;
  const { suggestion = [] } = collectionListType;
  const { text: [{ value: searchText } = {}] = [] } = itemSearchDocument;
  const searchInputRef = React.createRef();
  const onClickSuggestion = (s) => {
    searchInputRef.current.value = s;
    setSearchText(s);
  };
  const { setNotification } = React.useContext(SnackbarContext);
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const onDrop = ({ dropInfo, dragInfo }) => {
    if (!dropInfo || !dragInfo) {
      return;
    }

    const { entityId: addToEntityId, entityType: addToEntityType } = dropInfo;
    const { entityId, entityType } = dragInfo;
    if (entityId === addToEntityId && entityType === addToEntityType) {
      return;
    }
    if (addToEntityType.toUpperCase() !== 'COLLECTION') {
      setNotification({
        open: true,
        message: `Cannot add entity to ${addToEntityType}`,
        severity: 'warning',
      });
      return;
    }
    const queryParams = { type: entityType.toLowerCase() };
    CollectionApi.addCollectionEntity({ collectionId: addToEntityId, entityId, queryParams })
      .then(() => {
        setNotification({
          open: true,
          // Could also grab entity names from dragInfo.entity
          message:
            `Added ${entityType.toLowerCase()} ${entityId} ` +
            `to ${addToEntityType.toLowerCase()} ${addToEntityId}`,
          severity: 'success',
        });
      })
      .catch((error) => {
        const { response, message } = error;
        const notification = { open: true, severity: 'error', message };
        if (response && response.data && response.data && response.data.invalidInput) {
          notification.message = response.data.invalidInput.explanation || 'Unknown Error';
          notification.severity = 'warning';
        }
        setNotification(notification);
      });
  };

  return (
    <>
      <Box height="95%" width="100%">
        <Grid container alignItems="center" justify="space-between">
          <Grid item sm={8} md={6} lg={6}>
            <SearchInput
              onSubmit={setSearchText}
              submitting={isLoading}
              searchPlaceholder="Search collections"
              inputRef={searchInputRef}
            />
          </Grid>
          <Grid item sm={4} md={6} lg={6}>
            <Grid container alignItems="center" justify="flex-end" spacing={2}>
              <Grid item>
                <Hidden smDown>
                  <Sort
                    count={count}
                    options={[
                      { label: t('created'), value: 'created' },
                      { label: t('title'), value: 'title' },
                    ]}
                    onChange={onChangeSort}
                  />
                </Hidden>
              </Grid>
              {paginationOn && (
                <Grid item>
                  <Hidden smDown>
                    <Pagination
                      onChangePage={onChangePage}
                      onChangeRowsPerPage={onChangeRowsPerPage}
                      count={count}
                      page={page}
                      rowsPerPage={rowsPerPage}
                    />
                  </Hidden>
                </Grid>
              )}
              <Grid item>
                <LayoutButtonGroup
                  viewLayout={viewLayout}
                  onChangeViewLayout={onChangeViewLayout}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setIsDialogOpen(true)}
                >
                  {t('new')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: 'inherit', width: 'inherit', display: 'flex', marginTop: '22px' }}>
          <div style={{ flexDirection: 'column', marginRight: '16px' }}>
            <CustomBinList />
          </div>
          <div style={{ flexGrow: 1 }}>
            <Box>
              {suggestion && suggestion.length > 0 ? (
                <SpellCheck
                  searchText={searchText}
                  suggestions={suggestion}
                  onClick={onClickSuggestion}
                />
              ) : null}
              <ViewComponent collectionListType={collectionListType} onDrop={onDrop} />
            </Box>
          </div>
        </div>
      </Box>
      <CollectionCreateDialog
        onSuccess={({ id }) => history.push(`/collection/${id}/`)}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
}
