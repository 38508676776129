import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { StorageListTree, SelectedEntityWrapper } from '@vidispine/vdt-materialui';
import { Link } from 'react-router-dom';
import { file as FileApi } from '@vidispine/vdt-api';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from './SnackbarContext';

const themeStyles = (theme) => ({
  treeView: {
    height: 240,
    '& .loadMore': {
      fontStyle: 'italic',
    },
  },
  treeBox: {
    // header minHeight, settings padding, header padding, job toolbar
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(3) * 2}px - ${
      theme.spacing(2) * 2
    }px - 60px)`,
    overflow: 'auto',
  },
});

function SelectedFileActions({ fileId, fileType }) {
  const { t } = useTranslation();
  const { setNotification } = React.useContext(SnackbarContext);
  const { item: itemList = [] } = fileType;
  const onClick = () => {
    FileApi.createFileImport({ fileId, metadataDocument: {} })
      .then(() =>
        setNotification({
          open: true,
          message: 'Import Job Created',
          severity: 'success',
        }),
      )
      .catch((error) =>
        setNotification({
          open: true,
          message: error.message,
          severity: 'error',
        }),
      );
  };
  return (
    <Box py={4} px={2}>
      {itemList.length > 0 ? (
        <Button component={Link} to={`/archive/${itemList[0].id}`} disableElevation fullWidth>
          {t('goToItem')}
        </Button>
      ) : (
        <Button onClick={onClick} variant="contained" color="primary" disableElevation fullWidth>
          {t('import')}
        </Button>
      )}
    </Box>
  );
}

function Storage({ classes }) {
  const [selected, setSelected] = React.useState(null);
  return (
    <Box display="flex" flexWrap="nowrap" width="100%">
      <Box width="50%" className={classes.treeBox} display="flex">
        <StorageListTree className={classes.treeView} selected={selected} onChange={setSelected} />
      </Box>
      <Box width="50%" ml={2}>
        {selected && (
          <SelectedEntityWrapper
            selected={selected}
            SelectedFileActionsComponent={SelectedFileActions}
          />
        )}
      </Box>
    </Box>
  );
}

export default withStyles(themeStyles)(Storage);
