import React from 'react';
import { FormControl, FormLabel, FormGroup } from '@material-ui/core';
import { CheckboxField, SelectField } from '@vidispine/vdt-materialui';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  checkboxFormControl: {
    marginTop: '0.6rem',
  },
  checkboxFormGroup: {
    flexDirection: 'row',
  },
});

function Export({
  classes,
  onShapeTagChange,
  onLocationChange,
  shapeTags = [],
  locations = [],
  selectedLocation = '',
}) {
  const locationInput = {
    name: 'location',
    value: selectedLocation,
    onChange: onLocationChange,
  };
  const shapeTagsInput = {
    values: shapeTags.map((shapeTag) => ({
      input: {
        name: shapeTag,
        value: shapeTag,
        onChange: onShapeTagChange,
      },
      label: shapeTag,
    })),
  };

  const checkboxFields = React.useMemo(
    () =>
      shapeTagsInput.values.map((value) => (
        <CheckboxField
          input={value.input}
          key={value.input.name}
          label={value.label}
          checked={value === true}
        />
      )),
    [shapeTagsInput.values],
  );

  return (
    <form>
      <SelectField
        input={locationInput}
        options={locations}
        FormControlProps={{ disabled: !locations.length }}
        label="Export location"
        meta={
          !locations.length ? { error: 'No export locations configured', touched: true } : undefined
        }
      />
      <FormControl component="fieldset" className={classes.checkboxFormControl}>
        <FormLabel component="legend">Formats</FormLabel>
        <FormGroup className={classes.checkboxFormGroup}>{checkboxFields}</FormGroup>
      </FormControl>
    </form>
  );
}

export default withStyles(styles, { name: 'VdtExportForm' })(Export);
