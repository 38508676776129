import React from 'react';
import { collection as CollectionApi } from '@vidispine/vdt-api';

const useCollectionAutoPlay = ({ collectionId, defaultEnabled = true }) => {
  const [nextPlaying, setNextPlaying] = React.useState();
  const [isAutoPlayEnabled, setIsAutoPlayEnabled] = React.useState(defaultEnabled);
  const toggleAutoPlay = (newValue) =>
    setIsAutoPlayEnabled((prevValue) => (newValue !== undefined ? newValue : !prevValue));
  const allItemsRef = React.useRef();
  const orderItemsRef = React.useRef();
  const hitsRef = React.useRef();
  const onNext = React.useCallback(
    ({ itemId }) => {
      if (isAutoPlayEnabled === false) return;
      const searchDocument = {
        field: [
          {
            name: 'mediaType',
            value: [{ value: 'video' }, { value: 'audio' }],
          },
        ],
      };
      const searchForNext = ({ first, number } = {}) => {
        const { current: allItems } = allItemsRef;
        const { current: orderItems } = orderItemsRef;
        if (!Array.isArray(allItems)) {
          Promise.all([
            CollectionApi.getCollection({
              collectionId,
              queryParams: { children: 'item' },
            }),
            CollectionApi.searchCollectionItem({
              collectionId,
              searchDocument,
              queryParams: { first, number },
            }),
          ]).then(([{ data: collectionType }, { data: itemListType }]) => {
            const { hits, item: itemList = [] } = itemListType;
            allItemsRef.current = itemList.map(({ id }) => id);
            hitsRef.current = hits;
            const { content: contentList } = collectionType;
            orderItemsRef.current = contentList;
            searchForNext({ first: 1, number: 100 });
          });
          return;
        }
        const { current: hits } = hitsRef;
        const currentItemIdx = orderItems.findIndex(({ id: thisId }) => thisId === itemId);
        if (currentItemIdx + 1 === orderItems.length) {
          // last item in the list
          setNextPlaying();
          return;
        }
        if (currentItemIdx === -1 && hits > allItems.length) {
          // request more items to find the currentItem
          CollectionApi.searchCollectionItem({
            collectionId,
            searchDocument,
            queryParams: { first: first + number, number },
          }).then(({ data: itemListType }) => {
            const { item: itemList = [] } = itemListType;
            allItemsRef.current.push(...itemList.map(({ id }) => id));
            searchForNext({ first: first + number, number });
          });
          return;
        }
        const remainingItems = orderItems.slice(currentItemIdx + 1);
        const nextItem = remainingItems.find(({ id: thisId }) => allItems.includes(thisId));
        setNextPlaying(nextItem);
      };
      searchForNext();
    },
    [collectionId, isAutoPlayEnabled],
  );
  return {
    isAutoPlayEnabled,
    toggleAutoPlay,
    setNextPlaying,
    nextPlaying,
    onNext,
  };
};

export default useCollectionAutoPlay;
