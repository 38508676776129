import React from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { Autocomplete } from '@material-ui/lab';

import { QueryBuilderCard } from '@vidispine/vdt-materialui';
import {
  useAddMetadataFieldAttributes,
  withErrorBoundary,
  useAutocomplete,
} from '@vidispine/vdt-react';

import { ADVANCED_SEARCH_QUERY_FIELDS, ADVANCED_SEARCH_QUERY_GROUPS } from '../const';

const AUTOCOMPLETE_DEBOUNCE_TIMEOUT = 300;

function autoCompleteHoc(InputComponent) {
  return ({ value, onChange, name, AutoCompleteInputProps, ...props }) => {
    const { setAutocompleteField, setAutocompleteText, suggestions } = useAutocomplete();

    const [localValue, setLocalValue] = React.useState(value);
    const debouncedAutcomplete = React.useRef(
      debounce((val) => setAutocompleteText(val), AUTOCOMPLETE_DEBOUNCE_TIMEOUT),
    ).current;

    React.useEffect(() => setLocalValue(value), [value]);

    const onClick = (e) => {
      e.stopPropagation();
      if (name) setAutocompleteField(name, localValue);
    };

    const onInputChange = (e, val, reason) => {
      if (e) e.stopPropagation();
      setLocalValue(val);
      if (val !== localValue && reason !== 'reset') debouncedAutcomplete(val);
    };

    const onAutocompleteChange = (_, val) => {
      if (value !== val) {
        onChange({ target: { value: val } });
      }
    };

    const emitChange = () => {
      if (value !== localValue) {
        onChange({ target: { value: localValue } });
      }
    };

    return (
      <Autocomplete
        style={{ width: 250 }}
        value={localValue}
        onInputChange={onInputChange}
        onChange={onAutocompleteChange}
        freeSolo
        disableClearable
        options={suggestions}
        getOptionLabel={(option) => option && option}
        renderInput={(params) => (
          <InputComponent
            onClick={onClick}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            // eslint-disable-next-line react/jsx-props-no-spreading
            onBlur={emitChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                emitChange();
              }
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      />
    );
  };
}

function AdvancedSearch({ onSubmit, itemSearchDocument, ...props }) {
  const { t } = useTranslation();

  const { groupsWithAttributes, isLoading } = useAddMetadataFieldAttributes({
    groups: ADVANCED_SEARCH_QUERY_GROUPS,
  });

  if (isLoading) return null;
  return (
    <QueryBuilderCard
      searchText={t('search')}
      resetText={t('reset')}
      fields={ADVANCED_SEARCH_QUERY_FIELDS}
      groups={groupsWithAttributes}
      onSubmit={onSubmit}
      itemSearchDocument={itemSearchDocument}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      QueryBuilderProps={{
        QueryEntryProps: {
          FieldProps: {
            PredicateComponentProps: {
              withAutocomplete: true,
              autoCompleteHoc,
            },
          },
        },
      }}
    />
  );
}

export default withErrorBoundary(AdvancedSearch);
