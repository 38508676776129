import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { metadatafield as MetadataFieldApi, fieldgroup as FieldGroupApi } from '@vidispine/vdt-api';

const getValueFromData = ({ data = [] } = {}, key) => {
  if (!key) return undefined;
  const { value } = data.find((d) => d.key === key) || {};
  return value;
};

const defaultGetDataAttributes = (fieldOrGroup, keys) => {
  return Object.assign(
    {},
    ...keys.map((key) => {
      return {
        [key]: getValueFromData(fieldOrGroup, key),
      };
    }),
  );
};

const getOptions = ({ values: { field } = {} } = {}) => {
  return field ? field.map((f) => ({ value: f.value, label: f.key || f.value })) : undefined;
};

const convertValuesXmlToJson = (xmlString) => {
  const doc = new DOMParser().parseFromString(xmlString, 'application/xml');
  const fields = doc.getElementsByTagName('field');
  if (!fields.length) {
    return undefined;
  }
  const json = { field: [] };
  for (let i = 0; i < fields.length; i += 1) {
    const [keyNode, valueNode] = fields[i].childNodes;
    json.field.push({
      [keyNode.nodeName]: keyNode.firstChild.nodeValue,
      [valueNode.nodeName]: valueNode.firstChild.nodeValue,
    });
  }
  return json;
};

const useAddMetadataFieldAttributes = ({
  fields,
  groups,
  getDataAttributes = defaultGetDataAttributes,
  fieldDataToInclude = 'label,displayType,required',
  groupDataToInclude = 'label,order',
  fieldQueryParams = {},
  groupQueryParams = {},
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [fieldsWithAttributes, setFieldsWithAttributes] = React.useState(fields);
  const [groupsWithAttributes, setGroupsWithAttributes] = React.useState(groups);

  useDeepCompareEffect(() => {
    const addFieldAttributes = (fieldsArray = [], fieldsAttributes = {}) =>
      fieldsArray.map(({ name, ...overrides }) => {
        const fieldAttributes = fieldsAttributes[name] || {};
        const { label, ...dataAttributes } = getDataAttributes(
          fieldAttributes,
          fieldDataToInclude.split(','),
        );
        return {
          name,
          label: label || name,
          type: fieldAttributes.type,
          constraint: fieldAttributes.constraint,
          options: getOptions(fieldAttributes),
          ...dataAttributes,
          ...overrides,
        };
      });

    const addGroupAttributes = (groupsArray = [], groupsAttributes = {}, fieldsAttributes = {}) =>
      groupsArray.map(({ name, field, group, ...overrides }) => {
        const groupAttributes = groupsAttributes[name] || {};
        const childFields = field || (groupAttributes && groupAttributes.field);
        const childGroups = group || (groupAttributes && groupAttributes.group);
        const { label, ...dataAttributes } = getDataAttributes(
          groupAttributes,
          groupDataToInclude.split(','),
        );
        return {
          name,
          label: label || name,
          order: getValueFromData(groupAttributes, 'order'),
          fields: addFieldAttributes(childFields, fieldsAttributes),
          groups: addGroupAttributes(childGroups, groupsAttributes, fieldsAttributes),
          ...dataAttributes,
          ...overrides,
        };
      });
    let didCancel = false;
    async function fetchAttributesAsync() {
      setIsLoading(true);
      const fieldsAttributes = {};
      const groupsAttributes = {};
      let fieldPromises = [];
      if (fields) {
        const queryParams = { ...fieldQueryParams, data: fieldDataToInclude };
        fieldPromises = fields.map(async ({ name: fieldName }) => {
          try {
            const { data } = await MetadataFieldApi.getMetadataField({
              fieldName,
              queryParams,
            });
            fieldsAttributes[fieldName] = data;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(`Could not retrieve attributes for ${fieldName}`);
          }
        });
      }
      let groupPromises = [];
      if (groups) {
        const groupsToFetch = [...groups];
        const queryParams = { ...groupQueryParams, data: groupDataToInclude };
        groupPromises = groupsToFetch.map(async ({ name: groupName }) => {
          try {
            const { data } = await FieldGroupApi.getFieldGroup({
              groupName,
              queryParams,
            });
            const { field = [], group = [] } = data;
            field.forEach((f) => {
              const dataValues = f.data && f.data.find((e) => e.key === '__values');
              if (dataValues) {
                const jsonValues = convertValuesXmlToJson(dataValues.value);
                fieldsAttributes[f.name] = { ...f, values: jsonValues };
              }
            });
            group.forEach((g) => {
              groupsToFetch.push(g);
            });
            groupsAttributes[groupName] = data;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(`Could not retrieve attributes for ${groupName}`);
          }
        });
      }
      await Promise.all([...fieldPromises, ...groupPromises]);
      if (!didCancel) {
        if (Object.values(fieldsAttributes).some((v) => v)) {
          setFieldsWithAttributes(() => addFieldAttributes(fields, fieldsAttributes));
        }
        if (Object.values(groupsAttributes).some((v) => v)) {
          setGroupsWithAttributes(() => {
            const newGroupsWithAttributes = addGroupAttributes(
              groups,
              groupsAttributes,
              fieldsAttributes,
            );
            newGroupsWithAttributes.forEach((group) => {
              const { order } = group;
              if (order) {
                group.fields.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
              }
            });
            return newGroupsWithAttributes;
          });
        }
        setIsLoading(false);
      }
    }
    fetchAttributesAsync();
    return () => {
      didCancel = true;
    };
  }, [fields, groups, fieldDataToInclude, groupDataToInclude, fieldQueryParams, groupQueryParams]);

  return { fieldsWithAttributes, groupsWithAttributes, isLoading };
};

export default useAddMetadataFieldAttributes;
