import React from 'react';
import { useSearch, SearchCollection } from '@vidispine/vdt-react';
import CustomCollectionList from './CustomCollectionList';
import SearchCollectionPagination from './SearchCollectionPagination';

const initialState = {
  queryParams: {
    field: ['collectionId', 'title', 'created', '__user'],
    content: 'metadata',
  },
  itemSearchDocument: {
    operator: {
      field: [
        {
          name: 'eidr_referentType',
          value: [{ value: 'savedSearch' }],
        },
      ],
      operation: 'NOT',
    },
    suggestion: {
      maximumSuggestions: 3,
      accuracy: 0.7,
    },
  },
};

export default function SearchCollectionList() {
  const {
    state,
    setSearchText,
    setSearchFilter,
    onChangeRowsPerPage,
    onChangePage,
    onChangeSort,
    setFacet,
  } = useSearch(initialState);
  const { matrixParams, queryParams, itemSearchDocument, page, rowsPerPage } = state;
  return (
    <SearchCollection
      itemSearchDocument={itemSearchDocument}
      matrixParams={matrixParams}
      queryParams={queryParams}
    >
      <CustomCollectionList
        setSearchFilter={setSearchFilter}
        setSearchText={setSearchText}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangeSort={onChangeSort}
        setFacet={setFacet}
        page={page}
        rowsPerPage={rowsPerPage}
        // paginationOn
      />
      <SearchCollectionPagination
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </SearchCollection>
  );
}
