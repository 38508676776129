import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Radio from '@material-ui/core/Radio';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Skeleton from '@material-ui/lab/Skeleton';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import InfoIcon from '@material-ui/icons/Info';
import { item as ItemApi, bulkymetadata as BulkyApi, shape as ShapeApi } from '@vidispine/vdt-api';
import { useApi } from '@vidispine/vdt-react';
import { parseMetadataType, formatSeconds } from '@vidispine/vdt-js';

import { withDialog } from '../DialogContext';
import { useTranscoderResource } from '../ResourceContext';

export const DIALOG_ID = 'ITEM_SHRYNK_DIALOG';
const DEFAULT_SHRYNK_MODELS = [
  { value: 'football', label: 'Soccer', AvatarIcon: SportsSoccerIcon },
  { value: 'ice-hockey', label: 'Ice Hockey', AvatarIcon: SportsHockeyIcon },
  { value: 'basketball', label: 'Basketball', AvatarIcon: SportsBasketballIcon },
  { value: 'formula1', label: 'Formula 1', AvatarIcon: SportsMotorsportsIcon },
  { value: 'handball', label: 'Handball', AvatarIcon: SportsHandballIcon },
];

const useShrynk = (itemId, tag) => {
  const [highlighter, setHighlighter] = React.useState(undefined);

  const { data: itemType = {}, request: getItem, isLoading: isGetItemLoading } = useApi(
    ItemApi.getItem,
  );
  const { durationSeconds } = React.useMemo(
    () => parseMetadataType(itemType.metadata, { arrayOnSingle: false, flat: true }),
    [itemType],
  );

  React.useEffect(() => {
    const queryParams = {
      field: ['durationSeconds'],
      content: ['metadata'],
      interval: 'generic',
    };
    getItem({ itemId, queryParams });
  }, [itemId, getItem]);

  const { data: { uri: [shapeId] = [] } = {}, request: listShape } = useApi(ShapeApi.listShape);

  React.useEffect(() => {
    const queryParams = {
      tag: 'original',
    };
    listShape({ itemId, queryParams });
  }, [itemId, listShape]);

  const {
    data: { uri: bulkyMetadataList = [] } = {},
    request: listShapeBulkyMetadata,
    isLoading: isListShapeBulkyMetadataLoading,
  } = useApi(BulkyApi.listShapeBulkyMetadata);

  React.useEffect(() => {
    if (!shapeId) return;
    listShapeBulkyMetadata({ itemId, shapeId });
  }, [listShapeBulkyMetadata, itemId, shapeId]);

  React.useEffect(() => {
    if (!shapeId) return;
    const hasHighlights = bulkyMetadataList.includes('highlighter');
    const hasModel = bulkyMetadataList.includes('model');
    if (hasModel) {
      BulkyApi.getShapeBulkyMetadata({
        itemId,
        shapeId,
        key: 'model',
      }).then(({ data: bulkyMetadataType }) => {
        const { field: [{ value: currentModel }] = [{}] } = bulkyMetadataType;
        if (currentModel) setHighlighter(currentModel);
      });
    } else if (hasHighlights) setHighlighter('unknown');
  }, [itemId, shapeId, bulkyMetadataList]);

  const onRender = (duration) =>
    ShapeApi.createHighlight({
      itemId,
      shapeId,
      queryParams: { tag, duration },
    });

  const onAnalyze = (model) => {
    const analyzeJobDocument = { highlighter: { model } };
    BulkyApi.updateShapeBulkyMetadata({ itemId, shapeId, key: 'model', value: model });
    return ShapeApi.createShapeAnalyze({
      itemId,
      shapeId,
      analyzeJobDocument,
    });
  };

  const isLoading = isListShapeBulkyMetadataLoading || isGetItemLoading;
  return {
    durationSeconds: Math.round(durationSeconds),
    highlighter,
    onAnalyze,
    onRender,
    isLoading,
  };
};

const valueLabelFormat = (value) => formatSeconds(value).toDuration();

const styles = (theme) => ({
  dialogContent: {
    minHeight: 200,
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  slider: {
    width: '80%',
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
});

const ResourceStep = ({ classes, resourceId }) => {
  return (
    <DialogContent className={classes.dialogContent}>
      {resourceId ? 'Transcoder Ready' : 'No Vidispine Transcoders configured in VidiNet'}
    </DialogContent>
  );
};

const AnalyzeStep = ({ classes, shrynkModels, onAnalyze, highlighter, onSuccess, onError }) => {
  const [model, setModel] = React.useState(highlighter);
  const onChangeModel = (newModel) => () => setModel(newModel !== model ? newModel : undefined);
  const onClick = () => onAnalyze(model).then(onSuccess).catch(onError);
  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <List
          dense
          className={classes.list}
          subheader={<ListSubheader>Choose Analysis Model</ListSubheader>}
        >
          {shrynkModels.map(({ label, value, AvatarIcon }) => (
            <ListItem key={value} button onClick={onChangeModel(value)} selected={model === value}>
              <ListItemAvatar>
                <Avatar variant="square">
                  {AvatarIcon ? <AvatarIcon /> : <span>{label[0]}</span>}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={label} />
              <ListItemSecondaryAction>
                <Radio edge="end" checked={model === value} onClick={onChangeModel(value)} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        {!highlighter && !model && (
          <>
            <InfoIcon />
            <Typography color="textSecondary" variant="subtitle2">
              Analysis required before transcoding
            </Typography>
          </>
        )}
        {highlighter && model && (
          <>
            <InfoIcon />
            <Typography color="textSecondary" variant="subtitle2">
              This action will perform a new analysis and overwrite existing data
            </Typography>
          </>
        )}
        <Tooltip
          arrow
          placement="top"
          title={
            <Typography variant="subtitle2">Perform new analysis with selected model</Typography>
          }
        >
          <span>
            <Button variant="contained" color="primary" onClick={onClick} disabled={!model}>
              Analyze
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  );
};

const TranscodeStep = ({
  classes,
  shrynkModels,
  highlighter,
  durationSeconds,
  onRender,
  onSuccess,
  onError,
}) => {
  const [transcodeDuration, setTranscodeDuration] = React.useState(undefined);
  const onChangeTranscodeDuration = (evt, value) => setTranscodeDuration(value);
  const [newItem, setNewItem] = React.useState(false);
  const handleNewItem = ({ target }) => setNewItem(target.checked);
  const currentModel = shrynkModels.find(({ value }) => value === highlighter);
  if (!currentModel) return null;
  const { AvatarIcon, label } = currentModel;
  const onClick = () => onRender(transcodeDuration).then(onSuccess).catch(onError);
  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <List dense className={classes.list}>
            <ListItem selected>
              <ListItemAvatar>
                <Avatar variant="square">
                  {AvatarIcon ? <AvatarIcon /> : <span>{label[0]}</span>}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={label} />
              <ListItemSecondaryAction>
                <Radio edge="end" checked disabled />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
        <Typography style={{ marginTop: 16 }} gutterBottom>
          Output duration
        </Typography>
        <Slider
          className={classes.slider}
          valueLabelDisplay="on"
          valueLabelFormat={valueLabelFormat}
          min={0}
          max={durationSeconds}
          defaultValue={durationSeconds}
          onChange={onChangeTranscodeDuration}
          step={1}
        />
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox checked={newItem} onChange={handleNewItem} name="checkedB" color="primary" />
          }
          label="Create new item"
        />
        <Tooltip
          arrow
          placement="top"
          title={
            <Typography variant="subtitle2">Transcode with existing analysis model</Typography>
          }
        >
          <span>
            <Button variant="contained" color="primary" onClick={onClick}>
              Transcode
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  );
};

const SkeletonStepper = ({ steps = 3 }) => (
  <Stepper>
    {[...Array(steps)].map((key) => (
      <Step key={key}>
        <StepLabel
          StepIconComponent={Skeleton}
          StepIconProps={{
            variant: 'circle',
            height: 24,
            width: 24,
          }}
        >
          <Skeleton variant="rect" width={70} height={20} />
        </StepLabel>
      </Step>
    ))}
  </Stepper>
);

function ItemShrynkDialogComponent({
  classes,
  itemId,
  shrynkModels = DEFAULT_SHRYNK_MODELS,
  shapeTag = '__mp4',
  onClose,
  onSuccess,
  onError,
}) {
  const { resource: { id: resourceId } = {} } = useTranscoderResource();
  const { durationSeconds, highlighter, onAnalyze, onRender, isLoading } = useShrynk(
    itemId,
    shapeTag,
  );
  const [activeStep, setActiveStep] = React.useState();
  React.useEffect(() => {
    if (!resourceId) setActiveStep(0);
    else if (resourceId && !highlighter) setActiveStep(1);
    else if (highlighter) setActiveStep(2);
  }, [highlighter, resourceId]);
  return (
    <>
      <DialogTitle disableTypography>
        <Typography variant="h6">Nablet Shrynk</Typography>
        <Typography variant="subtitle2">
          Create highlight reels based on Nablet analysis. Duration of output is approximate.
        </Typography>
      </DialogTitle>
      <Divider />
      {isLoading ? (
        <DialogContent>
          <SkeletonStepper classes={classes} />{' '}
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <Stepper activeStep={activeStep} nonLinear>
              <Step>
                <StepButton completed={Boolean(resourceId)} onClick={() => setActiveStep(0)}>
                  VidiNet
                </StepButton>
              </Step>
              <Step>
                <StepButton completed={Boolean(highlighter)} onClick={() => setActiveStep(1)}>
                  Analyze
                </StepButton>
              </Step>
              <Step>
                <StepButton onClick={() => setActiveStep(2)}>Transcode</StepButton>
              </Step>
            </Stepper>
          </DialogContent>
          {
            {
              0: <ResourceStep classes={classes} resourceId={resourceId} />,
              1: (
                <AnalyzeStep
                  classes={classes}
                  onAnalyze={onAnalyze}
                  shrynkModels={shrynkModels}
                  highlighter={highlighter}
                  onSuccess={() => {
                    onSuccess();
                    onClose();
                  }}
                  onError={onError}
                />
              ),
              2: (
                <TranscodeStep
                  classes={classes}
                  highlighter={highlighter}
                  durationSeconds={durationSeconds}
                  shrynkModels={shrynkModels}
                  onRender={onRender}
                  onSuccess={() => {
                    onSuccess();
                    onClose();
                  }}
                  onError={onError}
                />
              ),
            }[activeStep]
          }
        </>
      )}
    </>
  );
}

export default withStyles(styles)(withDialog(DIALOG_ID, ItemShrynkDialogComponent));
