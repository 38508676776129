import { item as ItemApi } from '@vidispine/vdt-api';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useApi from './useApi';

export default function useSearchItem({
  itemSearchDocument,
  queryParams,
  matrixParams,
  advancedParameter,
}) {
  const { request, data: itemListType = {}, isLoading, isError } = useApi(ItemApi.searchItem);
  const onRefresh = () =>
    request({
      itemSearchDocument:
        itemSearchDocument.text &&
        Array.isArray(itemSearchDocument.text) &&
        itemSearchDocument.text.length > 0
          ? {
              ...itemSearchDocument,
              text: [{ value: `${itemSearchDocument.text[0].value} AND ${advancedParameter}` }],
            }
          : {
              ...itemSearchDocument,
              text: [{ value: `${advancedParameter}` }],
            },
      queryParams,
      matrixParams,
    });
  useDeepCompareEffect(() => {
    onRefresh();
  }, [itemSearchDocument, queryParams, matrixParams]);
  return {
    itemListType,
    isLoading,
    isError,
    onRefresh,
  };
}
