import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

function ExportLocationList({ exportLocationListType = {}, onEditLocation, onDeleteLocation }) {
  const { exportLocation: exportLocationList = [] } = exportLocationListType;
  return (
    <List>
      {exportLocationList
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((location) => (
          <ListItem
            key={location.name}
            button
            onClick={onEditLocation ? () => onEditLocation(location) : undefined}
          >
            <ListItemText primary={location.name} secondary={location.uri} />
            {onDeleteLocation && (
              <ListItemSecondaryAction>
                <IconButton onClick={() => onDeleteLocation(location)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
    </List>
  );
}

export default ExportLocationList;
