const MuiDialog = {
  root: {},
  scrollPaper: {},
  scrollBody: {},
  container: {},
  paper: {
    background: '#2a3241',
    border: 'solid 1px #4a5366',
    borderRadius: '3px',
  },
  paperScrollPaper: {},
  paperScrollBody: {},
  paperWidthFalse: {},
  paperWidthXs: {
    minWidth: '525px',
  },
  paperWidthSm: {
    minWidth: '525px',
  },
  paperWidthMd: {
    minWidth: '525px',
  },
  paperWidthLg: {
    minWidth: '525px',
  },
  paperWidthXl: {
    minWidth: '525px',
  },
  paperFullWidth: {},
  paperFullScreen: {},
};

export default MuiDialog;
