import React from 'react';
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  TextField,
  Button,
} from '@material-ui/core';
import { exportlocation as ExportLocationApi } from '@vidispine/vdt-api';

import { withDialog } from '../DialogContext';

export const DIALOG_ID = 'CREATE_EXPORTLOCATION_DIALOG';

function CreateExportLocationDialog({ onSuccess, onError, onClose }) {
  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      name: { value: name },
      uri: { value: uri },
      script: { value: script },
    } = event.target;
    const exportLocationDocument = { uri, script, name };
    try {
      ExportLocationApi.updateExportLocation({
        locationName: name,
        exportLocationDocument,
      })
        .then(() => {
          if (onSuccess) onSuccess(exportLocationDocument);
          onClose();
        })
        .catch((error) => {
          if (onError) onError(error);
        });
    } catch (error) {
      if (onError) onError(error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>Add New Export Location</DialogTitle>
      <DialogContent>
        <FormControl autoComplete="off" fullWidth>
          <TextField name="name" label="Name" required />
        </FormControl>
        <FormControl autoComplete="off" fullWidth>
          <TextField placeholder="ftp://user:pass@example.com/example/" name="uri" label="URI" />
        </FormControl>
        <FormControl autoComplete="off" fullWidth>
          <TextField name="script" label="Script" multiline />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default withDialog(DIALOG_ID, CreateExportLocationDialog);
