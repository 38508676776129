const VdtBinContentDroppable = {
  root: {
    paddingTop: '16px',
    paddingBottom: '16px',
    '&:empty::after': {
      content: '"Drag and drop items to the bin..."',
      height: '63px',
      border: '1px dashed #ffffff99',
      color: '#ffffff99',
      display: 'grid',
      placeItems: 'center',
    },
  },
  canDrop: {
    '&.VdtDroppable-isOver': {
      backgroundColor: '#7affd522',
      '&::after': {
        backgroundColor: '#7affd522',
      },
    },
    '&::after': {
      content: '"Drop your item here..."',
      height: '63px',
      border: '1px dashed #7affd599',
      color: '#7affd599',
      display: 'grid',
      placeItems: 'center',
    },
  },
};

export default VdtBinContentDroppable;
