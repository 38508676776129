import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { LoginForm } from '@vidispine/vdt-materialui';
import { useTranslation } from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';

const SERVER_URL_PARAM = 'to';
const params = new URLSearchParams(window.location.search);
const defaultServerUrl = params.get(SERVER_URL_PARAM) || '';

export default function Login({ onLogin, userName, serverUrl = defaultServerUrl, onBack }) {
  const { t } = useTranslation();
  return (
    <Grid container direction="row" alignItems="stretch">
      <Hidden only={['xs', 'sm']}>
        <Grid container item xs={8} style={{ background: '#41495a', minHeight: '100vh' }}>
          <div style={{ display: 'grid', placeItems: 'center', width: '100%', height: '100vh' }}>
            <div style={{ position: 'relative' }}>
              <img
                src="/QVEST-Logo-2021-WHITE.svg"
                alt="QVEST"
                style={{ height: '150px', display: 'block' }}
              />{' '}
              <Typography
                component="p"
                variant="body2"
                style={{ position: 'absolute', right: '0', bottom: '-0.7em' }}
              >
                Cloud Archive based on Vidispine
              </Typography>
            </div>
          </div>
        </Grid>
      </Hidden>
      <Grid container item xs={12} md={4} style={{ background: '#2b3342', height: '100vh' }}>
        <div
          style={{
            width: '80%',
            maxWidth: '310px',
            margin: 'auto',
          }}
        >
          <LoginForm
            onSubmit={onLogin}
            FormProps={{
              initialValues: { userName, serverUrl },
            }}
            FormFieldsProps={{
              UrlFieldComponent: serverUrl ? null : undefined,
              RememberMeFieldComponent: null,
            }}
            FormButtonsProps={{
              SubmitButtonProps: { variant: 'outlined', color: 'primary' },
            }}
          />
          {onBack && (
            <Button
              onClick={onBack}
              size="small"
              variant="outlined"
              style={{
                borderRadius: '3px',
                height: '36px',
                width: '100%',
                marginTop: '24px',
              }}
            >
              {t('Back To Server List')}
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
