import React from 'react';
import { utils as api } from '@vidispine/vdt-api';

export default class VidispineApi extends React.Component {
  constructor(props) {
    super(props);
    const { serverUrl, token, bearerToken, username, password, runAs } = props;
    if (serverUrl) {
      api.defaultClient.defaults.baseURL = serverUrl;
    }
    if (bearerToken) {
      api.defaultClient.defaults.headers.Authorization = `Bearer ${bearerToken}`;
    } else if (token) {
      api.defaultClient.defaults.headers.Authorization = `token ${token}`;
    } else {
      api.defaultClient.defaults.auth = { username, password };
    }
    if (runAs) {
      api.defaultClient.defaults.headers.RunAs = runAs;
    }
  }

  componentDidUpdate(prevProps) {
    const { serverUrl, token, bearerToken, username, password, runAs } = this.props;
    if (prevProps.serverUrl !== serverUrl) {
      if (serverUrl) {
        api.defaultClient.defaults.baseURL = serverUrl;
      } else {
        delete api.defaultClient.defaults.baseURL;
      }
    }
    if (prevProps.bearerToken !== bearerToken) {
      if (bearerToken) {
        api.defaultClient.defaults.headers.Authorization = `Bearer ${bearerToken}`;
      } else {
        delete api.defaultClient.defaults.headers.Authorization;
      }
    }
    if (!bearerToken && prevProps.token !== token) {
      if (token) {
        api.defaultClient.defaults.headers.Authorization = `token ${token}`;
      } else {
        delete api.defaultClient.defaults.headers.Authorization;
      }
    }
    if (
      !bearerToken &&
      !token &&
      (prevProps.username !== username || prevProps.password !== password)
    ) {
      api.defaultClient.defaults.auth = { username, password };
    }
    if (prevProps.runAs !== runAs) {
      if (runAs) {
        api.defaultClient.defaults.headers.RunAs = runAs;
      } else {
        delete api.defaultClient.defaults.headers.RunAs;
      }
    }
  }

  render() {
    const { children, username, serverUrl } = this.props;
    const cloneProps = { username, serverUrl };
    const cloneChild = (child) => React.cloneElement(child, cloneProps);
    return React.Children.map(children, cloneChild);
  }
}
