const MuiTablePagination = {
  root: {
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: '#2e3846',
    borderRadius: '3px',
    '& .MuiIconButton-root': {
      padding: '12px 6px',
      '&:hover': {
        background: 'transparent',
      },
      '& .MuiSvgIcon-root': {
        width: '1.1em',
        height: '1.1em',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
  },
  toolbar: {
    paddingTop: '5px',
    paddingBottom: '5px',
    marginLeft: '24px',
    marginRight: '24px',
    borderTop: '0.0625rem solid #47556f',
  },
  spacer: {},
  selectRoot: {
    borderRadius: '3px',
    borderColor: '#4a5366',
    borderWidth: '1px',
    borderStyle: 'solid',
    background: '#333d4c',
    lineHeight: '1.125',
    padding: '11px 41px 11px 13px',
    fontWeight: '700',
    marginLeft: '20px',

    '-webkit-font-smoothing': 'auto',
    '-moz-osx-font-smoothing': 'auto',
    '&:hover': {
      background: '#2b3440',
    },
  },
  select: { marginLeft: 0, padding: '11px 41px 11px 13px !important' },
  selectIcon: { marginRight: '8px' },
  input: {
    padding: 0,
  },
  menuItem: {},
  actions: {},
  caption: {
    fontWeight: 'bold',
    paddingRight: '30px',
    '&:first-of-type': {
      fontWeight: '500',
      fontSize: '12px',
      textTransform: 'uppercase',
      paddingRight: '0',
      '-webkit-font-smoothing': 'auto',
      '-moz-osx-font-smoothing': 'auto',
    },
  },
};

export default MuiTablePagination;
