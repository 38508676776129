import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `POST /API/item/${itemId}/thumbnail`
 *
 * @function thumbnail.createThumbnail
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createThumbnail({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/thumbnail`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `GET /API/item/${itemId}/thumbnailresource`
 *
 * @function thumbnail.listThumbnailResource
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function listThumbnailResource({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/thumbnailresource`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `PUT /API/item/${itemId}/thumbnailresource`
 *
 * @function thumbnail.createThumbnailResource
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createThumbnailResource({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/thumbnailresource`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `GET /API/thumbnail/${resourceId}/${itemId}`
 *
 * @function thumbnail.listThumbnail
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @returns {Promise<Object>}
 */
function listThumbnail({ itemId = isRequired(), resourceId = isRequired(), ...props }) {
  const path = `/API/thumbnail/${resourceId}/${itemId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `DELETE /API/thumbnail/${resourceId}/${itemId}`
 *
 * @function thumbnail.removeAllThumbnail
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @returns {Promise<Object>}
 */
function removeAllThumbnail({ itemId = isRequired(), resourceId = isRequired(), ...props }) {
  const path = `/API/thumbnail/${resourceId}/${itemId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `PUT /API/thumbnail/${resourceId}/${itemId}/${time}`
 *
 * @function thumbnail.updateThumbnail
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @param {string} obj.time
 * @returns {Promise<Object>}
 */
function updateThumbnail({
  itemId = isRequired(),
  resourceId = isRequired(),
  time = isRequired(),
  headers: propHeaders = {},
  file,
  ...props
}) {
  const path = `/API/thumbnail/${resourceId}/${itemId}/${time}`;
  const headers = { accept: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'PUT',
    body: file,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `GET /API/thumbnail/${resourceId}/${itemId}/${time}`
 *
 * @function thumbnail.getThumbnail
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @param {string} obj.time
 * @returns {Promise<Object>}
 */
function getThumbnail({
  itemId = isRequired(),
  resourceId = isRequired(),
  time = isRequired(),
  headers: propsHeaders = {},
  ...props
}) {
  const path = `/API/thumbnail/${resourceId}/${itemId}/${time}`;
  const headers = { accept: 'image/png, image/jpeg', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `GET /API/thumbnail/${resourceId}/${itemId}/${time}/export`
 *
 * @function thumbnail.exportThumbnail
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @param {string} obj.time
 * @returns {Promise<Object>}
 */
function exportThumbnail({
  itemId = isRequired(),
  resourceId = isRequired(),
  time = isRequired(),
  ...props
}) {
  const path = `/API/thumbnail/${resourceId}/${itemId}/${time}/export`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `DELETE /API/thumbnail/${resourceId}/${itemId}/${time}`
 *
 * @function thumbnail.removeThumbnail
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @param {string} obj.time
 * @returns {Promise<Object>}
 */
function removeThumbnail({
  itemId = isRequired(),
  resourceId = isRequired(),
  time = isRequired(),
  ...props
}) {
  const path = `/API/thumbnail/${resourceId}/${itemId}/${time}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `GET /API/item/${itemId}/posterresource`
 *
 * @function thumbnail.listPosterResource
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function listPosterResource({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/posterresource`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `PUT /API/item/${itemId}/posterresource`
 *
 * @function thumbnail.createPosterResource
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createPosterResource({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/posterresource`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `GET /API/poster/${resourceId}/${itemId}`
 *
 * @function thumbnail.listPoster
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @returns {Promise<Object>}
 */
function listPoster({ itemId = isRequired(), resourceId = isRequired(), ...props }) {
  const path = `/API/poster/${resourceId}/${itemId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `DELETE /API/poster/${resourceId}/${itemId}`
 *
 * @function thumbnail.removeAllPoster
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @returns {Promise<Object>}
 */
function removeAllPoster({ itemId = isRequired(), resourceId = isRequired(), ...props }) {
  const path = `/API/poster/${resourceId}/${itemId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `PUT /API/poster/${resourceId}/${itemId}/${time}`
 *
 * @function thumbnail.updatePoster
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @param {string} obj.time
 * @returns {Promise<Object>}
 */
function updatePoster({
  itemId = isRequired(),
  resourceId = isRequired(),
  time = isRequired(),
  ...props
}) {
  const path = `/API/poster/${resourceId}/${itemId}/${time}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `GET /API/poster/${resourceId}/${itemId}/${time}`
 *
 * @function thumbnail.getPoster
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @param {string} obj.time
 * @returns {Promise<Object>}
 */
function getPoster({
  itemId = isRequired(),
  resourceId = isRequired(),
  time = isRequired(),
  headers: propsHeaders = {},
  ...props
}) {
  const path = `/API/poster/${resourceId}/${itemId}/${time}`;
  const headers = { accept: 'image/png, image/jpeg', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `GET /API/poster/${resourceId}/${itemId}/${time}/export`
 *
 * @function thumbnail.exportPoster
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @param {string} obj.time
 * @returns {Promise<Object>}
 */
function exportPoster({
  itemId = isRequired(),
  resourceId = isRequired(),
  time = isRequired(),
  ...props
}) {
  const path = `/API/poster/${resourceId}/${itemId}/${time}/export`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/thumbnail.html APIdoc}
 *
 * `DELETE /API/poster/${resourceId}/${itemId}/${time}`
 *
 * @function thumbnail.removePoster
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.resourceId
 * @param {string} obj.time
 * @returns {Promise<Object>}
 */
function removePoster({
  itemId = isRequired(),
  resourceId = isRequired(),
  time = isRequired(),
  ...props
}) {
  const path = `/API/poster/${resourceId}/${itemId}/${time}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  createThumbnail,
  listThumbnailResource,
  createThumbnailResource,
  listThumbnail,
  removeAllThumbnail,
  updateThumbnail,
  getThumbnail,
  exportThumbnail,
  removeThumbnail,
  listPosterResource,
  createPosterResource,
  listPoster,
  removeAllPoster,
  updatePoster,
  getPoster,
  exportPoster,
  removePoster,
};
