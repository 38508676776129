import { createMuiTheme } from '@material-ui/core/styles';

import MuiAppBar from './theme/MuiAppBar';
import MuiBreadcrumbs from './theme/MuiBreadcrumbs';
import MuiButton from './theme/MuiButton';
import MuiDialog from './theme/MuiDialog';
import MuiDialogActions from './theme/MuiDialogActions';
import MuiDialogTitle from './theme/MuiDialogTitle';
import MuiDialogContent from './theme/MuiDialogContent';
import MuiList from './theme/MuiList';
import MuiMenu from './theme/MuiMenu';
import MuiPaper from './theme/MuiPaper';
import MuiTableCell from './theme/MuiTableCell';
import MuiTablePagination from './theme/MuiTablePagination';
import MuiTableRow from './theme/MuiTableRow';
import VdtQueryEntry from './theme/VdtQueryEntry';
import 'typeface-exo-2';
import VdtBinContentDroppable from './theme/VdtBinContentDroppable';

const primaryDark = '#7affd5';

const typography = {
  useNextVariants: true,
  htmlFontSize: 18,
  fontFamily: '"Lato", sans-serif',
  subtitle2: {
    fontWeight: 600,
  },
};

const overrides = {
  MuiAppBar,
  MuiBreadcrumbs,
  MuiButton,
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiList,
  MuiMenu,
  MuiPaper,
  MuiTableCell,
  MuiTablePagination,
  MuiTableRow,
  VdtQueryEntry,
  VdtBinContentDroppable,
};

export const dark = createMuiTheme({
  typography,
  overrides,
  palette: {
    type: 'dark',
    primary: {
      main: primaryDark,
    },
    secondary: {
      main: primaryDark,
    },
    background: {
      default: '#232a34',
      paper: '#2e3846',
    },
  },
});

export const light = dark;

export default {
  light,
  dark,
};
