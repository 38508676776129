import React from 'react';
import { Switch, Route, useHistory, useParams, Link, useRouteMatch } from 'react-router-dom';
import { GetFieldGroup } from '@vidispine/vdt-react';
import { FieldGroupDesigner } from '@vidispine/vdt-materialui';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { fieldgroup as FieldGroupApi } from '@vidispine/vdt-api';
import { SnackbarContext } from './SnackbarContext';

const queryParams = { includeValues: true, data: 'label,displayType,order' };

function MetadataFieldList({ fieldGroupDocument = {} }) {
  const { group: groupList = [] } = fieldGroupDocument;
  const { path } = useRouteMatch();
  return (
    <List>
      {groupList.map(({ name }) => (
        <ListItem key={name} button component={Link} to={`${path}${name}`}>
          <ListItemText primary={name} />
        </ListItem>
      ))}
    </List>
  );
}

function MetadataFieldGroup({ fieldGroupDocument = {} }) {
  const { groupName } = useParams();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { setNotification } = React.useContext(SnackbarContext);
  const onChangeGroup = (newGroupName) => history.push(path.replace(':groupName', newGroupName));
  const { group: groupList = [] } = fieldGroupDocument;
  return (
    <GetFieldGroup groupName={groupName} queryParams={queryParams}>
      <FieldGroupDesigner
        setNotification={setNotification}
        onChangeGroup={onChangeGroup}
        groups={groupList}
        useParams={useParams}
      />
    </GetFieldGroup>
  );
}

export default function MetadataDesigner() {
  const [fieldGroupDocument, setFieldGroupDocument] = React.useState({});
  React.useEffect(() => {
    FieldGroupApi.listFieldGroup().then(({ data }) => setFieldGroupDocument(data));
  }, []);
  return (
    <Switch>
      <Route exact path="/settings/metadata/field-group/">
        <MetadataFieldList fieldGroupDocument={fieldGroupDocument} />
      </Route>
      <Route exact path="/settings/metadata/field-group/:groupName">
        <MetadataFieldGroup fieldGroupDocument={fieldGroupDocument} />
      </Route>
    </Switch>
  );
}
