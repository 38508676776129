import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function HeaderTitle() {
  return (
    <Grid
      container
      alignItems="center"
      style={{
        backgroundColor: '#141820',
        height: '68px',
        width: '356px',
      }}
    >
      <img
        alt="qibb logo"
        src="/QVEST-Logo-2021-WHITE.svg"
        style={{
          width: '5.625rem',
          height: '4.3125rem',
          marginLeft: '16px',
        }}
      />
      <Typography
        style={{
          fontSize: '10px',
          color: 'white',
          display: 'inine-block',
          marginTop: '10px',
          marginLeft: '1em',
        }}
      >
        Cloud Archive based on Vidispine
      </Typography>
    </Grid>
  );
}
