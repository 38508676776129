import React from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import { EntityTable, MimeTypeIcon } from '@vidispine/vdt-materialui';

const CreatedComponent = ({ value }) => (value ? moment(value).format('ll') : '');
const IconComponent = () => (
  <Avatar variant="square">
    <FolderIcon />
  </Avatar>
);

const ThumbnailComponent = ({ innerProps = {} }) => {
  const { itemType = {}, itemId } = innerProps;
  const { thumbnails = {} } = itemType;
  const { uri: srcList = [] } = thumbnails;
  const imgIdx = Math.floor(srcList.length / 2);
  const imgUrl = srcList[imgIdx];
  return (
    <Link to={`/item/${itemId}`}>
      {imgUrl ? (
        <img src={imgUrl} width={80} alt="" />
      ) : (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.1)',
            width: 80,
            height: 45,
          }}
        />
      )}
    </Link>
  );
};

const MimeTypeComponent = ({ value: mimeType }) => (
  <Avatar variant="square">
    <MimeTypeIcon mimeType={mimeType} />
  </Avatar>
);

export default function WorkspaceEntityTable({ entries = [], ...props }) {
  const { t } = useTranslation();
  const history = useHistory();
  const handleCollectionRowClick = (collectionType) => {
    const { id } = collectionType;
    history.push(`/collection/${id}`);
  };
  return (
    <EntityTable
      entries={entries}
      onCollectionClick={handleCollectionRowClick}
      optionLabels={[
        {
          label: '',
          key: 'icon',
        },
        {
          label: '',
          key: 'thumbnail',
        },
        {
          label: t('title'),
        },
        {
          label: t('created'),
        },
      ]}
      collectionOptions={[
        {
          valueComponent: IconComponent,
          key: 'icon',
        },
        {
          key: 'thumbnail',
        },
        {
          value: 'title',
        },
        {
          value: 'created',
          valueComponent: CreatedComponent,
          hide: 'xs',
        },
      ]}
      itemOptions={[
        {
          value: 'mimeType',
          valueComponent: MimeTypeComponent,
        },
        {
          label: '',
          valueComponent: ThumbnailComponent,
          key: 'thumbnail',
        },
        {
          value: 'title',
        },
        {
          value: 'created',
          valueComponent: CreatedComponent,
          hide: 'xs',
        },
      ]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
