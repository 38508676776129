import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { EntityGrid, MediaCard, MediaCardThumbnail } from '@vidispine/vdt-materialui';

import ItemLink from '../item/ItemLink';

const subheaderSelector = ({ created }) => (created ? moment(created).format('ll') : '');

const titleSelector = ({ originalFilename, title, itemId }) => {
  if (title) return title;
  if (originalFilename) return originalFilename;
  return itemId;
};
const ThumbnailComponent = ItemLink(MediaCardThumbnail);

export default function WorkpaceEntityGrid({ entries = [], GridItemProps = {}, ...props }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  return (
    <EntityGrid
      entries={entries}
      ItemCardProps={{
        MediaCardComponent: MediaCard,
        titleSelector,
        subheaderSelector,
        ThumbnailComponent,
        MenuComponent: null,
        ContentComponent: null,
        ActionsComponent: null,
        titleTypographyProps: { noWrap: true, style: { width: 220 } },
        subheaderTypographyProps: { noWrap: true, style: { width: 220 } },
      }}
      CollectionCardProps={{
        MediaCardComponent: MediaCard,
        subheaderSelector,
        titleTypographyProps: { noWrap: true, style: { width: 220 } },
        subheaderTypographyProps: { noWrap: true, style: { width: 220 } },
      }}
      GridContainerProps={{ justify: matches ? 'flex-start' : 'space-evenly' }}
      GridItemProps={{
        style: { paddingRight: 8, paddingBottom: 8 },
        ...GridItemProps,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
