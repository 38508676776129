/* eslint react/prop-types: "off" */
import React from 'react';
import { fieldgroup as FieldGroupApi } from '@vidispine/vdt-api';

import useApi from '../hooks/useApi';

export default function GetFieldGroup({ groupName, queryParams, children }) {
  const { request, data: metadataFieldGroupDocument, isLoading, isError } = useApi(
    FieldGroupApi.getFieldGroup,
  );
  const onRefresh = () => request({ groupName, queryParams });
  React.useEffect(() => {
    onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName, queryParams]);
  const cloneProps = {
    groupName,
    queryParams,
    metadataFieldGroupDocument,
    onRefresh,
    isLoading,
    isError,
  };
  const cloneChild = (child) => React.cloneElement(child, cloneProps);
  return React.Children.map(children, cloneChild);
}
