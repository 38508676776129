import React from 'react';
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  TextField,
  Button,
} from '@material-ui/core';

import { exportlocation as ExportLocationApi } from '@vidispine/vdt-api';

import { withDialog } from '../DialogContext';

export const DIALOG_ID = 'EDIT_EXPORTLOCATION_DIALOG';

function EditExportLocationDialog({ onClose, exportLocationType = {}, onSuccess, onError }) {
  const { name: locationName } = exportLocationType;
  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      uri: { value: uri },
      script: { value: script },
    } = event.target;
    const exportLocationDocument = { uri, script, name: locationName };
    try {
      ExportLocationApi.updateExportLocation({
        locationName,
        exportLocationDocument,
      })
        .then(() => {
          if (onSuccess) onSuccess(exportLocationDocument);
          onClose();
        })
        .catch((error) => {
          if (onError) onError(error);
        });
    } catch (error) {
      if (onError) onError(error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>{`Edit Export Location ${locationName}`}</DialogTitle>
      <DialogContent>
        <FormControl autoComplete="off" fullWidth>
          <TextField
            placeholder="ftp://user:pass@example.com/example/"
            name="uri"
            label="URI"
            defaultValue={exportLocationType.uri}
          />
        </FormControl>
        <FormControl autoComplete="off" fullWidth>
          <TextField
            name="script"
            label="Script"
            multiline
            defaultValue={exportLocationType.script}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default withDialog(DIALOG_ID, EditExportLocationDialog);
