const MuiButton = {
  // .MuiButton-root	Styles applied to the root element.
  root: {
    borderRadius: '4px',
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: '700',
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  // .MuiButton-label	Styles applied to the span element that wraps the children.
  label: {},
  // .MuiButton-text	Styles applied to the root element if variant="text".
  text: {},
  // .MuiButton-textPrimary	Styles applied to the root element if variant="text" and color="primary".
  textPrimary: {},
  // .MuiButton-textSecondary	Styles applied to the root element if variant="text" and color="secondary".
  textSecondary: {},
  // .MuiButton-outlined	Styles applied to the root element if variant="outlined".
  outlined: {
    color: '#fff',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    backgroundColor: '#333d4c',
    '&:hover': {
      backgroundColor: 'rgba(41, 49, 61, 0.6)',
    },
  },
  // .MuiButton-outlinedPrimary	Styles applied to the root element if variant="outlined" and color="primary".
  outlinedPrimary: {},
  // .MuiButton-outlinedSecondary	Styles applied to the root element if variant="outlined" and color="secondary".
  outlinedSecondary: {},
  // .MuiButton-contained	Styles applied to the root element if variant="contained".
  contained: {},
  // .MuiButton-containedPrimary	Styles applied to the root element if variant="contained" and color="primary".
  containedPrimary: {
    '&:hover': {
      backgroundColor: 'rgba(122, 255, 213, 0.93)',
    },
  },
  // .MuiButton-containedSecondary	Styles applied to the root element if variant="contained" and color="secondary".
  containedSecondary: {},
  // .MuiButton-disableElevation	Styles applied to the root element if disableElevation={true}.
  disableElevation: {},
  // .Mui-focusVisible	Pseudo-class applied to the ButtonBase root element if the button is keyboard focused.
  focusVisible: {},
  // .Mui-disabled	Pseudo-class applied to the root element if disabled={true}.
  disabled: {},
  // .MuiButton-colorInherit	Styles applied to the root element if color="inherit".
  colorInherit: {},
  // .MuiButton-textSizeSmall	Styles applied to the root element if size="small" and variant="text".
  textSizeSmall: {},
  // .MuiButton-textSizeLarge	Styles applied to the root element if size="large" and variant="text".
  textSizeLarge: {},
  // .MuiButton-outlinedSizeSmall	Styles applied to the root element if size="small" and variant="outlined".
  outlinedSizeSmall: {},
  // .MuiButton-outlinedSizeLarge	Styles applied to the root element if size="large" and variant="outlined".
  outlinedSizeLarge: {},
  // .MuiButton-containedSizeSmall	Styles applied to the root element if size="small" and variant="contained".
  containedSizeSmall: {},
  // .MuiButton-containedSizeLarge	Styles applied to the root element if size="large" and variant="contained".
  containedSizeLarge: {},
  // .MuiButton-sizeSmall	Styles applied to the root element if size="small".
  sizeSmall: {},
  // .MuiButton-sizeLarge	Styles applied to the root element if size="large".
  sizeLarge: {},
  // .MuiButton-fullWidth	Styles applied to the root element if fullWidth={true}.
  fullWidth: {},
  // .MuiButton-startIcon	Styles applied to the startIcon element if supplied.
  startIcon: {},
  // .MuiButton-endIcon	Styles applied to the endIcon element if supplied.
  endIcon: {},
  // .MuiButton-iconSizeSmall	Styles applied to the icon element if supplied and size="small".
  iconSizeSmall: {},
  // .MuiButton-iconSizeMedium	Styles applied to the icon element if supplied and size="medium".
  iconSizeMedium: {},
  // .MuiButton-iconSizeLarge	Styles applied to the icon element if supplied and size="large".
  iconSizeLarge: {},
};

export default MuiButton;
