import { user as UserApi } from '@vidispine/vdt-api';
import React from 'react';
import useApi from './useApi';

const useListUser = (args) => {
  const { request, data: userListType = {}, isLoading } = useApi(UserApi.listUser);
  const onRefresh = () => request(args);
  React.useEffect(() => {
    onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args]);
  return {
    onRefresh,
    userListType,
    isLoading,
  };
};

export default useListUser;
