import React from 'react';
import { item as ItemApi } from '@vidispine/vdt-api';
import debounce from 'lodash.debounce';
import useDeepCompareEffect from 'use-deep-compare-effect';
import useApi from './useApi';

export default function useItemSearchAutocomplete(
  itemSearchDoc,
  queryParams,
  debounceTime,
  suggestionSettings = { maximumSuggestions: 10, accuracy: 1 },
  triggerLength = 1,
) {
  const [textValue, setTextValue] = React.useState('');
  const handleAutocompleteChange = React.useRef(debounce(setTextValue, debounceTime)).current;

  const { data: { autocomplete = [] } = {}, request } = useApi(ItemApi.searchItem);
  const { suggestion = [] } = autocomplete[0] || {};

  useDeepCompareEffect(() => {
    if (itemSearchDoc && textValue && textValue.length >= triggerLength) {
      request({
        body: {
          ...itemSearchDoc,
          suggestion: suggestionSettings,
          autocomplete: [
            {
              text: textValue,
            },
          ],
          text: [{ value: textValue }],
        },
        queryParams: { count: false, number: 0, ...queryParams },
      });
    }
  }, [request, textValue, queryParams, triggerLength]);

  return {
    suggestion,
    handleAutocompleteChange,
  };
}
