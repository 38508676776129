import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  SearchItemCollection,
  useQueryInitialState,
  useSearch,
  useUpdateQueryState,
} from '@vidispine/vdt-react';
import WorkspaceSearchList from './WorkspaceSearchList';

const defaultInitialState = {
  itemSearchDocument: {
    intervals: 'all',
  },
  queryParams: {
    field: [
      'title',
      'originalFilename',
      'created',
      'user',
      'durationSeconds',
      'mimeType',
      'itemId',
      'collectionId',
      '__parent_collection',
      '__collection',
    ],
    content: ['metadata', 'thumbnail'],
    'noauth-url': true,
  },
};

export default function Workspace() {
  const history = useHistory();
  const { queryInitialState } = useQueryInitialState({ history });
  const initialState = queryInitialState || defaultInitialState;
  const {
    state,
    setSearchText,
    setSearchFilter,
    setParentCollection,
    setFacet,
    onChangeRowsPerPage,
    onChangePage,
    onChangeSort,
  } = useSearch(initialState);
  const { matrixParams, queryParams, itemSearchDocument, page, rowsPerPage } = state;
  useUpdateQueryState({ history, state });
  const initialSearchText = React.useMemo(
    () => initialState.itemSearchDocument.text && initialState.itemSearchDocument.text[0].value,
    [initialState.itemSearchDocument.text],
  );
  const { parentCollection } = state;
  return (
    <SearchItemCollection
      itemSearchDocument={itemSearchDocument}
      matrixParams={matrixParams}
      queryParams={queryParams}
    >
      <WorkspaceSearchList
        setSearchText={setSearchText}
        setSearchFilter={setSearchFilter}
        setWorkspace={setParentCollection}
        setFacet={setFacet}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangeSort={onChangeSort}
        page={page}
        rowsPerPage={rowsPerPage}
        parentCollectionId={parentCollection}
        initialSearchText={initialSearchText}
        initialSearchFilter={initialState.itemSearchDocument.filter}
        initialSort={initialState.itemSearchDocument.sort}
        initialWorkspaceId={initialState.parentCollection}
      />
    </SearchItemCollection>
  );
}
