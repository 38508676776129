import React from 'react';
import { GetJobs, useJobSearch } from '@vidispine/vdt-react';
import { JobList as VdtJobList, JobCard } from '@vidispine/vdt-materialui';
import { makeStyles } from '@material-ui/core/styles';

import { Drawer } from '@material-ui/core';

const useStyles = makeStyles({
  drawerPaper: {
    minWidth: '30%',
  },
});

export default function JobList({
  defaultQueryParams = { user: false },
  defaultMatrixParams = { first: 1, number: 10, sort: 'jobId desc' },
}) {
  const classes = useStyles();
  const [selectedJobType, setSelectedJobType] = React.useState(null);
  const onJobClick = (job) => setSelectedJobType(job);
  const {
    state: { page, rowsPerPage, jobMetadata, queryParams, matrixParams },
    onChangePage,
    onChangeSort,
    onChangeType,
    onChangeState,
    onChangePriority,
    onChangeStarttime,
    onChangeFinishtime,
    onChangeRowsPerPage,
    onChangeJobmetadata,
  } = useJobSearch({ queryParams: defaultQueryParams, matrixParams: defaultMatrixParams });
  return (
    <>
      <GetJobs matrixParams={matrixParams} queryParams={queryParams}>
        <VdtJobList
          page={page}
          rowsPerPage={rowsPerPage}
          jobMetadata={jobMetadata}
          onChangePage={onChangePage}
          onChangeSort={onChangeSort}
          onChangeType={onChangeType}
          onChangeState={onChangeState}
          onChangePriority={onChangePriority}
          onChangeStarttime={onChangeStarttime}
          onChangeFinishtime={onChangeFinishtime}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangeJobmetadata={onChangeJobmetadata}
          JobTableProps={{
            JobRowProps: {
              onClick: onJobClick,
            },
          }}
        />
      </GetJobs>
      <Drawer
        anchor="right"
        open={Boolean(selectedJobType)}
        onClose={() => setSelectedJobType(null)}
        classes={{ paper: classes.drawerPaper }}
      >
        <JobCard
          // eslint-disable-next-line react/jsx-props-no-spreading
          jobType={selectedJobType}
        />
      </Drawer>
    </>
  );
}
