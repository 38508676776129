import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';

// eslint-disable-next-line import/prefer-default-export
export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
}

export function b64EncodeUnicode(str) {
  return compressToEncodedURIComponent(str);
}

export function b64DecodeUnicode(str) {
  // fallback for old base64 encoded links
  return str.slice(-1) === '='
    ? decodeURIComponent(
        atob(str)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      )
    : decompressFromEncodedURIComponent(str);
}
