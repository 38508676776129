/* eslint-disable import/prefer-default-export */
const parseValueList = (value) => {
  if (value === undefined) return [];
  if (value === null) return [{ value: '' }];
  if (Array.isArray(value)) {
    if (value.length > 0 && value[0].value) return value;
    return value.map((thisValue) => ({ value: thisValue }));
  }
  if (value.value) return [value];
  return [{ value }];
};

const parseValue = (fieldValue = {}) => {
  if (fieldValue && (fieldValue.value || fieldValue.name)) {
    const { value: valueList, uuid } = fieldValue;
    const value = parseValueList(valueList);
    return { value, uuid };
  }
  return { value: parseValueList(fieldValue) };
};

const parseField = (field = {}) => {
  const { name, value } = field;
  const parsedValue = parseValue(value);
  return { name, ...parsedValue };
};

const shouldBeField = ({ name, value }) =>
  ['string', 'boolean', 'number'].includes(typeof value) ||
  (name !== 'group' && Array.isArray(value)) ||
  value === null;

const parseGroup = (thisGroup = {}) => {
  const { name: groupName, value: groupsOrFields = {} } = thisGroup;
  // eslint-disable-next-line no-use-before-define
  const { field, group } = parseGroupsOrFields(groupsOrFields);
  return {
    name: groupName,
    group,
    field,
  };
};

const parseGroupsOrFields = (groupsOrFields) => {
  const group = [];
  const field = [];
  Object.entries(groupsOrFields).forEach(([name, value]) => {
    if (shouldBeField({ name, value })) {
      field.push(parseField({ name, value }));
    } else if (Array.isArray(value)) {
      value.forEach(({ groupName, ...groupValue }) => {
        // eslint-disable-next-line no-use-before-define
        group.push(parseGroup({ name: groupName, value: groupValue }));
      });
    } else {
      // eslint-disable-next-line no-use-before-define
      group.push(parseGroup({ name, value }));
    }
  });
  return {
    group,
    field,
  };
};

const parseTimeSpan = (timeSpan) => {
  const { start = '-INF', end = '+INF', ...groupsOrFields } = timeSpan;
  const { group, field } = parseGroupsOrFields(groupsOrFields);
  return {
    start,
    end,
    field,
    group,
  };
};

const createMetadataType = (metadataType = {}) => {
  const newMetadataType = {};
  if (Array.isArray(metadataType)) {
    newMetadataType.timespan = metadataType.map((thisTimeSpan) => parseTimeSpan(thisTimeSpan));
  } else {
    const genericTimespan = parseTimeSpan(metadataType);
    newMetadataType.timespan = [genericTimespan];
  }
  return newMetadataType;
};

export { createMetadataType };

export default createMetadataType;
