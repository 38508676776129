import React from 'react';

const CHANGE_PAGE = 'CHANGE_PAGE';
const CHANGE_ROWS = 'CHANGE_ROWS';
const CHANGE_SORT = 'CHANGE_SORT';
const CHANGE_TYPE = 'CHANGE_TYPE';
const CHANGE_STATE = 'CHANGE_STATE';
const CHANGE_PRIORITY = 'CHANGE_PRIORITY';
const CHANGE_STARTTIME = 'CHANGE_STARTTIME';
const CHANGE_FINISHTIME = 'CHANGE_FINISHTIME';

const ACTION_TYPE = {
  CHANGE_PAGE,
  CHANGE_ROWS,
  CHANGE_SORT,
  CHANGE_TYPE,
  CHANGE_STATE,
  CHANGE_PRIORITY,
  CHANGE_STARTTIME,
  CHANGE_FINISHTIME,
};

const setInitialDefaultState = ({ queryParams = {}, matrixParams = {} } = {}) => ({
  queryParams,
  matrixParams: {
    first: 1,
    number: 10,
    ...matrixParams,
  },
  rowsPerPage: 10,
  page: 0,
  jobMetadata: [],
});

const searchReducer = (state, action) => {
  const { type, ...rest } = action;
  switch (type) {
    case ACTION_TYPE.CHANGE_PAGE: {
      const { page = 0 } = action;
      const { rowsPerPage = 10 } = state;
      return {
        ...state,
        page,
        matrixParams: {
          ...state.matrixParams,
          first: page * rowsPerPage + 1,
        },
      };
    }
    case ACTION_TYPE.CHANGE_ROWS: {
      const { rowsPerPage } = action;
      return {
        ...state,
        rowsPerPage,
        page: 0,
        matrixParams: {
          ...state.matrixParams,
          first: 1,
          number: rowsPerPage,
        },
      };
    }
    case ACTION_TYPE.CHANGE_SORT: {
      const { field, order = 'desc' } = action;
      return {
        ...state,
        page: 0,
        matrixParams: {
          ...state.matrixParams,
          first: 1,
          sort: `${field} ${order}`,
        },
      };
    }
    case ACTION_TYPE.CHANGE_TYPE:
    case ACTION_TYPE.CHANGE_STATE:
    case ACTION_TYPE.CHANGE_PRIORITY: {
      const { field, value = [] } = rest;
      let matrixParams;
      if (!value) {
        const { [field]: deleteField, ...newMatrixParams } = state.matrixParams;
        matrixParams = { ...newMatrixParams, first: 1 };
      } else {
        matrixParams = {
          ...state.matrixParams,
          first: 1,
          [field]: Array.isArray(value) ? value.join(',') : value,
        };
      }
      return {
        ...state,
        page: 0,
        matrixParams,
      };
    }
    case ACTION_TYPE.CHANGE_STARTTIME:
    case ACTION_TYPE.CHANGE_FINISHTIME: {
      const { field, value = [] } = rest;
      let queryParams;
      if (!value) {
        const { [field]: deleteField, ...newQueryParams } = state.queryParams;
        queryParams = { ...newQueryParams, first: 1 };
      } else {
        queryParams = {
          ...state.queryParams,
          first: 1,
          [field]: value,
        };
      }
      return {
        ...state,
        page: 0,
        queryParams,
      };
    }
    case ACTION_TYPE.CHANGE_JOBMETADATA: {
      const { field, value = [] } = rest;
      let queryParams;
      if (!value.length) {
        const { [field]: deleteField, ...newQueryParams } = state.queryParams;
        queryParams = { ...newQueryParams, first: 1 };
      } else {
        queryParams = {
          ...state.queryParams,
          first: 1,
          [field]: value,
        };
      }
      return {
        ...state,
        page: 0,
        queryParams,
        jobMetadata: value,
      };
    }
    default:
      return state;
  }
};

export default function useJobSearch(initialState) {
  const initialDefaultState = setInitialDefaultState(initialState);
  const [state, dispatch] = React.useReducer(searchReducer, initialDefaultState);
  const onChangeRowsPerPage = ({ target: { value: rowsPerPage } } = {}) =>
    dispatch({
      type: ACTION_TYPE.CHANGE_ROWS,
      rowsPerPage,
    });
  const onChangePage = ({ page }) =>
    dispatch({
      type: ACTION_TYPE.CHANGE_PAGE,
      page,
    });
  const onChangeSort = (event, field, order) => {
    const fieldName = typeof field === 'object' ? field.value : field;
    dispatch({
      type: ACTION_TYPE.CHANGE_SORT,
      field: fieldName,
      order,
    });
  };
  const onChangeType = ({ field = [] }) => {
    const [{ name = 'type', value = [] }] = field;
    dispatch({
      type: ACTION_TYPE.CHANGE_TYPE,
      field: name,
      value: value.length ? value[0].value : null,
    });
  };
  const onChangeState = ({ field = [] }) => {
    const [{ name = 'state', value = [] }] = field;
    dispatch({
      type: ACTION_TYPE.CHANGE_STATE,
      field: name,
      value: value.length ? value[0].value : null,
    });
  };
  const onChangePriority = ({ field = [] }) => {
    const [{ name = 'priority', value = [] }] = field;
    dispatch({
      type: ACTION_TYPE.CHANGE_PRIORITY,
      field: name,
      value: value.length ? value[0].value : null,
    });
  };
  const onChangeStarttime = ({ field = [] }) => {
    const [{ name = 'starttime-from', value = [] }] = field;
    dispatch({
      type: ACTION_TYPE.CHANGE_STARTTIME,
      field: name,
      value: value.length ? value[0].value : null,
    });
  };
  const onChangeFinishtime = ({ field = [] }) => {
    const [{ name = 'finishtime-from', value = [] }] = field;
    dispatch({
      type: ACTION_TYPE.CHANGE_FINISHTIME,
      field: name,
      value: value.length ? value[0].value : null,
    });
  };
  const onChangeJobmetadata = (value) => {
    dispatch({
      type: ACTION_TYPE.CHANGE_JOBMETADATA,
      field: 'jobmetadata',
      value,
    });
  };
  return {
    state,
    onChangePage,
    onChangeSort,
    onChangeType,
    onChangeState,
    onChangePriority,
    onChangeStarttime,
    onChangeFinishtime,
    onChangeRowsPerPage,
    onChangeJobmetadata,
  };
}
