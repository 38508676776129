import 'core-js/es/string/replace-all';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@vidispine/vdt-materialui';
import '@vidispine/vdt-materialui/dist/index.css';
import { useTranslation } from 'react-i18next';
import { useLocalStorage, SettingsProvider } from '@vidispine/vdt-react';

import App from './App';
import Login from './login/Login';
import AppTitle from './AppTitle';
import themes from './themes';

import i18n from './i18n';
import vdtMuiLocale from './locale';
import { VIDISPINE_URL, APP_TITLE, SETTINGS_LOCAL_STORAGE_KEY } from './const';
import Authentication from './loginkeycloak/Authentication';
import ServerListRoute from './login/ServerListRoute';

function Index() {
  const { t } = useTranslation();
  document.title = APP_TITLE || t('appTitle');
  const [initialSettings, setSettingsState] = useLocalStorage(SETTINGS_LOCAL_STORAGE_KEY, {});
  const useKeycloakAuth = process.env.REACT_APP_USE_KEYCLOAK_AUTH;
  const AppContainer = ['TRUE', 'true'].includes(useKeycloakAuth)
    ? Authentication
    : ServerListRoute;
  return (
    <SettingsProvider
      settingsKey={SETTINGS_LOCAL_STORAGE_KEY}
      onChange={setSettingsState}
      initialSettings={initialSettings}
    >
      <ThemeProvider themes={themes} props={vdtMuiLocale({ t, lng: i18n.language })}>
        <AppContainer
          AppComponent={App}
          LoginComponent={Login}
          AppTitleComponent={AppTitle}
          serverUrl={VIDISPINE_URL}
        />
      </ThemeProvider>
    </SettingsProvider>
  );
}

ReactDOM.render(
  <Suspense fallback={null}>
    <Index />
  </Suspense>,
  document.getElementById('root'),
);
