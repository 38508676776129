import React from 'react';

const useLocalStorage = (localStorageKey, defaultValue) => {
  const [value, setValue] = React.useState(
    JSON.parse(window.localStorage.getItem(localStorageKey) || JSON.stringify(defaultValue)),
  );

  React.useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [localStorageKey, value]);

  const getValue = () => JSON.parse(window.localStorage.getItem(localStorageKey));

  return [value, setValue, getValue];
};

export default useLocalStorage;
