import React from 'react';
import { uuidv4 } from '../utils';

export const BinContext = React.createContext();

const defaultIsUniqueBinItem = (
  bin,
  { entityId, entityType, entityTimespan: { start, end, field: [{ name } = {}] = [] } = {} },
) => {
  const entityExistsInBin = bin.some(
    (o) => o.entity.id === entityId && o.entityType === entityType,
  );
  let entityTimespanExistsInBin = false;
  if (entityExistsInBin) {
    const binEntitiesWithSameId = bin.filter(
      (o) => o.entity.id === entityId && o.entityType === entityType,
    );
    binEntitiesWithSameId.forEach(
      ({
        entityTimespan: { start: beStart, end: beEnd, field: [{ name: beName } = {}] = [] } = {},
      }) => {
        if (start === beStart && end === beEnd && name === beName) {
          entityTimespanExistsInBin = true;
        }
      },
    );
  }
  return !entityExistsInBin || !entityTimespanExistsInBin;
};
export function BinProvider({
  onChange,
  children,
  initialState = [],
  allowDuplicates: defaultAllowDuplicates = false,
  isUniqueBinItem = defaultIsUniqueBinItem,
}) {
  const [binState, setBinState] = React.useState(initialState);
  const [allowDuplicates, setAllowDuplicates] = React.useState(defaultAllowDuplicates);

  const onDelete = (binIdToDelete) => {
    if (!binIdToDelete || !['string', 'number'].includes(typeof binIdToDelete)) {
      // eslint-disable-next-line no-console
      console.error('BinProvider onDelete called without a binId of the entity to delete');
      return;
    }
    setBinState((state) => {
      const newState = state.filter((o) => o.binId !== binIdToDelete);
      if (onChange) onChange(newState);
      return newState;
    });
  };

  const onEmptyBin = () => {
    const newState = [];
    setBinState(newState);
    if (onChange) onChange(newState);
  };

  const createBinObject = (binObject) => {
    const [binId] = uuidv4().split('-');
    return { binId, ...binObject };
  };

  const handleDrop = ({ dragInfo = {} } = {}) => {
    const { entityId, entityType, entity } = dragInfo;
    if (!entityId || !entityType || !entity) {
      throw new Error('Dragged element needs to have entityId, entityType and entity props');
    }
    setBinState((state = []) => {
      if (isUniqueBinItem(state, dragInfo) || allowDuplicates) {
        const newState = [...state, createBinObject(dragInfo)];
        if (onChange) onChange(newState);
        return newState;
      }
      return state;
    });
  };

  const handleSort = ({ dragId, hoverId, originalDragIndex }) => {
    if (dragId === undefined || (hoverId === undefined && originalDragIndex === undefined)) return;
    setBinState((state) => {
      const newState = [...state];
      const dragIndex = binState.findIndex((o) => o.binId === dragId);
      const hoverIndex = binState.findIndex((o) => o.binId === hoverId);
      const newDragIndex = hoverIndex > -1 ? hoverIndex : originalDragIndex;
      [newState[newDragIndex], newState[dragIndex]] = [newState[dragIndex], newState[newDragIndex]];
      if (onChange) onChange(newState);
      return newState;
    });
  };

  return (
    <BinContext.Provider
      value={{
        setBin: setBinState,
        bin: binState,
        allowDuplicates,
        setAllowDuplicates,
        onDrop: handleDrop,
        onSort: handleSort,
        onFind: () => null,
        onEmptyBin,
        onDelete,
      }}
    >
      {children}
    </BinContext.Provider>
  );
}
