import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CollectionCard, CollectionGrid, withDrop } from '@vidispine/vdt-materialui';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { compose } from '@vidispine/vdt-react';
import { withStyles } from '@material-ui/core';
import MediaCardHeaderDraggable from './MediaCardHeaderDraggable';

const subheaderSelector = ({ created }) => (created ? moment(created).format('ll') : '');

const collectionCardStyles = (theme) => ({
  canDrop: {
    border: `1px solid ${theme.palette.primary.dark}`,
    '&.VdtDroppable-isOver': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    },
  },
});
const CollectionCardDroppable = compose(
  withStyles(collectionCardStyles, { name: 'CollectionCardDroppable' }),
  withDrop,
)(CollectionCard);

export default function CustomCollectionGrid({ collectionListType, onDrop }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const history = useHistory();
  const handleItemClick = (collectionType) => {
    const { id } = collectionType;
    history.push(`/collection/${id}`);
  };
  return (
    <CollectionGrid
      CollectionCardComponent={CollectionCardDroppable}
      collectionListType={collectionListType}
      onItemClick={handleItemClick}
      CollectionCardProps={{
        HeaderComponent: MediaCardHeaderDraggable,
        subheaderSelector,
        onDrop,
        titleTypographyProps: { noWrap: true, style: { width: 220 } },
        subheaderTypographyProps: { noWrap: true, style: { width: 220 } },
      }}
      GridContainerProps={{ justify: matches ? 'flex-start' : 'space-evenly' }}
      GridItemProps={{ style: { paddingRight: 8, paddingBottom: 8 } }}
    />
  );
}
