const MuiBreadcrumbs = {
  root: {},
  ol: {
    marginTop: '22px',
    marginLeft: '12px',
  },
  li: {
    margin: '0 0 0 12px',
    '& a': {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '1.5',
      color: '#ffffff',
      cursor: 'pointer',
      textDecoration: 'none !important',
    },
    '& img': {
      height: '16px',
      top: '1px',
      position: 'relative',
    },
    '& p': {
      color: 'orange',
    },
  },
  separator: {
    color: '#666e7d',
    marginLeft: '12px',
    marginRight: 0,
  },
};

export default MuiBreadcrumbs;
