import React from 'react';
import { Link } from 'react-router-dom';
import { MediaCardAvatar, MediaBadges } from '@vidispine/vdt-materialui';
import { parseMetadataType, roles as ROLES } from '@vidispine/vdt-js';
import { HasRole } from '@vidispine/vdt-react';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import TransformIcon from '@material-ui/icons/Transform';
import { useTranslation } from 'react-i18next';
import FolderIcon from '@material-ui/icons/Folder';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';

import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuVert from '../layout/MenuVert';
import DialogContext from '../DialogContext';
import { DIALOG_ID as EXPORT_DIALOG_ID } from '../export/ExportDialog';
import { DIALOG_ID as ADD_TO_COLLECTION_DIALOG_ID } from '../collection/AddToCollectionDialog';
import { DIALOG_ID as ITEM_TRANSCODE_DIALOG } from './ItemTranscodeDialog';
import { DIALOG_ID as ITEM_DELETE_DIALOG } from './ItemDeleteDialog';

function ItemMenu() {
  const { onOpen: onOpenDialog } = React.useContext(DialogContext);
  return (
    <MenuVert>
      <MenuItem onClick={onOpenDialog(ITEM_TRANSCODE_DIALOG)}>
        <ListItemIcon>
          <TransformIcon />
        </ListItemIcon>
        <ListItemText primary="Transcode" />
      </MenuItem>
      <MenuItem onClick={onOpenDialog(EXPORT_DIALOG_ID)}>
        <ListItemIcon>
          <SendIcon />
        </ListItemIcon>
        <ListItemText primary="Export" />
      </MenuItem>
      <MenuItem onClick={onOpenDialog(ADD_TO_COLLECTION_DIALOG_ID)}>
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary="Add to collection" />
      </MenuItem>
      <Divider />
      <MenuItem onClick={onOpenDialog(ITEM_DELETE_DIALOG)}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText primary="Delete" primaryTypographyProps={{ color: 'secondary' }} />
      </MenuItem>
    </MenuVert>
  );
}

export default function ItemHeader({ itemType = {}, isLoading, onClose, linkTo }) {
  const { t } = useTranslation();
  const { metadata: metadataType, shape: shapeTypeList = [] } = itemType;
  const metadata = React.useMemo(
    () => parseMetadataType(metadataType, { joinValue: ',', flat: true }),
    [metadataType],
  );
  const [shapeType] = shapeTypeList;
  const { title, mimeType, created, user, originalFilename, mediaType } = metadata;
  return (
    <Card square elevation={0} style={{ backgroundColor: 'inherit' }}>
      <CardHeader
        title={
          isLoading ? (
            <Skeleton variant="rect" width="50%" height={28} />
          ) : (
            title || originalFilename
          )
        }
        avatar={
          isLoading ? (
            <Skeleton variant="rect" width={40} height={40} />
          ) : (
            <MediaCardAvatar avatar={mimeType} />
          )
        }
        titleTypographyProps={{ variant: 'h5' }}
        action={
          <>
            {linkTo && (
              <IconButton component={Link} to={linkTo} disableRipple>
                <LaunchIcon />
              </IconButton>
            )}
            <HasRole roleName={[ROLES.ITEM_WRITE]} allow={<ItemMenu mediaType={mediaType} />} />
            {onClose && (
              <IconButton component={ButtonBase} onClick={onClose} disableRipple>
                <CloseIcon />
              </IconButton>
            )}
          </>
        }
      />
      <CardContent>
        {isLoading ? (
          <Skeleton variant="rect" width="10%" height={18} />
        ) : (
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rect" width="10%" height={18} />
              ) : (
                <Grid container alignItems="center">
                  <Typography variant="body2">
                    {t('createdBy')}
                    &nbsp;
                  </Typography>
                  <Typography variant="subtitle2">{user}</Typography>
                  <Typography variant="body2">&nbsp;on&nbsp;</Typography>
                  <Typography variant="subtitle2">
                    {created ? moment(created).format('ll') : ''}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rect" width="10%" height={18} />
              ) : (
                <Grid container alignItems="center">
                  <MediaBadges shapeType={shapeType} />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
