/* eslint react/jsx-props-no-spreading: "off" */
import React from 'react';
import { Link } from 'react-router-dom';

const ItemLink = (Component) => (props) => {
  const { innerProps = {} } = props;
  const { itemId } = innerProps;
  const to = `/archive/${itemId}`;
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Component {...props} />
    </Link>
  );
};

export default ItemLink;
