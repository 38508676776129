import React from 'react';
import { NavLink, useLocation, Link as RouterLink } from 'react-router-dom';
import { UserAvatarButton, useThemeContext, useSnackbarAlert } from '@vidispine/vdt-materialui';
import { useSettingsActions, HasRole, useRoles } from '@vidispine/vdt-react';
import Grid from '@material-ui/core/Grid';
import MUIButton from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { roles as ROLES } from '@vidispine/vdt-js';
import { user as UserApi, user as userApi } from '@vidispine/vdt-api';
import AppTitle from '../AppTitle';
import i18n from '../i18n';

import EditUserDialog, { DIALOG_ID as EDIT_USER_DIALOG_ID } from '../user/EditUserDialog';

import { useDialogContext } from '../DialogContext';

import ChangePasswordDialog, { DIALOG_ID as CHANGE_PASSWORD_DIALOG } from './ChangePasswordDialog';

const headerButtonLinkStyles = (theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'capitalize',
    color: '#fff',
    lineHeight: '1em',
    padding: '27px',
    position: 'relative',
    margin: '0 4px',
    '&:hover': {
      backgroundColor: '#141820 !important',
      transition: 'none',
      '&::after': { borderTop: 'solid 2px #fff' },
    },
    '&::after': {
      color: 'transparent',
      borderTop: 'none',
      right: 0,
      position: 'absolute',
      bottom: 0,
      left: 0,
      content: '""',
    },
    '&.selected': {
      '&::after': { borderTop: `solid 3px ${theme.palette.primary.main}` },
    },
  },
});

const Button = withStyles(headerButtonLinkStyles, { name: 'VdtHeaderButtonLink' })(MUIButton);

export default function Header({ userName, serverUrl, onLogout, onLogin }) {
  const [showAlert] = useSnackbarAlert();
  const { t } = useTranslation();
  const {
    // togglePalette,
    isDefaultPalette,
  } = useThemeContext();
  const { updateSetting } = useSettingsActions();

  const { onOpen: onOpenDialog, onClose: onCloseDialog } = useDialogContext();

  const onToggleLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    updateSetting({ language: newLanguage });
  };
  // const onTogglePalette = (e, newTheme) => {
  //   togglePalette();
  //   updateSetting({ theme: newTheme ? 'light' : 'dark' });
  // };

  const location = useLocation();
  const paths = location.pathname.split('/');
  const locationsWithBreadcrumb = ['archive', 'housekeeping', 'collection', 'import'];

  const { hasRole } = useRoles();
  const isAdmin = hasRole(ROLES.ADMINISTRATOR);

  const [userType, setUserType] = React.useState(null);

  React.useEffect(() => {
    if (userName && userType === null) {
      userApi.getUser({ userName }).then(({ data: newUserType }) => setUserType(newUserType));
    }
  }, [userName, setUserType, userType]);

  const handleEditUser = ({ userDocument }) => {
    const metaData = { userName, simpleMetadataDocument: userDocument.metadata };
    const userDataWithMetadata = { userName, userDocument };

    if (isAdmin) {
      UserApi.updateUser(userDataWithMetadata)
        .then(() => {
          showAlert({ severity: 'success', message: 'User data updated' });
          userApi.getUser({ userName }).then(({ data: newUserType }) => setUserType(newUserType));
          onCloseDialog();
        })
        .catch(() => {
          showAlert({ severity: 'error', message: 'Failed to update user metadata' });
        });
    } else {
      UserApi.updateUserMetadata(metaData)
        .then(() => {
          showAlert({ severity: 'success', message: 'User metadata updated' });
          userApi.getUser({ userName }).then(({ data: newUserType }) => setUserType(newUserType));
          onCloseDialog();
        })
        .catch(() => {
          showAlert({ severity: 'error', message: 'Failed to update user metadata' });
        });
    }
  };

  return (
    <AppBar position="relative">
      <Grid container spacing={0}>
        <Grid item xs="auto">
          <AppTitle />
        </Grid>
        <Grid item xs>
          {locationsWithBreadcrumb.includes(paths[1]) && (
            <Breadcrumbs separator="•" aria-label="breadcrumb">
              <Link component={RouterLink} to="/">
                <img src="/icons/home.svg" alt="Home" />
              </Link>
              {paths[1] === 'archive' && (
                <Link component={RouterLink} to="/archive/">
                  Assets
                </Link>
              )}
              {paths[1] === 'collection' && (
                <Link component={RouterLink} to="/collection/">
                  Collections
                </Link>
              )}
              {paths[1] === 'import' && (
                <Link component={RouterLink} to="/import/">
                  Upload
                </Link>
              )}
              {paths[1] === 'housekeeping' && (
                <Link component={RouterLink} to="/housekeeping/">
                  Housekeeping
                </Link>
              )}
              {paths[2]?.length && (
                <Link component={RouterLink} to={`/${paths[1]}/${paths[2]}`}>
                  Detail View
                </Link>
              )}
            </Breadcrumbs>
          )}
        </Grid>
        <Grid item xs="auto">
          <Button
            activeClassName="selected"
            variant="text"
            component={NavLink}
            to="/archive/"
            color="inherit"
            disableRipple
          >
            Assets
          </Button>
          <Button
            activeClassName="selected"
            variant="text"
            component={NavLink}
            to="/collection/"
            color="inherit"
            disableRipple
          >
            {t('collections')}
          </Button>
          <HasRole
            roleName={[ROLES.IMPORT_WRITE]}
            allow={
              <Button
                activeClassName="selected"
                variant="text"
                component={NavLink}
                to="/import/"
                color="inherit"
                disableRipple
              >
                {t('upload')}
              </Button>
            }
          />
          <HasRole
            roleName={[ROLES.ADMINISTRATOR]}
            allow={
              <Button
                activeClassName="selected"
                variant="text"
                component={NavLink}
                to="/housekeeping/"
                color="inherit"
                disableRipple
              >
                {t('housekeeping')}
              </Button>
            }
          />
        </Grid>
        <Grid
          item
          xs="auto"
          style={{
            margin: '14px',
          }}
        >
          <UserAvatarButton
            userName={userName}
            onLogout={onLogout}
            // togglePalette={onTogglePalette}
            isDefaultPalette={isDefaultPalette}
            locale={{
              set: (lang) => onToggleLanguage(lang),
              curr: i18n.language || '',
              options: [
                { label: 'Svenska', value: 'sv' },
                { label: 'English', value: 'en' },
                { label: 'Deutsch', value: 'de' },
              ],
            }}
          >
            {isAdmin ? (
              <MenuItem button component={NavLink} to="/settings">
                {t('settings')}
              </MenuItem>
            ) : (
              <div />
            )}
            {userType && (
              <MenuItem button onClick={onOpenDialog(EDIT_USER_DIALOG_ID)}>
                Edit account
              </MenuItem>
            )}
            <MenuItem button onClick={onOpenDialog(CHANGE_PASSWORD_DIALOG)}>
              Change password
            </MenuItem>
          </UserAvatarButton>
        </Grid>
      </Grid>
      <ChangePasswordDialog
        username={userName}
        onSuccess={(args) => onLogin({ serverUrl, ...args })}
        DialogProps={{ fullWidth: true, maxWidth: 'sm' }}
      />
      {userType && (
        <EditUserDialog
          onSubmit={handleEditUser}
          user={userType}
          DialogProps={{
            fullWidth: true,
          }}
          isUserNameDisabled={!isAdmin}
          isRealNameDisabled={!isAdmin}
          dialogTitle="Edit account"
        />
      )}
    </AppBar>
  );
}
