import React from 'react';
import {
  vsimport as ImportApi,
  ChunkedUpload,
  collection as CollectionApi,
} from '@vidispine/vdt-api';
import { createMetadataType } from '@vidispine/vdt-js';
import { uuidv4 } from '../utils';

const DEFAULT_AUDIO_SHAPETAGS = ['__mp3_160k'];
const DEFAULT_VIDEO_SHAPETAGS = ['__mp4'];
const DEFAULT_IMAGE_SHAPETAGS = ['__png'];
const AUDIO_MIMETYPE_PREFIX = 'audio';
const VIDEO_MIMETYPE_PREFIX = 'video';
const IMAGE_MIMETYPE_PREFIX = 'image';
const ADDITIONAL_VIDEO_MIMETYPES = ['application/mxf'];
const ADDITIONAL_IMAGE_MIMETYPES = [];
const ADDITIONAL_AUDIO_MIMETYPES = [];

const getDefaultShapeTags = (file) => {
  if (!file || !file.type) return [];
  const mimeType = file.type.toLowerCase();
  if (mimeType.startsWith(AUDIO_MIMETYPE_PREFIX) || ADDITIONAL_AUDIO_MIMETYPES.includes(mimeType))
    return DEFAULT_AUDIO_SHAPETAGS;
  if (mimeType.startsWith(VIDEO_MIMETYPE_PREFIX) || ADDITIONAL_VIDEO_MIMETYPES.includes(mimeType))
    return DEFAULT_VIDEO_SHAPETAGS;
  if (mimeType.startsWith(IMAGE_MIMETYPE_PREFIX) || ADDITIONAL_IMAGE_MIMETYPES.includes(mimeType))
    return DEFAULT_IMAGE_SHAPETAGS;
  return [];
};

const chunkedUpload = (file, props = {}) =>
  new Promise((resolve, reject) => {
    const uploadInstance = new ChunkedUpload(file, {
      onFail: (error) => reject(error),
      onComplete: (resp) => resolve(resp),
      ...props,
    });
    uploadInstance.upload();
  });

const onUploadFile = ({
  file,
  collectionId,
  onProgress,
  metadata: propsMetadata = {},
  placeholderParams = {},
  importParams = {},
  chunkedUploadProps = {},
  generateTransferId = uuidv4,
  getShapeTags = getDefaultShapeTags,
}) =>
  new Promise((resolve, reject) => {
    try {
      let itemId;
      onProgress({});
      const shapeTags = getShapeTags(file);
      const filename = file.name;
      const mimeType = file.type;
      const [mediaType] = mimeType.split('/');
      const metadata = {
        // originalFilename: filename,
        // mimeType,
        // mediaType,
        ...propsMetadata,
      };
      const metadataDocument = createMetadataType(metadata);
      ImportApi.createImportPlaceholder({
        metadataDocument,
        queryParams: {
          container: 1,
          ...placeholderParams,
        },
      })
        .then(({ data: itemDocument }) => {
          itemId = itemDocument.id;
          if (!collectionId) return Promise.resolve();
          const collectionIds = Array.isArray(collectionId) ? collectionId : [collectionId];
          return Promise.all(
            collectionIds.map((id) =>
              CollectionApi.addCollectionEntity({
                collectionId: id,
                entityId: itemId,
              }),
            ),
          );
        })
        .then(() => {
          const transferId = generateTransferId();
          const queryParams = {
            filename,
            transferId,
            tag: shapeTags,
            ...importParams,
          };
          return chunkedUpload(file, {
            api: {
              method: ImportApi.createImportComponentRaw,
              props: {
                itemId,
                component: 'container',
                queryParams,
              },
            },
            onProgress,
            ...chunkedUploadProps,
          });
        })
        .then((response) => {
          resolve({ ...response, itemId, collectionId });
        })
        .catch((error) => reject(error));
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
      reject(err);
    }
  });

function useUploadFiles({
  onUploadFile: handleUploadFile = onUploadFile,
  initialMetadata = {},
  ...props
}) {
  const [files, setFiles] = React.useState([]);
  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );
  const onAddFiles = React.useCallback(
    (newFiles = []) => {
      const newFilesObj = newFiles.map((file) => ({
        file,
        metadata: { title: file.name, ...initialMetadata },
      }));
      setFiles((currentFiles) => newFilesObj.concat(currentFiles));
    },
    [initialMetadata],
  );
  const onChangeFile = React.useCallback(
    (idx, changeFile = {}) => {
      setFiles((currentFilesList) => {
        const currentFile = currentFilesList[idx];
        const { metadata: newMetadata, progress: newProgress } = changeFile;
        const { metadata: currentMetadata = {}, progress: currentProgress = {} } = currentFile;
        const newFilesList = [...currentFilesList];
        const newFile = { ...currentFile };
        if (newMetadata) newFile.metadata = { ...currentMetadata, ...newMetadata };
        if (newProgress) newFile.progress = { ...currentProgress, ...newProgress };
        newFilesList[idx] = newFile;
        return newFilesList;
      });
    },
    [setFiles],
  );
  const onChangeProgress = React.useCallback(
    (idx) => (newProgress = {}) => {
      onChangeFile(idx, { progress: newProgress });
    },
    [onChangeFile],
  );
  const onChangeMetadata = React.useCallback(
    (idx) => (newMetadata = {}) => {
      onChangeFile(idx, { metadata: newMetadata });
    },
    [onChangeFile],
  );
  const onUpload = React.useCallback(
    (uploadProps) =>
      files.reduce(
        (previousPromise, thisFile, idx) =>
          previousPromise.then((res) => {
            if (thisFile.progress) {
              return previousPromise;
            }
            return handleUploadFile({
              file: thisFile.file,
              onProgress: onChangeProgress(idx),
              metadata: thisFile.metadata,
              ...uploadProps,
              ...props,
            }).then((currentResult) => [...res, currentResult]);
          }),
        Promise.resolve([]),
      ),
    [files, handleUploadFile, onChangeProgress, props],
  );

  const onRemoveFile = React.useCallback(
    (idx) => () => {
      setFiles([...files].filter((_, i) => i !== idx));
    },
    [setFiles, files],
  );

  const onRemoveFiles = React.useCallback(
    (idxs) => () => {
      setFiles([...files].filter((_, i) => !idxs.includes(i)));
    },
    [setFiles, files],
  );

  return {
    onAddFiles,
    onChangeFile,
    onChangeMetadata,
    onUpload,
    onRemoveFile,
    onRemoveFiles,
    files,
    setFiles,
  };
}

export default useUploadFiles;
