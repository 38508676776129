import React from 'react';
import Box from '@material-ui/core/Box';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  JobFooterContextProvider,
  DragAndDropProvider,
  useThemeContext,
} from '@vidispine/vdt-materialui';
import { RolesProvider, BinProvider, ComposeProviders, useSettings } from '@vidispine/vdt-react';
import debounce from 'lodash.debounce';

import { user as userApi } from '@vidispine/vdt-api';
import i18n from './i18n';
import Header from './layout/Header';
import SearchItem from './search/SearchItem';
import SearchItemPrivileged from './search/SearchItemPrivileged';
import SearchCollectionList from './search/SearchCollectionList';
import Item from './item/Item';
import ItemPrivileged from './item/ItemPrivileged';
import Collection from './collection/Collection';
import Workspace from './workspaces/Workspace';
import ItemUpload from './ItemUpload';
import { ResourceProvider } from './ResourceContext';
import { DialogProvider } from './DialogContext';
import { SnackbarProvider } from './SnackbarContext';
import { UploadProvider } from './UploadContext';
import Settings from './Settings';
import { BIN_LOCAL_STORAGE_KEY } from './const';
import { IdleTimer } from './IdleTimer';

const onChangeBin = debounce((newValue) => {
  window.localStorage.setItem(BIN_LOCAL_STORAGE_KEY, JSON.stringify(newValue));
}, 500);

const initialBin = JSON.parse(
  window.localStorage.getItem(BIN_LOCAL_STORAGE_KEY) || JSON.stringify([]),
);

function App({ onLogout, serverUrl, onLogin, tokenLifetime }) {
  const { settings, updateUsername } = useSettings();
  const { language, theme } = settings;
  const { togglePalette, palette } = useThemeContext();
  const [userName, setUserName] = React.useState('');

  React.useEffect(() => {
    userApi
      .whoAmI()
      .then(({ data: newUserName }) => {
        setUserName(newUserName);
      })
      .catch(() => {
        setUserName('');
      });
  }, [setUserName, userName, updateUsername]);
  React.useEffect(() => {
    if (language && language !== i18n.language) i18n.changeLanguage(language);
    if (theme && theme !== palette) togglePalette();
  }, [language, theme, togglePalette, palette]);

  const logOutUser = React.useCallback(() => {
    onLogout();
    window.history.replaceState(null, '', '/');
  }, [onLogout]);

  React.useEffect(() => {
    const timer = new IdleTimer({
      handleExpire: logOutUser,
      handlePulse: userApi.whoAmI,
      handleTimeout: logOutUser,
      lifetime: tokenLifetime,
    });

    return () => timer.destroy();
  }, [logOutUser, tokenLifetime]);

  return (
    <ComposeProviders
      providers={[
        DialogProvider,
        { provider: ResourceProvider, resourceType: 'vidinet' },
        SnackbarProvider,
        { provider: BinProvider, initialState: initialBin, onChange: onChangeBin },
        RolesProvider,
        DragAndDropProvider,
        {
          provider: UploadProvider,
          uploadProps: {
            initialMetadata: {
              dsc: '',
              tcd: [],
            },
            chunkedUploadProps: {
              chunkSize: 200000000, // 200mb chunks
              minChunkSize: 10000000, // 10mb
              maxConcurrentTransfers: 10,
              minFileSize: 100000000, // 100MB
              maxConcurrentFileTransfers: 1,
            },
          },
        },
      ]}
    >
      <Box height="100vh">
        <Header userName={userName} onLogout={onLogout} serverUrl={serverUrl} onLogin={onLogin} />
        <Box mb={7.5} p={2}>
          <Switch>
            <Route path="/import">
              <ItemUpload />
            </Route>
            <Route path="/archive/:itemId">
              <Item />
            </Route>
            <Route path="/archive/">
              <SearchItem userName={userName} />
            </Route>
            <Route path="/settings">
              <Settings />
            </Route>
            <Route path="/collection/:collectionId">
              <Collection />
            </Route>
            <Route path="/collection/">
              <SearchCollectionList />
            </Route>
            <Route path="/workspace/">
              <Workspace />
            </Route>
            <Route path="/housekeeping/:itemId">
              <ItemPrivileged />
            </Route>
            <Route path="/housekeeping/">
              <SearchItemPrivileged userName={userName} />
            </Route>
            <Redirect exact from="/" push to="/archive/" />
          </Switch>
        </Box>
      </Box>
      <JobFooterContextProvider />
    </ComposeProviders>
  );
}

export default App;
