import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { Pagination } from '@vidispine/vdt-materialui';

export default function SearchCollectionPagination({
  collectionListType,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}) {
  const { hits: count = 0 } = collectionListType;

  return (
    <>
      <Box
        height="95%"
        style={{
          marginLeft: '356px',
        }}
      >
        <Grid item>
          <Hidden smDown>
            <Pagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Hidden>
        </Grid>
      </Box>
    </>
  );
}
