import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import { GetCollection, useCollectionAutoPlay } from '@vidispine/vdt-react';
import { collection as CollectionApi } from '@vidispine/vdt-api';
import {
  ItemTabs,
  CollectionMetadataTab,
  CollectionAccessTab,
  CollectionContent,
} from '@vidispine/vdt-materialui';
import { parseSimpleMetadataType, formatTimeCodeText } from '@vidispine/vdt-js';

import CollectionHeader from './CollectionHeader';
import CollectionDeleteDialog from './CollectionDeleteDialog';
import Item from '../item/Item';
import { SnackbarContext } from '../SnackbarContext';
import { COLLECTION_METADATA_FIELDS, COLLECTION_ENTITY_ITEM_LIST_FIELDS } from '../const';

const headerQueryParams = {
  field: ['collectionId', 'title', 'created', '__user', 'user'],
  content: ['metadata'],
};

const collectionTabs = [
  {
    label: 'Metadata',
    component: CollectionMetadataTab,
    fields: COLLECTION_METADATA_FIELDS,
  },
  {
    label: 'Access',
    component: CollectionAccessTab,
  },
];

const ListItemProps = {
  ItemContentProps: {
    ListItemTextProps: {
      primaryTextKeys: COLLECTION_ENTITY_ITEM_LIST_FIELDS,
    },
  },
};

export default function Collection({ collectionId: propsCollectionId, onClose, onError }) {
  const { t } = useTranslation();
  const { collectionId: paramsCollectionId } = useParams();
  const collectionId = propsCollectionId || paramsCollectionId;
  const thisCollection = { entityId: collectionId, entityType: 'collection' };
  const [selected, setSelected] = React.useState(thisCollection);
  const onCloseSelected = () => setSelected(thisCollection);
  const contentItemsRef = React.useRef();
  const [isAutoSaveReorder, setIsAutoSaveReorder] = React.useState(false);

  const history = useHistory();
  const { setNotification } = React.useContext(SnackbarContext);

  const onDeleteSuccess = () => {
    setNotification({ open: true, message: `${collectionId} successfully removed` });
    history.push('/collection');
  };

  const onDeleteFailure = ({ message = 'There was an error processing your request' }) => {
    setNotification({ open: true, message, severity: 'error' });
  };
  const handleClick = ({ entityType, entityId, startTimecodeText, endTimecodeText } = {}) => {
    const startSeconds = startTimecodeText
      ? formatTimeCodeText(startTimecodeText).toSeconds()
      : undefined;
    const endSeconds = endTimecodeText
      ? formatTimeCodeText(endTimecodeText).toSeconds()
      : undefined;
    setSelected({
      entityType,
      entityId,
      startSeconds,
      endSeconds,
    });
  };

  const onSave = async () => {
    if (contentItemsRef.current) {
      try {
        await CollectionApi.updateCollection({
          collectionId,
          collectionDocument: {
            content: contentItemsRef.current.map((item) => ({
              id: item.id,
              type: item.type,
              mode: 'move',
              metadata: item.metadata,
            })),
          },
        });
        setNotification({
          open: true,
          severity: 'success',
          message: `Collection Updated`,
        });
      } catch (error) {
        if (onError) onError(error);
      }
    }
  };

  const onChange = (contentItems) => {
    contentItemsRef.current = contentItems;
    if (isAutoSaveReorder) onSave();
  };

  const toggleAutosave = () => {
    setIsAutoSaveReorder((prevState) => !prevState);
  };

  const { onNext, isAutoPlayEnabled, toggleAutoPlay, nextPlaying } = useCollectionAutoPlay({
    collectionId,
  });

  React.useEffect(() => {
    if (nextPlaying) {
      const { id: entityId, type: entityType, metadata: simpleMetadataType } = nextPlaying;
      const newSelected = { entityType, entityId };
      if (simpleMetadataType) {
        const { start: startTimecodeText, end: endTimecodeText } = parseSimpleMetadataType(
          simpleMetadataType,
        );
        if (startTimecodeText !== undefined) {
          newSelected.startSeconds = formatTimeCodeText(startTimecodeText).toSeconds();
        }
        if (endTimecodeText !== undefined) {
          newSelected.endSeconds = formatTimeCodeText(endTimecodeText).toSeconds();
        }
      }
      setSelected(newSelected);
    }
  }, [nextPlaying]);

  return (
    <div>
      {selected && (
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 8, width: 440 }}>
            <GetCollection collectionId={collectionId} queryParams={headerQueryParams}>
              <CollectionHeader
                onClose={onClose}
                CollectionHeaderMenuProps={{
                  onSave,
                  toggleAutosave,
                  isAutoSaveReorder,
                  isAutoPlayEnabled,
                  toggleAutoPlay,
                }}
              />
            </GetCollection>
            <div style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
              <CollectionContent
                collectionId={collectionId}
                onClick={handleClick}
                selected={selected}
                ListItemProps={ListItemProps}
                onError={onError}
                onChange={onChange}
                onClose={onCloseSelected}
              />
            </div>
          </div>
          <Divider orientation="vertical" flexItem />
          <div style={{ marginLeft: 8, flexGrow: 1 }}>
            {selected.entityType && (
              <>
                {
                  {
                    item: (
                      <>
                        <Item
                          itemId={selected.entityId}
                          EntityCommentComponent={false}
                          isCinema
                          startSeconds={selected.startSeconds}
                          endSeconds={selected.endSeconds}
                          ItemHeaderProps={{
                            onClose: onCloseSelected,
                            linkTo: `/collection/${selected.entityId}`,
                          }}
                          onVideoEnd={() => onNext({ itemId: selected.entityId })}
                        />
                      </>
                    ),
                    collection: (
                      <>
                        {selected.entityId !== collectionId && (
                          <GetCollection
                            collectionId={selected.entityId}
                            queryParams={headerQueryParams}
                          >
                            <CollectionHeader
                              isLoading={false}
                              onClose={onCloseSelected}
                              linkTo={`/collection/${selected.entityId}`}
                            />
                          </GetCollection>
                        )}
                        <ItemTabs tabs={collectionTabs} collectionId={selected.entityId} />
                      </>
                    ),
                  }[selected.entityType]
                }
              </>
            )}
          </div>
        </div>
      )}
      <CollectionDeleteDialog
        collectionId={collectionId}
        onSuccess={onDeleteSuccess}
        onFailure={onDeleteFailure}
        title={`${t('delete')} ${t('collection')}`}
        subtitle="Contained items are unaffected"
        confirmButtonText="Permanently delete collection"
      />
    </div>
  );
}
