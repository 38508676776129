import React from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import debounce from 'lodash.debounce';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import { useItemSearchAutocomplete, useRoles } from '@vidispine/vdt-react';
import {
  SearchInput,
  Sort,
  SearchSuggestions,
  LayoutButtonGroup,
  SpellCheck,
} from '@vidispine/vdt-materialui';

import { roles as ROLES } from '@vidispine/vdt-js';
import SavedSearchModal from './SavedSearchModal';
import Filter from './Filter';
import CustomBinList from '../bin/CustomBinList';
import AdvancedSearch from './AdvancedSearch';

const AUTOCOMPLETE_DEBOUNCE_TIMEOUT = 200;

const defaultSortOptions = [
  { label: 'Created by', value: 'created' },
  { label: 'Title', value: 'title' },
];

const styles = (theme) => ({
  root: {
    height: '95%',
    width: '100%',
  },
  resultContainer: { height: 'inherit', width: 'inherit', display: 'flex', marginTop: '22px' },
  filterContainer: { minWidth: 220, marginBottom: theme.spacing(1) },
  leftResultContainer: { flexDirection: 'column', marginRight: theme.spacing(2) },
  rightResultContainer: { flexGrow: 1, minWidth: '0%' },
  QueryBuilderContainer: {
    marginBottom: theme.spacing(1),
  },
});

function SearchItemForm({
  classes,
  children,
  setSearchText,
  isLoading,
  sortOptions = defaultSortOptions,
  onChangeSort,
  itemSearchDocument,
  layout,
  onChangeViewLayout,
  itemListType,
  setItemSearchDocument,
  setSearchFilter = () => null,
  filterFields,
  withBin = false,
  BinProps = {},
  onRefresh,
  queryParams,
}) {
  const searchInputRef = React.useRef(null);
  const {
    suggestion: autoCompleteSuggestion,
    handleAutocompleteChange,
  } = useItemSearchAutocomplete(itemSearchDocument, queryParams, AUTOCOMPLETE_DEBOUNCE_TIMEOUT);

  const initialSearchText = React.useMemo(
    () =>
      (itemSearchDocument.text &&
        itemSearchDocument.text.length > 0 &&
        itemSearchDocument.text[0].value) ||
      '',
    [itemSearchDocument.text],
  );
  const { sort: initialSort } = itemSearchDocument;
  const { t } = useTranslation();

  const compatibleInitialSort = React.useMemo(
    () =>
      initialSort && initialSort.length
        ? {
            option: sortOptions.find((o) => o.value === initialSort[0].field),
            direction: initialSort[0].order,
          }
        : undefined,
    [initialSort, sortOptions],
  );
  const hasAdvancedSearch =
    itemSearchDocument.operator &&
    (itemSearchDocument.operator.operator ||
      itemSearchDocument.operator.field ||
      itemSearchDocument.operator.group);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = React.useState(false);
  const onClickAdvancedSearch = () => setIsAdvancedSearchOpen((prevState) => !prevState);
  const onClickSuggestion = React.useCallback(
    (s) => {
      setSearchText(s);
      searchInputRef.current.value = s;
    },
    [searchInputRef, setSearchText],
  );
  const { suggestion = [] } = itemListType;
  const { text: [{ value: searchText } = {}] = [] } = itemSearchDocument;
  const [savedSearchId, setSavedSearchId] = React.useState();

  const onApplySearch = React.useCallback(
    (newItemSearchDocument, newSavedSearchId) => {
      setItemSearchDocument(newItemSearchDocument);
      const { text: [{ value: savedSearchText = '' } = {}] = [] } = newItemSearchDocument;
      searchInputRef.current.value = savedSearchText;
      if (newSavedSearchId) setSavedSearchId(newSavedSearchId);
    },
    [searchInputRef, setItemSearchDocument],
  );
  const debouncedSetSearchFilter = React.useRef(
    debounce(setSearchFilter, AUTOCOMPLETE_DEBOUNCE_TIMEOUT),
  ).current;

  const onSearchButtonClick = () => {
    if (!searchInputRef) return;
    const inputValue = searchInputRef.current.value;
    if ((!inputValue && !searchText) || searchText === inputValue) {
      onRefresh();
    } else {
      setSearchText(inputValue);
    }
  };

  const { hasRole } = useRoles();
  const hasRightForSearch = hasRole(ROLES.ITEM_SEARCH);

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item sm={8} md={4} lg={6}>
          <Grid container wrap="nowrap">
            <SearchInput
              defaultValue={initialSearchText}
              onSubmit={setSearchText}
              submitting={isLoading}
              searchPlaceholder={t('searchItems')}
              onChange={handleAutocompleteChange}
              SuggestionsComponent={SearchSuggestions}
              suggestions={autoCompleteSuggestion}
              inputRef={searchInputRef}
              ButtonProps={{
                onClick: onSearchButtonClick,
                type: 'button',
              }}
              disabled={!hasRightForSearch}
            />
            {onApplySearch && (
              <SavedSearchModal
                itemSearchDocument={itemSearchDocument}
                onApplySearch={onApplySearch}
              />
            )}
          </Grid>
        </Grid>
        <Grid item sm={4} md={8} lg={6}>
          <Grid container alignItems="center" justify="flex-end">
            {false && (
              <Grid item>
                <Button size="small" onClick={onClickAdvancedSearch}>
                  <Badge color="secondary" variant="dot" invisible={!hasAdvancedSearch}>
                    Advanced Search
                  </Badge>
                </Button>
              </Grid>
            )}
            {sortOptions && (
              <Grid item>
                <Hidden smDown>
                  <Sort
                    options={sortOptions.map((o) => ({
                      label: t(o.label),
                      value: o.value,
                    }))}
                    initialSort={compatibleInitialSort}
                    onChange={onChangeSort}
                  />
                </Hidden>
              </Grid>
            )}
            {layout !== undefined && (
              <Grid item>
                <LayoutButtonGroup viewLayout={layout} onChangeViewLayout={onChangeViewLayout} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.resultContainer}>
        {(withBin || filterFields) && (
          <Hidden smDown>
            <div className={classes.leftResultContainer}>
              {filterFields && (
                <div className={classes.filterContainer}>
                  <Filter
                    key={savedSearchId}
                    setSearchFilter={debouncedSetSearchFilter}
                    initialSearchFilter={itemSearchDocument.filter}
                    isLoading={isLoading}
                    itemListType={itemListType}
                    filterFields={filterFields}
                  />
                </div>
              )}
              {withBin && (
                <CustomBinList
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...BinProps}
                />
              )}
            </div>
          </Hidden>
        )}
        <div className={classes.rightResultContainer}>
          {suggestion && suggestion.length > 0 ? (
            <SpellCheck
              searchText={searchText}
              suggestions={suggestion}
              onClick={onClickSuggestion}
            />
          ) : null}
          <Collapse in={isAdvancedSearchOpen} key={savedSearchId}>
            <AdvancedSearch
              onSubmit={onApplySearch}
              itemSearchDocument={itemSearchDocument}
              classes={{ root: classes.QueryBuilderContainer }}
            />
          </Collapse>
          {children}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(SearchItemForm);
