import React from 'react';
import { search as SearchApi } from '@vidispine/vdt-api';
import useApi from './useApi';
import useUpdateEffect from './useUpdateEffect';

const setInitialDefaultState = ({
  field = undefined,
  text = '',
  maximumSuggestions = 10,
} = {}) => ({
  field,
  text,
  maximumSuggestions,
});

export default function useAutocomplete(initialState) {
  const initialDefaultState = setInitialDefaultState(initialState);
  const [autocompleteDoc, setAutocompleteDoc] = React.useState(initialDefaultState);

  const setAutocompleteField = React.useCallback((field, text = '') => {
    setAutocompleteDoc((state) => ({ ...state, field, text }));
  }, []);

  const setAutocompleteText = React.useCallback((text) => {
    setAutocompleteDoc((state) => ({ ...state, text }));
  }, []);

  const setAutocompleteMaxSuggestions = React.useCallback((maximumSuggestions) => {
    setAutocompleteDoc((state) => ({ ...state, maximumSuggestions }));
  }, []);

  const { request: searchAutoComplete, data: { suggestion: suggestions = [] } = {} } = useApi(
    SearchApi.searchAutoComplete,
  );

  useUpdateEffect(() => {
    searchAutoComplete({ autocompleteRequestDocument: autocompleteDoc });
  }, [autocompleteDoc]);

  return {
    suggestions,
    setAutocompleteField,
    setAutocompleteText,
    setAutocompleteMaxSuggestions,
  };
}
