import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { TextField } from '@vidispine/vdt-materialui';
import {
  Button,
  withStyles,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Divider,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const styles = (theme) => ({
  openSearch: { marginLeft: 'auto' },
  formLabelContainer: {
    display: 'flex',
  },
  markAll: { padding: theme.spacing(1, 1, 1, 0) },
  filtersContainer: {},
  filter: {
    padding: theme.spacing(1, 0, 0, 0),
  },
});

function SearchInFilters({ options = [], onChange, label, classes }) {
  const { t } = useTranslation();
  const [filters, setFilters] = React.useState();
  const [checkedFilterValues, setCheckedFilterValues] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (options) setFilters(options.slice(1));
  }, [options]);

  const handleFilterSearch = ({ target: { value } }) => {
    const queryOptions = options
      .slice(1)
      .filter((option) => option.value.toLowerCase().startsWith(value.toLowerCase()));
    setFilters(queryOptions);
  };

  const handleCheckedFilterValue = ({ target: { value } }) => {
    if (checkedFilterValues.includes(value)) {
      setCheckedFilterValues((prevState) =>
        prevState.filter((filterValue) => filterValue !== value),
      );
    } else {
      setCheckedFilterValues((prevState) => [...prevState, value]);
    }
  };

  const handleSelectAll = () => {
    if (checkedFilterValues.length === filters.length) {
      setCheckedFilterValues([]);
    } else {
      setCheckedFilterValues(filters.map((filter) => filter.value));
    }
  };

  const handleSetSelection = () => {
    if (checkedFilterValues.length > 0) {
      onChange(checkedFilterValues);
      setCheckedFilterValues([]);
      handleClose(null);
    }
  };

  return (
    <>
      <div className={clsx(classes.formLabelContainer, 'VdtCheckboxGroupField-formLabelContainer')}>
        <FormLabel component="legend">{label}</FormLabel>
        <Button
          className={classes.openSearch}
          size="small"
          type="submit"
          onClick={handleClick}
          disableRipple
        >
          <SearchIcon />
        </Button>
      </div>
      <Dialog open={Boolean(anchorEl)} onClose={handleClose}>
        <DialogTitle>Search in filters</DialogTitle>
        <DialogContent>
          <TextField
            onChange={handleFilterSearch}
            label={label}
            helperText={null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.markAll}>
            <FormControlLabel
              label={t('markAll')}
              onChange={handleSelectAll}
              control={
                <Checkbox checked={checkedFilterValues.length === options.slice(1).length} />
              }
            />
          </div>

          <Divider />
          <div className={classes.filtersContainer}>
            {filters &&
              filters.map((filter) => (
                <FormControlLabel
                  key={filter.value}
                  onChange={handleCheckedFilterValue}
                  label={filter.value}
                  classes={{
                    root: classes.filter,
                  }}
                  control={
                    <Checkbox
                      name={filter.value}
                      value={filter.value}
                      checked={checkedFilterValues.includes(filter.value)}
                    />
                  }
                />
              ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSetSelection}>
            {t('setSelection')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(styles, { name: 'VdtSearchInFilters' })(SearchInFilters);
