import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { SavedSearch } from '@vidispine/vdt-materialui';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  paper: {},
};

function SavedSearchModal({ classes, itemSearchDocument, onApplySearch }) {
  const [saveSearchAnchorEl, setSaveSearchAnchorEl] = React.useState(null);

  const onSelect = (query, savedSearchId) => {
    onApplySearch(query, savedSearchId);
    setSaveSearchAnchorEl(null);
  };

  const cancel = () => {
    setSaveSearchAnchorEl(false);
  };

  const responseRef = React.useRef();

  return (
    <>
      <IconButton
        onClick={(e) => setSaveSearchAnchorEl(e.currentTarget)}
        color="inherit"
        disableRipple
        style={{
          padding: '5px',
          marginLeft: '8px',
        }}
      >
        <BookmarkIcon />
      </IconButton>
      <Dialog
        classes={classes}
        open={Boolean(saveSearchAnchorEl)}
        anchorEl={saveSearchAnchorEl}
        onClose={() => setSaveSearchAnchorEl(null)}
      >
        <DialogTitle>Saved Search</DialogTitle>
        <DialogContent>
          <SavedSearch
            itemSearchDocument={itemSearchDocument}
            markerField="eidr_referentType"
            markerValue="savedSearch"
            onSelect={onSelect}
            onSaveSuccess={() => setSaveSearchAnchorEl(null)}
            SearchListProps={{
              SearchCollectionProps: {
                responseRef,
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(styles)(SavedSearchModal);
