import React, { useCallback } from 'react';

export default function useHoverScrub(srcList = [], customOffset = 0) {
  const [scrubPosition, setScrubPosition] = React.useState(0);
  const [thumbnailIndex, setThumbnailIndex] = React.useState(0);
  const [showScrub, setShowScrub] = React.useState(false);

  const getInnerLeft = useCallback(
    (e) => {
      const left = e.clientX - e.currentTarget.offsetLeft - customOffset;
      if (left < 0) {
        return 0;
      }
      if (left > e.currentTarget.offsetWidth) {
        return e.currentTarget.offsetWidth;
      }
      return left;
    },
    [customOffset],
  );

  const onMouseMove = (e) => {
    if (srcList.length > 1) {
      const innerLeft = getInnerLeft(e);
      setScrubPosition(innerLeft - 1);
      setThumbnailIndex(Math.floor((innerLeft / e.currentTarget.offsetWidth) * srcList.length));
    }
  };

  const handleShowScrub = (value) => () => {
    if (srcList.length > 1) {
      setShowScrub(value);
    }
  };

  return {
    thumbnailIndex,
    showScrub,
    scrubPosition,
    handleShowScrub,
    onMouseMove,
  };
}
