import React from 'react';
import { EntityDeleteDialogPrivileged } from '@vidispine/vdt-materialui';
import { useDialogContext } from '../DialogContext';

export const DIALOG_ID = 'DELETE_ITEM_DIALOG_PRIVILEGED';

export default function ItemDeleteDialogPrivileged({ itemId, ...props }) {
  const { dialogId, onClose } = useDialogContext();
  return (
    <EntityDeleteDialogPrivileged
      open={dialogId === DIALOG_ID}
      onClose={onClose}
      entity="item"
      entityId={itemId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
