/* eslint react/prop-types: "off" */
import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { job as JobApi } from '@vidispine/vdt-api';

import useApi from '../hooks/useApi';

export default function GetJobs({ matrixParams, queryParams, children }) {
  const { request, data: jobs = {}, isLoading, isError } = useApi(JobApi.listJob);
  const onRefresh = () => request({ matrixParams, queryParams });
  useDeepCompareEffect(() => {
    onRefresh();
  }, [matrixParams, queryParams]);
  const cloneProps = {
    matrixParams,
    queryParams,
    onRefresh,
    isLoading,
    isError,
    jobs,
  };
  const cloneChild = (child) => React.cloneElement(child, cloneProps);
  return React.Children.map(children, cloneChild);
}
