const clearStore = (key) => localStorage.removeItem(key);
const readStore = (key) => window.localStorage.getItem(key);
const writeStore = (key, value) => window.localStorage.setItem(key, value);

const isExpired = (key) => {
  const lifetime = readStore(key);
  return lifetime > 0 && lifetime < Date.now();
};

class IdleTimer {
  constructor({
    handleExpire = () => {},
    handlePulse = () => Promise.resolve(),
    handleTimeout = () => {},
    lifetime = 7200,
  }) {
    this.debounceRateRead = 1000;
    this.debounceRateWrite = 300;
    this.eventListeners = ['keydown', 'mousemove', 'scroll'];
    this.handleExpire = handleExpire;
    this.handlePulse = handlePulse;
    this.handleTimeout = handleTimeout;
    this.handleUpdate = this.updateStore.bind(this);
    this.intervalId = null;
    this.lifetime = lifetime;
    this.storeKey = '_sessionLifetime';
    this.timeoutId = null;
    this.init();
  }

  destroy() {
    clearInterval(this.intervalId);
    clearStore(this.storeKey);
    this.eventListeners.forEach((listener) => {
      window.removeEventListener(listener, this.handleUpdate);
    });
  }

  init() {
    if (isExpired(this.storeKey)) {
      this.handleExpire();
      return;
    }
    this.initHeartbeat();
    this.eventListeners.forEach((listener) => {
      window.addEventListener(listener, this.handleUpdate);
    });
  }

  initHeartbeat() {
    this.updateStore();
    this.intervalId = setInterval(() => {
      if (isExpired(this.storeKey)) {
        this.destroy();
        this.handleTimeout();
      }
    }, this.debounceRateRead);
  }

  updateStore() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.handlePulse().then(() => {
        const lifetime = Date.now() + this.lifetime * 1000;
        writeStore(this.storeKey, lifetime);
      });
    }, this.debounceRateWrite);
  }
}

/* eslint-disable import/prefer-default-export */
export { IdleTimer };
