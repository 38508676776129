import React from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import {
  SearchInput,
  Sort,
  Pagination,
  LayoutButtonGroup,
  EntityGridItemDraggable,
  EntityTableRowDraggable,
  ENTITY_TREE_ROOT,
} from '@vidispine/vdt-materialui';
import WorkpaceEntityGrid from './WorkpaceEntityGrid';
import WorkspaceEntityTable from './WorkspaceEntityTable';
import Filter from '../search/Filter';
import WorkspaceTree from './WorkspaceTree';

const GRID_VIEW = 'GRID_VIEW';
const ROW_VIEW = 'ROW_VIEW';
const DEFAULT_VIEW = ROW_VIEW;
const DEBOUNCE_TIMEOUT = 800;
const MOVE_REFRESH_MS_DELAY = 1000;

const defaultSortOptions = [
  { label: 'Created by', value: 'created' },
  { label: 'Title', value: 'title' },
];

export default function WorkspaceSearchList({
  isLoading,
  onRefresh,
  searchResultType,
  initialWorkspaceId,
  initialExpandedWorkspace,
  initialSearchText,
  initialSearchFilter,
  initialSort,
  sortOptions = defaultSortOptions,
  setWorkspace,
  setSearchText,
  setSearchFilter: debounceSetSearchFilter,
  onChangeRowsPerPage,
  onChangePage,
  onChangeSort,
  page,
  rowsPerPage,
  parentCollectionId = ENTITY_TREE_ROOT,
}) {
  const timerRef = React.useRef(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchFilter = React.useCallback(debounce(debounceSetSearchFilter, DEBOUNCE_TIMEOUT), [
    debounceSetSearchFilter,
  ]);
  const [viewLayout, setViewLayout] = React.useState(DEFAULT_VIEW);
  const onChangeViewLayout = (event, newLayout) => {
    if (newLayout) setViewLayout(newLayout);
  };
  let ViewComponent;
  switch (viewLayout) {
    case GRID_VIEW:
      ViewComponent = WorkpaceEntityGrid;
      break;
    case ROW_VIEW:
      ViewComponent = WorkspaceEntityTable;
      break;
    default:
      ViewComponent = WorkpaceEntityGrid;
      break;
  }
  const { t } = useTranslation();
  const { entry: entries = [], hits: count = 0 } = searchResultType;
  const minExpectedCount = isLoading ? page * rowsPerPage + 1 : 0;
  const compatibleInitialSort = React.useMemo(
    () =>
      initialSort && initialSort.length
        ? {
            option: sortOptions.find((o) => o.value === initialSort[0].field),
            direction: initialSort[0].order,
          }
        : undefined,
    [initialSort, sortOptions],
  );

  const handleMoved = () => {
    timerRef.current = setTimeout(() => onRefresh(), MOVE_REFRESH_MS_DELAY);
  };
  React.useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [searchResultType]);
  return (
    <Box height="95%" width="100%">
      <Grid container alignItems="center" justify="space-between">
        <Grid item sm={8} md={4} lg={6}>
          <SearchInput
            onSubmit={setSearchText}
            defaultValue={initialSearchText}
            submitting={isLoading}
            searchPlaceholder={t('searchItems')}
          />
        </Grid>
        <Grid item sm={4} md={8} lg={6}>
          <Grid container alignItems="center" justify="flex-end">
            <Grid item>
              <Hidden smDown>
                <Sort
                  options={sortOptions.map((o) => ({ label: t(o.label), value: o.value }))}
                  initialSort={compatibleInitialSort}
                  onChange={onChangeSort}
                />
              </Hidden>
            </Grid>
            <Grid item>
              <Hidden smDown>
                <Pagination
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  count={count || minExpectedCount}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
              </Hidden>
            </Grid>
            <Grid item>
              <LayoutButtonGroup viewLayout={viewLayout} onChangeViewLayout={onChangeViewLayout} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ height: '95%', width: '100%' }}>
        <Hidden smDown>
          <Grid item sm={12} md={2}>
            <Box pb={2}>
              <Filter
                setSearchFilter={setSearchFilter}
                initialSearchFilter={initialSearchFilter}
                isLoading={isLoading}
              />
              <WorkspaceTree
                onChange={setWorkspace}
                onMoved={handleMoved}
                initialWorkspaceId={initialWorkspaceId}
                initialExpanded={initialExpandedWorkspace}
              />
            </Box>
          </Grid>
        </Hidden>
        <Grid item sm={12} md={10} style={{ height: '95%', width: '100%' }}>
          <ViewComponent
            entries={entries}
            EntityGridItemComponent={EntityGridItemDraggable}
            EntityTableRowComponent={EntityTableRowDraggable}
            parentCollectionId={parentCollectionId}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
