const MuiDialogActions = {
  root: {
    padding: '16px 24px 12px',
    background: '#2a3241',
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      height: '1px',
      background: '#47556f',
      position: 'absolute',
      top: '0',
      left: '24px',
      right: '24px',
    },
  },
  spacing: {},
};

export default MuiDialogActions;
