import React from 'react';
import { group as GroupApi } from '@vidispine/vdt-api';
import useApi from './useApi';

export default function useGroup(initialState) {
  const { data: groupType, request } = useApi(GroupApi.getGroup, { data: initialState });
  const { groupName } = groupType || {};

  const getGroup = React.useCallback(() => request({ groupName }), [request, groupName]);

  React.useEffect(() => {
    if (groupName) getGroup();
  }, [getGroup, groupName]);

  const updateGroup = React.useCallback(
    ({ groupDocument }) =>
      GroupApi.updateGroup({
        groupName,
        groupDocument,
      }).then(getGroup),
    [getGroup, groupName],
  );

  const addChildGroups = React.useCallback(
    ({ groups }) =>
      GroupApi.updateGroup({
        groupName,
        groupDocument: {
          childGroupList: {
            group: groups.map((g) => ({ groupName: g })),
          },
        },
      }).then(getGroup),
    [getGroup, groupName],
  );

  const addParentGroups = React.useCallback(
    ({ groups }) =>
      GroupApi.updateGroup({
        groupName,
        groupDocument: {
          parentGroupList: {
            group: groups.map((g) => ({ groupName: g })),
          },
        },
      }).then(getGroup),
    [getGroup, groupName],
  );

  const addUsersToGroup = React.useCallback(
    ({ users }) =>
      GroupApi.updateGroup({
        groupName,
        groupDocument: {
          userList: {
            user: users.map((u) => ({ userName: u })),
          },
        },
      }).then(getGroup),
    [getGroup, groupName],
  );

  const removeUserFromGroup = React.useCallback(
    (userName) =>
      GroupApi.removeGroupUser({
        groupName,
        userName,
      }).then(getGroup),
    [getGroup, groupName],
  );

  const removeChildGroup = React.useCallback(
    (childGroupName) =>
      GroupApi.removeGroupChildren({
        groupName,
        childGroupName,
      }).then(getGroup),
    [getGroup, groupName],
  );

  const removeFromParentGroup = React.useCallback(
    (parentGroupName) =>
      GroupApi.removeGroupChildren({
        groupName: parentGroupName,
        childGroupName: groupName,
      }).then(getGroup),
    [getGroup, groupName],
  );

  const removeGroup = React.useCallback(() => GroupApi.removeGroup({ groupName }), [groupName]);

  return {
    groupType,
    getGroup,
    updateGroup,
    removeGroup,
    addChildGroups,
    addParentGroups,
    addUsersToGroup,
    removeUserFromGroup,
    removeChildGroup,
    removeFromParentGroup,
  };
}
