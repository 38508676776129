import React from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { exportlocation as ExportLocationApi } from '@vidispine/vdt-api';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import AddIcon from '@material-ui/icons/Add';
import { useApi } from '@vidispine/vdt-react';
import { useConfirmationDialog } from '@vidispine/vdt-materialui';

import { SnackbarContext } from '../SnackbarContext';
import { useDialogContext } from '../DialogContext';
import CreateExportLocationDialog, {
  DIALOG_ID as CREATE_EXPORTLOCATION_DIALOG,
} from './CreateExportLocationDialog';
import EditExportLocationDialog, {
  DIALOG_ID as EDIT_EXPORTLOCATION_DIALOG,
} from './EditExportLocationDialog';
import ExportLocationList from './ExportLocationList';

const styles = () => ({
  buttonList: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
  },
});

function ExportLocation({ classes }) {
  const [ConfirmationDialog, confirm] = useConfirmationDialog({ throwOnReject: false });

  const { setNotification } = React.useContext(SnackbarContext);
  const { onOpen: onOpenDialog, onClose: onCloseDialog } = useDialogContext();
  const { t } = useTranslation();
  const [selectedLocation, setSelectedLocation] = React.useState();

  const onEditLocation = (editLocation) => {
    setSelectedLocation(editLocation);
    onOpenDialog(EDIT_EXPORTLOCATION_DIALOG)();
  };

  const { data: exportLocationListType, request: listExportLocation } = useApi(
    ExportLocationApi.listExportLocation,
  );

  React.useEffect(() => {
    listExportLocation().catch((error) => {
      setNotification({
        open: true,
        message: error.message,
        severity: 'error',
      });
    });
  }, [listExportLocation, setNotification]);

  const onSuccess = ({ name }) => {
    onCloseDialog();
    setNotification({
      open: true,
      message: `${name} has been updated`,
      severity: 'success',
    });
    listExportLocation();
    setSelectedLocation();
  };

  const onError = (error) =>
    setNotification({
      open: true,
      message: error.message,
      severity: 'error',
    });

  const onDeleteLocation = (location) => {
    confirm(location).then((confirmed) => {
      if (confirmed) {
        try {
          ExportLocationApi.removeExportLocation({
            locationName: location.name,
          })
            .then(() => {
              setNotification({
                open: true,
                message: `${location.name} has been deleted`,
                severity: 'success',
              });
              listExportLocation();
            })
            .catch((error) =>
              setNotification({
                open: true,
                message: error.message,
                severity: 'error',
              }),
            );
        } catch (error) {
          setNotification({
            open: true,
            message: error.message,
            severity: 'error',
          });
        }
      }
    });
  };

  return (
    <>
      <div className={classes.buttonList}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={onOpenDialog(CREATE_EXPORTLOCATION_DIALOG)}
        >
          {t('new')}
        </Button>
      </div>
      {exportLocationListType && (
        <ExportLocationList
          exportLocationListType={exportLocationListType}
          onEditLocation={onEditLocation}
          onDeleteLocation={onDeleteLocation}
        />
      )}
      <CreateExportLocationDialog
        onSuccess={onSuccess}
        onError={onError}
        DialogProps={{
          fullWidth: true,
          maxWidth: 'md',
        }}
      />
      <EditExportLocationDialog
        exportLocationType={selectedLocation}
        onSuccess={onSuccess}
        onError={onError}
        DialogProps={{
          fullWidth: true,
          maxWidth: 'md',
        }}
      />
      {ConfirmationDialog && (
        <ConfirmationDialog
          titleText="Delete Export Location"
          confirmText={t('Delete')}
          rejectText={t('Cancel')}
        >
          {({ name }) => (
            <DialogContentText>Are you sure you want to delete {name}?</DialogContentText>
          )}
        </ConfirmationDialog>
      )}
    </>
  );
}

export default withStyles(styles)(ExportLocation);
