import React from 'react';
import { Switch, Route, useRouteMatch, NavLink, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import WorkIcon from '@material-ui/icons/Work';
import StorageIcon from '@material-ui/icons/Storage';
import EditIcon from '@material-ui/icons/Edit';
import FlightIcon from '@material-ui/icons/Flight';
import UserIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';

import JobList from './JobList';
import Storage from './Storage';
import UserList from './user/UserList';
import GroupList from './group/GroupList';
import MetadataDesigner from './MetadataDesigner';
import ExportLocation from './exportlocation/ExportLocation';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    zIndex: theme.zIndex.appBar - 1,
  },
  drawerPaper: {
    width: 240,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  adminTitle: {
    padding: theme.spacing(3),
    marginTop: 60,
  },
});

function Settings({ classes }) {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerContainer}>
          <Typography variant="h5" className={classes.adminTitle}>
            {t('settings')}
          </Typography>
          <Divider />
          <List>
            <ListItem button component={NavLink} to={`${path}/jobs`} activeClassName="Mui-selected">
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary={t('jobs')} />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={`${path}/storage`}
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <StorageIcon />
              </ListItemIcon>
              <ListItemText primary={t('storage')} />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={`${path}/metadata/field-group/`}
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={t('metadata')} />
            </ListItem>
            <ListItem button component={NavLink} to={`${path}/export-location/`}>
              <ListItemIcon>
                <FlightIcon />
              </ListItemIcon>
              <ListItemText primary={t('Export Location')} />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={`${path}/user/`}
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText primary={t('users')} />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={`${path}/group/`}
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={t('groups')} />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <div className={classes.content}>
        <Switch>
          <Route exact path={`${path}/jobs`}>
            <JobList />
          </Route>
          <Route exact path={`${path}/storage`}>
            <Storage />
          </Route>
          <Route path={`${path}/metadata/field-group/`}>
            <MetadataDesigner />
          </Route>
          <Route path={`${path}/export-location/`}>
            <ExportLocation />
          </Route>
          <Route path={`${path}/user/`}>
            <UserList />
          </Route>
          <Route path={`${path}/group/`}>
            <GroupList />
          </Route>
          <Redirect exact from={path} push to={`${path}/jobs`} />
        </Switch>
      </div>
    </div>
  );
}

export default withStyles(styles, { name: 'VdtSettings' })(Settings);
