export const PREVIEW_SHAPE_TAG = '__mp4';

export const JOB_PRIORITIES = ['LOWEST', 'LOW', 'MEDIUM', 'HIGH', 'HIGHEST', 'IMMEDIATE'];
export const JOB_STATES = [
  'READY',
  'STARTED',
  'VIDINET_JOB',
  'FINISHED',
  'FINISHED_WARNING',
  'WAITING',
  'ABORT_PENDING',
  'ABORTED',
  'FAILED_TOTAL',
];

export const BIN_LOCAL_STORAGE_KEY = 'bin';
export const SETTINGS_LOCAL_STORAGE_KEY = 'APP_SETTINGS';

export const VIDISPINE_URL = process.env.REACT_APP_VIDISPINE_URL;
export const APP_TITLE = process.env.REACT_APP_TITLE;

export const PREFIX_STRING = '^^PREFIX^^';
export const SUFFIX_STRING = '^^SUFFIX^^';

export const CATEGORY_OPTIONS = [
  'Film & Animation',
  'Autos & Vehicles',
  'Music',
  'Pets & Animals',
  'Sports',
  'Travel & Events',
  'Gaming',
  'People & Blogs',
  'Comedy',
  'Entertainment',
  'News & Politics',
  'Howto & Style',
  'Education',
  'Science & Technology',
  'Nonprofits & Activism',
  '',
];

export const DESCRIPTION_FIELD = { value: 'dsc', label: 'Description', type: 'string' };
export const ADU_FIELD = { value: 'adu_value', label: 'ADU', type: 'string' };
export const STL_TEXT_FIELD = { value: 'stl_text', label: 'Caption' };
export const CO_FIELD = { value: 'co', label: 'Comment' };
export const CREATED_FIELD = { value: 'created', label: 'Created', type: 'date' };
export const DURATIONSECONDS_FIELD = { value: 'durationSeconds', label: 'Duration', type: 'float' };
export const TITLE_FIELD = { value: 'title', label: 'Title', type: 'string' };
export const MIMETYPE_FIELD = { value: 'mimeType', label: 'Mime-Type', type: 'string' };
export const CCT_FIELD = {
  value: 'cct',
  label: 'Category',
  type: 'string',
  displayType: 'autocomplete',
  options: CATEGORY_OPTIONS,
};
export const TCD_FIELD = {
  value: 'tcd',
  label: 'Tags',
  displayType: 'tag',
  AutocompleteProps: { multiple: true, freeSolo: true },
};

export const ORIGINALFILENAME_FIELD = {
  value: 'originalFilename',
  label: 'Filename',
  type: 'string',
};
export const USER_FIELD = { value: 'user', label: 'User', type: 'string' };
export const ITEMID_FIELD = { value: 'itemId', label: 'Item ID', type: 'string' };
export const RDFCOUNTRY_FIELD = {
  value: 'rdf_country',
  label: 'Country',
  type: 'string',
  displayType: 'autocomplete',
};
export const RDFSTATE_FIELD = {
  value: 'rdf_state',
  label: 'State',
  type: 'string',
  displayType: 'autocomplete',
};
export const RDFCITY_FIELD = {
  value: 'rdf_city',
  label: 'City',
  type: 'string',
  displayType: 'autocomplete',
};

export const ADU_CELEBRITY_AWSVIDEOREKOGNITIONANALYZER_GROUP = {
  value: 'adu_celebrity_AWSVideoRekognitionAnalyzer',
};
export const ADU_LABEL_AWSVIDEOREKOGNITIONANALYZER_GROUP = {
  value: 'adu_label_AWSVideoRekognitionAnalyzer',
};

export const ADVANCED_SEARCH_QUERY_FIELDS = [
  TITLE_FIELD,
  DURATIONSECONDS_FIELD,
  CREATED_FIELD,
  ADU_FIELD,
].map(({ value: name, label, type }) => ({ name, label, type }));

export const ADVANCED_SEARCH_QUERY_GROUPS = [
  ADU_CELEBRITY_AWSVIDEOREKOGNITIONANALYZER_GROUP,
  ADU_LABEL_AWSVIDEOREKOGNITIONANALYZER_GROUP,
].map(({ value: name }) => ({ name }));

export const SEARCH_EVENT_HIGHLIGHT_FIELDS = [ADU_FIELD, STL_TEXT_FIELD, CO_FIELD];

export const SEARCH_EVENT_HIGHLIGHT_FIELDNAMES = SEARCH_EVENT_HIGHLIGHT_FIELDS.map(
  ({ value }) => value,
);

export const SEARCH_EVENT_QUERY_FIELDNAMES = [
  TITLE_FIELD,
  ORIGINALFILENAME_FIELD,
  CREATED_FIELD,
  USER_FIELD,
  DURATIONSECONDS_FIELD,
  MIMETYPE_FIELD,
  ITEMID_FIELD,
  ...SEARCH_EVENT_HIGHLIGHT_FIELDS,
].map(({ value }) => value);

export const GROUP_CONFIG = [
  {
    field: ['adu_value', 'adu_av_value'],
    name: 'adu_celebrity_AWSVideoRekognitionAnalyzer',
    label: 'AWS Celebrity Video',
  },
  {
    field: ['adu_value', 'adu_av_value'],
    name: 'adu_label_AWSVideoRekognitionAnalyzer',
    label: 'AWS Label Video',
  },
];
export const FIELD_LABEL_MAP = [
  {
    name: 'adu_av_value',
    label: 'AV Value',
  },
  {
    name: 'adu_value',
    label: 'Value',
  },
];

export const COLLECTION_METADATA_FIELDS = [
  TITLE_FIELD,
  DESCRIPTION_FIELD,
  CCT_FIELD,
  TCD_FIELD,
  // RDFCOUNTRY_FIELD, // These need to be created manually
  // RDFSTATE_FIELD,
  // RDFCITY_FIELD,
].map(({ value: name, ...props }) => ({ name, ...props }));

export const COLLECTION_ENTITY_ITEM_LIST_FIELDS = [
  'adu_label_AWSVideoRekognitionAnalyzer.adu_value',
  'adu_transcript_AWSTranscribeAnalyzer.adu_value',
  'adu_celebrity_AWSVideoRekognitionAnalyzer.adu_value',
  'stl_subtitle.stl_text',
  'co',
];
