import { item as ItemApi } from '@vidispine/vdt-api';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useApi from './useApi';

export default function useGetItem({ itemId, queryParams = {}, refreshOnMount = true }) {
  const { request, data: itemType = {}, isLoading, isError } = useApi(ItemApi.getItem);
  const onRefresh = () => request({ itemId, queryParams });
  useDeepCompareEffect(() => {
    if (refreshOnMount) onRefresh();
  }, [itemId, queryParams]);
  return {
    itemType,
    isLoading,
    isError,
    onRefresh,
  };
}
