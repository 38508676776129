import React from 'react';
import { useUploadFiles } from '@vidispine/vdt-react';

const UploadContext = React.createContext();

export const UploadProvider = ({ children, uploadProps }) => {
  const {
    onAddFiles,
    onChangeMetadata,
    onUpload,
    onRemoveFiles,
    onRemoveFile,
    files,
  } = useUploadFiles(uploadProps);

  const contextUseUploadFiles = () => ({
    onAddFiles,
    onChangeMetadata,
    onUpload,
    onRemoveFiles,
    onRemoveFile,
    files,
  });

  return (
    <UploadContext.Provider
      value={{
        useUploadFiles: contextUseUploadFiles,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export default UploadContext;
