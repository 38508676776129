import React from 'react';
import { item as ItemApi, shape as ShapeApi, bulkymetadata as BulkyApi } from '@vidispine/vdt-api';
import { useApi } from '@vidispine/vdt-react';
import { parseMetadataType } from '@vidispine/vdt-js';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import Crop169Icon from '@material-ui/icons/Crop169';
import Crop32Icon from '@material-ui/icons/Crop32';
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import InfoIcon from '@material-ui/icons/Info';

import { withDialog } from '../DialogContext';
import { useTranscoderResource } from '../ResourceContext';

export const DIALOG_ID = 'ITEM_HEIGHTSCREEN_DIALOG';

const THUMBNAIL_HEIGHT = 200;
const DEFAULT_SMARTCROP_OPTIONS = [
  { value: '1_1', label: 'Square', AvatarIcon: CropSquareIcon },
  { value: '16_9', label: 'Widescreen', AvatarIcon: Crop169Icon },
  { value: '9_16', label: 'Portrait', AvatarIcon: CropPortraitIcon },
  { value: '3_2', label: '3/2', AvatarIcon: Crop32Icon },
];

const styles = (theme) => ({
  dialogContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  thumbnailRoot: {
    height: THUMBNAIL_HEIGHT,
    margin: theme.spacing(2),
    position: 'relative',
  },
  thumbnail: {
    position: 'absolute',
    zIndex: 2,
    transition: 'filter 0.3s',
  },
  thumbnailOverlay: {
    height: 100,
    width: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 3,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transition: 'all 0.3s ease',
  },
});

const useHeightscreen = (itemId, thumbnailHeight, tag) => {
  const { data: itemType = {}, request: getItem, isLoading } = useApi(ItemApi.getItem);
  React.useEffect(() => {
    const queryParams = {
      field: ['originalHeight', 'originalWidth'],
      content: ['metadata', 'thumbnail'],
      'noauth-url': true,
      interval: 'generic',
    };
    getItem({ itemId, queryParams });
  }, [itemId, getItem]);
  const parsed = React.useMemo(
    () =>
      parseMetadataType(itemType.metadata, {
        flat: true,
        arrayOnSingle: false,
      }),
    [itemType],
  );
  const thumbnailSrc = React.useMemo(() => {
    const { thumbnails: { uri: thumbnailList = [] } = {} } = itemType;
    return thumbnailList[Math.round(thumbnailList.length / 2)];
  }, [itemType]);

  const originalWidth = Number(parsed.originalWidth !== undefined ? parsed.originalWidth : 0);
  const originalHeight = Number(parsed.originalHeight !== undefined ? parsed.originalHeight : 0);

  const [overlay, setOverlay] = React.useState();
  const onChange = (event, newOverlay) => setOverlay(newOverlay);

  const thumbnailWidth = (thumbnailHeight / originalHeight) * originalWidth;
  const isLandscape = originalWidth > originalHeight;
  const { overlayHeight, overlayWidth } = React.useMemo(() => {
    if (!overlay) return { overlayHeight: 0, overlayWidth: 0 };
    const widthFactor = Number(overlay.split('_')[0]);
    const heightFactor = Number(overlay.split('_')[1]);
    const aspectRatio = widthFactor / heightFactor;
    return {
      overlayHeight: isLandscape ? thumbnailHeight : thumbnailWidth * aspectRatio,
      overlayWidth: isLandscape ? thumbnailHeight * aspectRatio : thumbnailWidth,
    };
  }, [isLandscape, overlay, thumbnailWidth, thumbnailHeight]);

  const [smartcrop, setSmartcrop] = React.useState([]);
  const [shapeId, setShapeId] = React.useState(null);
  React.useEffect(() => {
    const queryParams = { p: 'shape[tag=original].id' };
    ItemApi.getItem({ itemId, queryParams }).then(({ data: shape = {} }) => {
      const { shape: [{ id }] = [{}] } = shape;
      setShapeId(id);
      BulkyApi.listShapeBulkyMetadata({ itemId, shapeId: id }).then(({ data = {} }) => {
        const { uri = [] } = data;
        setSmartcrop(
          uri.filter((e) => e.includes('smartcrop')).map((e) => e.substring(e.indexOf('_') + 1)),
        );
      });
    });
  }, [itemId]);

  const onStart = ({ resourceId, queryParams = {} }) => {
    if (smartcrop.indexOf(overlay) > -1) {
      return ShapeApi.createSmartcrop({
        itemId,
        shapeId,
        queryParams: { aspect: overlay, tag, ...queryParams },
      });
    }
    const aspect = overlay.replace('_', ':');
    const analyzeJobDocument = { smartcrop: { aspect } };
    if (!resourceId) throw new Error('No transcoder resource found');
    return ShapeApi.createShapeAnalyze({
      itemId,
      shapeId,
      analyzeJobDocument,
    });
  };

  return {
    thumbnailHeight,
    thumbnailWidth,
    overlayHeight,
    overlayWidth,
    onChange,
    thumbnailSrc,
    isLoading,
    overlay,
    smartcrop,
    shapeId,
    onStart,
  };
};

function ItemHeightscreenDialogComponent({
  classes,
  onClose,
  itemId,
  smartcropOptions = DEFAULT_SMARTCROP_OPTIONS,
  shapeTag = '__mp4',
}) {
  const {
    overlayHeight,
    overlayWidth,
    onChange,
    thumbnailSrc,
    isLoading,
    overlay,
    thumbnailWidth,
    thumbnailHeight,
    smartcrop,
    onStart,
  } = useHeightscreen(itemId, THUMBNAIL_HEIGHT, shapeTag);

  const { resource: { id: resourceId } = {} } = useTranscoderResource();

  const [newItem, setNewItem] = React.useState(false);
  const handleNewItem = ({ target }) => setNewItem(target.checked);

  const onClick = () => {
    const queryParams = !newItem ? { destinationItem: itemId } : {};
    onStart({ resourceId, queryParams }).then(onClose);
  };

  const y = `${(thumbnailHeight - overlayHeight) / 2}px`;
  const x = `${(thumbnailWidth - overlayWidth) / 2}px`;
  return (
    <>
      <DialogTitle disableTypography>
        <Typography variant="h6">Nablet Heightscreen</Typography>
        <Typography variant="subtitle2">Crops video based on Nablet analysis.</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        {!isLoading ? (
          <div className={classes.thumbnailRoot} style={{ width: thumbnailWidth }}>
            <img
              className={classes.thumbnail}
              src={thumbnailSrc}
              alt="thumbnail"
              height={thumbnailHeight}
              width={thumbnailWidth}
              style={{
                filter: overlay ? 'brightness(0.5)' : undefined,
              }}
            />
            <div
              className={classes.thumbnailOverlay}
              style={{
                backgroundImage: `url(${thumbnailSrc})`,
                clipPath: `inset(${y} ${x} ${y} ${x})`,
                backgroundSize: `${thumbnailWidth}px ${thumbnailHeight}px`,
                height: thumbnailHeight,
                width: thumbnailWidth,
              }}
            />
          </div>
        ) : null}
        <ToggleButtonGroup value={overlay} exclusive onChange={onChange}>
          {smartcropOptions.map(({ value, label, AvatarIcon }) => (
            <ToggleButton key={value} value={value}>
              {AvatarIcon ? <AvatarIcon /> : ''}
              <div>{label}</div>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </DialogContent>
      <DialogActions>
        {!resourceId ? (
          <Typography color="error" variant="subtitle2">
            Please configure your transcoder service in Vidinet
          </Typography>
        ) : (
          <>
            {overlay && smartcrop.indexOf(overlay) === -1 && (
              <>
                <InfoIcon />
                <Typography color="textSecondary" variant="subtitle2">
                  Analysis required before cropping
                </Typography>
              </>
            )}
            <FormControlLabel
              disabled={!overlay || smartcrop.indexOf(overlay) === -1}
              control={
                <Checkbox
                  checked={newItem}
                  onChange={handleNewItem}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Create new item"
            />
          </>
        )}
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={!overlay || !resourceId}
        >
          {smartcrop.indexOf(overlay) > -1 ? 'Transcode' : 'Analyze'}
        </Button>
      </DialogActions>
    </>
  );
}

export default withStyles(styles)(withDialog(DIALOG_ID, ItemHeightscreenDialogComponent));
