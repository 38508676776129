import React from 'react';
import useGetItem from '../hooks/useGetItem';

export default function GetItem({ itemId, queryParams, refreshOnMount, children }) {
  const { itemType, onRefresh, isLoading, isError } = useGetItem({
    itemId,
    queryParams,
    refreshOnMount,
  });
  const cloneProps = {
    itemId,
    queryParams,
    itemType,
    onRefresh,
    isLoading,
    isError,
  };
  const cloneChild = (child) => React.cloneElement(child, cloneProps);
  return React.Children.map(children, cloneChild);
}
